import { useState, useEffect } from 'react';
import { PayConfirmSvg } from '../../styles/IconStyle';
import FooterAction from '../FooterAction';
import ButtonFrame from '../ButtonFrame';
import { useNavigate } from 'react-router-dom';
import { UrlType, generalObj } from '../../constant/objects_types';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const PaymentConfirmation = ({ visible, data }:{
    visible: boolean,
    data: generalObj
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const navigate = useNavigate();
    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <>
                <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                    <div className="modalContainer" style={{}}>
                        <div style={{margin:"0px 20px", width:'100%', height:'100%', position:'relative'}}>
                            <div style={{height:"calc(100% - 90px)", display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                <PayConfirmSvg />
                                <div style={{marginTop:"25px"}}>
                                    <div className='sectionTitle' style={{textAlign:'center', fontSize:"14px"}}>Awesome!</div>
                                    {account && account.isAuthenticated ? (
                                        <div className="locationRegion" style={{textAlign:'center', marginTop:"5px"}}>Your e-Ticket would be available in “Tickets” as soon as your payment is confirmed.</div>
                                    ):(
                                        <div className="locationRegion" style={{textAlign:'center', marginTop:"5px"}}>Your e-Ticket payment has been confirmed and will be available in your email “{data.email}”.</div>
                                    )}
                                </div>
                            </div>
                            <FooterAction contStyle={{border:'none', padding:"0", bottom:"20px"}}>
                                <>
                                    {account && account.isAuthenticated && <ButtonFrame title="Go to Tickets" contStyle={{marginTop: "12px", backgroundColor:'#DB012E', border:"none"}} onPress={() => navigate(UrlType.TICKET)} />}
                                    <ButtonFrame title="Return to Home" contStyle={{backgroundColor:'#4F4F4F', border:"none"}} onPress={() => navigate(UrlType.HOME)} />
                                </>
                            </FooterAction>
                        </div>
                    </div>
                </div>
            </>
            )}
            
        </>
    )
}

export default PaymentConfirmation;