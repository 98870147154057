import { RActionType, RAction } from "../actions/action_types";

const initialState = {
    email: null,
    fullname:null,
    username:null,
    phone:null,
    phone_code:null,
    password:null
}


const RegisterReducer = (state=initialState, action:RAction) => {
    switch(action.type) {
        case RActionType.UPDATE_INFO:
            return ({
                ...state,
                ...action.payload
            })
        case RActionType.CLEAR_INFO:
            return (initialState)
        default:
            return state;
    }
}

export default RegisterReducer;