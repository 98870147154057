import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import ModalLoader from './ModalLoader';
import BackArrow from '../BackArrow';
import { generalObj } from '../../constant/objects_types';
import ButtonFrame from '../ButtonFrame';
import { getDateStr, scanTicketApi } from '../../action/generalFunc';

const AttendeeInfoModal = ({ visible, item, modalClose, onChecked }:{
    visible: boolean,
    item: generalObj,
    modalClose: () => void,
    onChecked: (val:generalObj) => void
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [modalLoader, setModalLoader] = useState<boolean>(false);
    const account = useSelector((state:RootState) => state.account);
    

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const checkInUser = async () => {
        if (!account || !account.isAuthenticated || !item) return;
        setModalLoader(true);
        const scan_result:generalObj = await scanTicketApi(account.token, item.id);
        if (scan_result.status) {
            if (scan_result.data.data.ticket_purchase.used) {
                alert("User already checked in");
            }else {
                alert("User checked in");
                onChecked(item);
            }
        }else {
            alert(scan_result.message);
        }
        setModalLoader(false);
    }

    return (
        <>
            {firstShow && (
                <>
                <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                    <div className="modalContainer" style={{}}>
                        <div style={{width:'100%', height:'100%', position:'relative'}}>
                            <div style={{height:'100%', margin:"0px 20px", marginTop:"20px"}}>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                    <div className="sectionTitle" style={{fontSize:"13px", lineHeight:"16px"}}>Attendee Info</div>
                                </div>
                                <div style={{display:"flex", alignItems:'center', justifyContent:'space-between', flexDirection:'column', gap:'15px', height:"calc(100% - 80px)", marginTop:'10px', width:'100%'}}>
                                    <div style={{width:'100%'}}>
                                        <div>
                                            <div className='fp_header' style={{marginTop:0, fontSize:24}}>{item.user.fullname}</div>
                                            <div className='postOwnerTextl' style={{margin:0, textAlign:'center', color:'#9E9B9B', marginTop:'3px'}}>{item.user.email}</div>
                                        </div>
                                        <div style={{marginTop:'20px', display:'flex', justifyContent:'space-between'}}>
                                            <div>
                                                <div className='postOwnerTextl' style={{color:'#9E9B9B', marginLeft:0}}>Ticket Type</div>
                                                <div className='postOwnerTextl' style={{fontSize:'14px', marginLeft:0}}>{item.ticket_info.category}</div>
                                            </div>
                                            <div>
                                                <div className='postOwnerTextl' style={{color:'#9E9B9B', marginLeft:0}}>No. of Tickets</div>
                                                <div className='postOwnerTextl' style={{fontSize:'14px', marginLeft:0}}>{item.quantity}</div>
                                            </div>
                                        </div>
                                        <div style={{marginTop:'20px', display:'flex', justifyContent:'space-between'}}>
                                            <div>
                                                <div className='postOwnerTextl' style={{color:'#9E9B9B', marginLeft:0}}>Ticket ID</div>
                                                <div className='postOwnerTextl' style={{fontSize:'14px', marginLeft:0}}>7987{item.id}</div>
                                            </div>
                                            <div>
                                                <div className='postOwnerTextl' style={{color:'#9E9B9B', marginLeft:0}}>Date Purchased</div>
                                                <div className='postOwnerTextl' style={{fontSize:'14px', marginLeft:0}}>{getDateStr(item.created_at)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{width:'100%'}}>
                                        <ButtonFrame title={!item.used ? 'Check In' : 'Checked In'} contStyle={{marginTop: 0, border:"none", backgroundColor:item.used? '#302929' :'#DB012E'}} onPress={() => checkInUser()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}
            <ModalLoader visible={modalLoader} />
        </>
    )
}

export default AttendeeInfoModal;