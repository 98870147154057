import { useState, useRef, useEffect, useCallback } from "react";
import { BottomTab, ModalLoader, ResellTicketItem, SearchBar, ShareModal, TicketDetail } from "../components";
import { DateIconSmallSvg, LineSvg, LocationSmallSvg, PriceTagSmallSvg, TimeIconSmallSvg } from "../styles/IconStyle";
import { UrlType, generalObj } from "../constant/objects_types";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { fetchResaleTickets, fetchUserTickets, getDateStr, getTimeStr, getUTCStr, processAddressnMap } from "../action/generalFunc";
import { SYMBOL } from "../constant/constant";

const TicketLanding = () => {
    const location = useLocation();

    const account = useSelector((state:RootState) => state.account);
    const [itemBlock, setItemBlock] = useState<boolean>(false);
    const [tickets, setTickets] = useState<generalObj[] | null>(null);
    const [resoldTickets, setResoldTickets] = useState<generalObj[] | null>(null);
    const [searchResold, setSearchResold] = useState<string>("");
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [ticketFilters, setTicketFilter] = useState<generalObj[]>([
        {
            id: '1',
            title: 'Upcoming Event',
            active: true
        },{
            id: '2',
            title: 'Past Event',
            active: false
        },{
            id: '3',
            title: 'Resold Tickets',
            active: false
        }
    ]);
    const [showTicket, setShowTicket] = useState<boolean>(false);
    const [selectedTicket, setSelectedTicket] = useState<generalObj | null>(null);
    const [sharingModal, setSharingModal] = useState<boolean>(false);
    const [ search_param ] = useSearchParams();
    const navigate = useNavigate();

    const headerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!search_param || !search_param.size) return;
        const tab = search_param.get("tab");
        if (!tab) return;
        setTicketFilter((prevTicketFilter) => {
            return (
                prevTicketFilter.filter((item) => {
                    if (item.id === tab) {
                        item.active = true;
                    }else {
                        item.active = false;
                    }
                    return item;
                })
            )
        });
    }, [search_param]);

    useEffect(() => {
        //setModalLoading(true);
        const closeTimer = setTimeout(() => {
            setItemBlock(true);
            //setModalLoading(false);
        }, 1500);

        return () => clearTimeout(closeTimer);
    }, []);

    useEffect(() => {
        if (!account || !account.isAuthenticated) {
            navigate(`${UrlType.LOGIN}?redirect=${UrlType.TICKET}`);
            return;
        }else {
            (async () => {
                let now = new Date();
                if (now.getTime() > account.expiry) return;
            
                setModalLoading(true)
                const result:generalObj = await fetchUserTickets(account.token);
                const resold_result:generalObj = await fetchResaleTickets(account.token);
                if (!resold_result.error) {
                    setResoldTickets(resold_result.data)
                }
                if (result.status) {
                    let updated_data = result.data.data.tickets.filter((ticketObj:generalObj) => {
                        if (!ticketObj.event) {
                            return null;
                        }
                        let ticket_date = new Date(getUTCStr(ticketObj.event.start_date));
                        let today = new Date();
    
                        if (ticket_date.getTime() > today.getTime()) {
                            ticketObj.is_new = true;
                            ticketObj.show = true;
    
                        }else {
                            ticketObj.is_new = false;
                            ticketObj.show = false;
                        }
                        return ticketObj;
                    });
    
                    setTickets(updated_data);
                }else {
                    alert(result.message);
                }
                setModalLoading(false);
            })();
        }
    }, [account, navigate]);

    useEffect(() => {
        const current_active = ticketFilters.filter((item:generalObj) => item.active);
    
        if (current_active.length <= 0) {
          return;
        }
    
        setTickets((prevTicket) => {
            if (!prevTicket) return null;
          return (
            prevTicket.filter((item) => {
              if (current_active[0].id === '1' && item.is_new) {
                item.show = true;
              }else if (current_active[0].id === '2' && !item.is_new) {
                item.show = true;
              }else {
                item.show = false;
              }
    
              return item;
            })
          )
        });
    }, [ticketFilters]);

    const updateFilter = (fItem:generalObj) => {
        const oldFilter = ticketFilters.filter((item) => {
            if (item.id === fItem.id && !item.active) {
                item.active = true;
            }else {
                item.active = false;
            }
            return item;
        });

        setTicketFilter(oldFilter);
    }

    const openTicket = (item:generalObj) => {
        setSelectedTicket(item);
        setShowTicket(true);
    }

    const filterResell = (evt:string) => {
        setSearchResold(evt);
        setResoldTickets((prevResoldTickets) => {
            if (!prevResoldTickets) return null;
            return (
                prevResoldTickets.filter((item) => {
                    if (item.performers.toLowerCase().includes(evt.toLocaleLowerCase()) || (item.user_obj && (item.user_obj.username.toLowerCase().includes(evt.toLowerCase()) || item.user_obj.email.toLowerCase().includes(evt.toLocaleLowerCase()))) || item.ticket_name.toLowerCase().includes(evt.toLocaleLowerCase())) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item
                })
            )
        })
    }

    const updateTicketObj = useCallback((val:generalObj) => {
        setResoldTickets((prevResoldTickets) => {
            if (!prevResoldTickets) return null;
            return (
                prevResoldTickets.filter((item) => {
                    if (val.id === item.id) {
                        item.user_obj = val;
                    }
                    return item
                })
            )
        })
    }, []);
    

    return (
        <>
            <div className={`appBody${!itemBlock ? " loading" : ""}`}>
                <div className="appCont" style={{position:'relative'}}>
                    <div ref={headerRef} style={{padding:"20px 0px", position:'fixed', width:'100%', top:"0px", zIndex:'99', backgroundColor:'#0D0404'}}>
                        <div style={{margin:"0px 20px 0px 20px", display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <div style={{display:'flex', alignItems:'center', gap:'3px'}}>
                                <span className="sectionTitle">Tickets</span>
                            </div>

                            {/* <button>
                                <SearchSvg />
                            </button> */}
                        </div>

                        {/* Ticket filter */}
                        <div style={{marginTop:"24px", display:'flex', gap:"13px", padding:"0px 20px"}}>
                            {ticketFilters.map((item:generalObj, index:number) => (
                                <button key={index} className={`toggleFilterItem ${item.active && "toggleFilterItemActive"}`} style={{padding:"8px 12px", margin:"0px"}} onClick={()=>updateFilter(item)}>
                                    <span className="toggleFilterItemText" style={{fontSize:"12px"}}>{item.title}</span>
                                </button>
                            ))}
                        </div>
                        {ticketFilters[2].active && (
                            <div style={{marginTop: "24px", padding:"0px 20px"}}>
                                <SearchBar inputProp={{placeholder:"Search “artist”", value:searchResold}} customInputText={{backgroundColor:"transparent"}} onTextChange={(event) => filterResell(event)} />
                            </div>
                        )}
                    </div>
                    <div style={{marginTop:`${headerRef.current?.clientHeight}px`, padding:"24px 20px 0px 20px", display:`${ticketFilters[0].active || ticketFilters[1].active ? 'flex' : 'none'}`, flexDirection:'column', gap:'16px'}}>
                        {tickets && tickets.map((item:generalObj, index:number) => (
                            item.show && (
                                <div key={index} style={{borderRadius:"16px", width:"100%", backgroundColor:'#EEE'}} onClick={() => openTicket(item)}>
                                    <div className="ticketBg" style={{padding:"13px 12px", display:'flex'}}>
                                        <div className="ticketBg image" style={{backgroundImage:`url(${item.event.cover_image ? item.event.cover_image : require('../assets/img/mpt.png')})`, width:"100%", height:"100%", position:'absolute', left:"0px", top:"0px"}}>
                                            <div></div>
                                        </div>
                                        <div style={{width:"100%", height:"100%", position:'absolute', backgroundColor:'rgba(52, 39, 38, 0.7)', borderRadius:"14px", left:"0px", top:"0px"}}></div>

                                        <div style={{padding:"12px 8px", borderRadius:"7px", backgroundColor:'rgba(255, 255, 255, 0.3)', width:"100%", position:'relative'}}>
                                            <p className="sectionTitle" style={{fontSize:"13px", lineHeight:"16px"}}>{item.event.name}</p>
                                            <div style={{marginTop:"10px"}}>
                                                {/* <div className="postOwner" style={{marginTop:"0px", gap:"4px"}}>
                                                    <PriceTagSmallSvg />
                                                    <p className="postOwnerTextl" style={{fontSize:"10px",fontWeight:'500', lineHeight:"14px", marginLeft:'0px'}}>Price: {parseFloat(item.price) > 0 ? `${SYMBOL}${parseFloat(`${item.price}`).toLocaleString()}` : "Free"}</p>
                                                </div> */}
                                                <div className="postOwner" style={{gap:"8px"}}>
                                                    <div style={{display:'flex', alignItems:'center', gap:"4px"}}>
                                                        <PriceTagSmallSvg />
                                                        <p className="postOwnerTextl" style={{fontSize:"10px",fontWeight:'500', lineHeight:"14px", marginLeft:'0px'}}>Price: {parseFloat(item.price) > 0 ? `${SYMBOL}${parseFloat(`${item.price}`).toLocaleString()}` : "Free"}</p>
                                                    </div>
                                                    <div style={{display:'flex', alignItems:'center', gap:"4px"}}>
                                                        <PriceTagSmallSvg />
                                                        <p className="postOwnerTextl" style={{fontSize:"10px",fontWeight:'500', lineHeight:"14px", marginLeft:'0px'}}>Quantity: x{item.ticket_purchase.quantity}</p>
                                                    </div>
                                                </div>

                                                <div className="postOwner" style={{gap:"8px"}}>
                                                    <div style={{display:'flex', alignItems:'center', gap:"4px"}}>
                                                        <DateIconSmallSvg />
                                                        <p className="postOwnerTextl" style={{fontSize:"10px",fontWeight:'500', lineHeight:"14px", marginLeft:'0px'}}>Date: {getDateStr(getUTCStr(item.event.start_date))}</p>
                                                    </div>
                                                    <div style={{display:'flex', alignItems:'center', gap:"4px"}}>
                                                        <TimeIconSmallSvg />
                                                        <p className="postOwnerTextl" style={{fontSize:"10px",fontWeight:'500', lineHeight:"14px", marginLeft:'0px'}}>Time: {getTimeStr(getUTCStr(item.event.start_date), false)}</p>
                                                    </div>
                                                </div>
                                                <div className={"postOwner"} style={{gap:"4px"}}>
                                                    <LocationSmallSvg />
                                                    <p className="postOwnerTextl" style={{fontSize:"10px",fontWeight:'500', lineHeight:"13px", marginLeft:'0px'}}>Location: {item.event.location_type === 'venue' ? `${processAddressnMap(item.event.location, "addressLabel").slice(0, 30)}` : item.event.online_link}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{marginLeft:"24px", marginRight:"16px", display:'flex', flexDirection:'column', alignItems:'center', position:"relative"}}>
                                            <div style={{position:'absolute', top:"-5px"}}>
                                                <LineSvg />
                                            </div>
                                            <div className="bgMain" style={{width:"23px", height:"26px", position:'absolute', borderRadius:'100%', top:"-29px", left:"-12px"}}></div>
                                            <div className="bgMain" style={{width:"23px", height:"26px", position:'absolute', borderRadius:'100%', bottom:"-29px", left:"-12px"}}></div>
                                        </div>
                                        <div style={{display:"flex",width:"72px", alignItems:'center'}}>
                                        <div style={{width:"72px", height:"80px", backgroundColor:'rgba(255, 255, 255, 0.6)', padding:"5px", borderRadius:"12px", position:'relative'}}>
                                            <img src={require('../assets/img/qr_code.png')} style={{width:'100%', height:'100%', objectFit:'fill'}} alt="qrcode" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                    
                    <div style={{marginTop:`${headerRef.current?.clientHeight}px`, padding:"20px 20px 0px 20px", display:`${ticketFilters[2].active ? 'flex' : 'none'}`, flexDirection:'column', gap:'40px'}}>
                        {resoldTickets && resoldTickets.map((item:generalObj, index:number) => (
                            (item.show || item.show === undefined) && (
                                <ResellTicketItem key={index} item={item} updateTicket={(val) => updateTicketObj(val)} />
                            )
                        ))}
                    </div>
                </div>
                {showTicket && selectedTicket && account && (
                    <TicketDetail visible={showTicket} ticket_item={selectedTicket} accountUser={account} loadShare={() => setSharingModal(true)} onModalClose={() => {
                        setShowTicket(false);
                        setSelectedTicket(null);
                    }} />
                )}
            </div>

            <BottomTab location_path={location.pathname} />
            <ModalLoader visible={modalLoading} />
            <ShareModal visible={sharingModal} modalClose={() => setSharingModal(false)} />
        </>
    )
}

export default TicketLanding;