
import { useState, useEffect } from "react";
import { UrlType, generalObj } from "../../constant/objects_types";
import { searchUserEvent } from "../../action/generalFunc";
import { IoCloseOutline } from "react-icons/io5";
import { SearchInputSvg } from "../../styles/IconStyle";
import BackArrow from "../BackArrow";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import ProfileGenerator from "../ProfileGenerator";
import EventItem from "../home/EventItem";
import uuid from 'uuid-random';
import { useNavigate } from "react-router-dom";

const SearchModal = ({ visible, modalClose }:{
    visible:boolean,
    modalClose: () => void
}) => {
    const [searchText, setSearchText] = useState<string>("");
    const [searchResult, setSearchResult] = useState<generalObj | null>(null);
    const [recentSearch, setRecentSearch] = useState<generalObj[] | null>(null);
    const [parentWidth, setParentWidth] = useState<number>(0);
    const [recommendPop, setRecommendPop] = useState<boolean>(false);
    const navigate = useNavigate();
    const account = useSelector((state:RootState) => state.account);

    console.log(recommendPop);

    useEffect(() => {
        const loadRecentSearch = async () => {
            try {
                const old_search = await localStorage.getItem('recentSearch');
                if (old_search !== null) {
                    let result = JSON.parse(old_search);
                    setRecentSearch(result.length > 0?result:null);
                }else {
                    setRecentSearch(null);
                }
            }catch(e) {
                setRecentSearch(null);
            }
        }

        loadRecentSearch();
    }, []);

    useEffect(() => {
        if (!recentSearch) return;
        localStorage.setItem("recentSearch", JSON.stringify(recentSearch));
    }, [recentSearch])

    const updateSearchResult = () => {
        if (!searchText) return;

        if (recentSearch) {
            const find_existing = recentSearch.filter(item => item.search === searchText);
            if (find_existing && find_existing.length > 0) return;
        }
        
        const latest_search = {
            id: uuid(),
            search: searchText
        }
        
        setRecentSearch((prevSearch) => (prevSearch ? [latest_search, ...prevSearch] : [latest_search]));
    }

    const searchTextOnChange = async (val:string) => {
        if (!account) return;
        setSearchText(val);
        if (val.length >= 2) {
            const result:generalObj = await searchUserEvent(account.token, val);
            if (result.status) {
                console.log(result.data.data);
                setSearchResult(result.data.data);
            }else {
                setSearchResult(null);
            }
        }else {
            setSearchResult(null);
        }
    }

    const updateRecentSearch = (action:string, id=null) => {
        switch(action) {
            case 'remove_single':
                setRecentSearch((prevRecentSearch) => {
                    if (!prevRecentSearch) return null;
                    return (
                        prevRecentSearch.filter(item => {
                            if (item.id !== id) {
                                return item;
                            }
                            return item;
                        })
                    )
                });
                break;
            case 'remove_all':
                setRecentSearch([]);
                break;
            default:
                return;
        }
    }

    const updateEventData = (action:string, id:number) => {
        if (!searchResult) return;
        const search_result_copy = searchResult.events;
        switch (action) {
            case 'save':
                const src_save = search_result_copy.filter((item:generalObj) => {
                    if (item.id === id) {
                        item.saved = !item.saved;
                    }
                    return item
                });
                setSearchResult({...searchResult, events:src_save});
                break;
            case 'recommend':
                const src_recommend = search_result_copy.filter((item:generalObj) => {
                    if (item.id === id) {
                        item.recommended = item.recommended === undefined? false : !item.recommended;
                    }
                    return item
                });
                setSearchResult({...searchResult, events:src_recommend});
                break;
            case 'follow':
                const src_follow = search_result_copy.filter((item:generalObj) => {
                    if (item.id === id) {
                        item.following = !item.following;
                    }
                    return item
                });
                setSearchResult({...searchResult, events:src_follow});
                break;
            default:
                console.log(action);
        }
    }

    const pageLayout = (event:HTMLDivElement | null) => {
        if (!event) return;
        const width = event.clientWidth;
        setParentWidth(width - 40);
    }

    return (
        <div className={`modalParentContainer${!visible ? " d-none" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"#1D1716", zIndex:"9999", left:"0px"}}>
            <div className="modalContainer flexEnd" style={{overflow:'hidden', paddingBlock:"30px"}}>
                <div className="actionView animate__animated animate__slideInUp" style={{height:"100%", paddingInline:"20px", backgroundColor:"#050202", position:'relative'}}>
                    <div style={{width:'100%', marginBottom:"15px", paddingInline:"0px", display:'flex', justifyContent:"space-between", alignItems:"center"}}>
                        <div style={{width:'10%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <BackArrow arrowStyle={{position:'relative', left:"-10px", top:"3px"}} arrowBtnStyle={{position:"relative", left:0, top:0}} onPress={modalClose} />
                        </div>
                        {/* Search bar */}
                        <div className="searchBar" style={{width:'90%', backgroundColor:'#1D1716', position:'relative', top:0}}>
                            <span style={{display:'flex', width:"18px", height:"18px"}}>
                                <SearchInputSvg style={{width:"100%", height:"100%"}} />
                            </span>
                            <input className="inputText" style={{width:'90%', backgroundColor:'transparent'}} autoFocus={true} value={searchText} placeholder='Search events, artist' onChange={(evt)=>searchTextOnChange(evt.target.value)} onBlur={updateSearchResult}  />
                            {searchText.length > 0 && (
                                <button onClick={()=>searchTextOnChange('')}>
                                    <span>
                                        <IoCloseOutline size={24} color="rgba(255, 255, 255, 0.6)" />
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Recent Search */}
                    {recentSearch && searchText.length < 2 && (
                        <div style={{marginBottom:"10px", marginTop:"32px", width:'100%'}}>
                            <div style={{marginBottom:"23px", display:'flex', justifyContent:'space-between'}}>
                                <div className="postOwnerText" style={{lineHeight:"16px", marginLeft:0}}>Recent Searches</div>
                                <button onClick={() => updateRecentSearch('remove_all')}>
                                    <span className="locationRegion" style={{color:"#DB012E"}}>Clear All</span>
                                </button>
                            </div>
                            <div className="actionItemCont">
                                <div style={{paddingBottom: "50px", height:"100%"}}>
                                    {recentSearch && (
                                        <>
                                            {recentSearch.map((item:generalObj, index:number) => (
                                                <div key={index} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:"26px"}}>
                                                    <button onClick={()=>searchTextOnChange(item.search)}>
                                                        <span className="locationRegion" style={{lineHeight:"16px",paddingBlock:"5px"}}>{item.search}</span>
                                                    </button>

                                                    <button style={{marginLeft:"5px"}} onClick={()=> updateRecentSearch('remove_single', item.id)}>
                                                        <IoCloseOutline size={18} color="rgba(255, 255, 255, 1)" />
                                                    </button>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Event */}
                    <div className="actionItemCont" style={{left:0, margin:0}}>
                        <div style={{paddingBottom: "50px", height:"100%", paddingInline:'20px'}} ref={(evt) => pageLayout(evt)}>
                            {searchText.length >= 2 && (
                                <div style={{marginBottom:!searchResult ? "10px" : searchResult.users.length > 0 && searchResult.events.length > 0 ? "50px" : "80px", width:'100%'}}>
                                    {searchResult && (
                                        <>
                                            {searchResult.users && searchResult.users.length > 0 && (
                                                <>
                                                    <div style={{marginBottom:"23px", display:'flex', justifyContent:'space-between'}}>
                                                        <span className="postOwnerText" style={{lineHeight:"16px", marginLeft:0}}>Users</span>
                                                    </div>
                                                    <div>
                                                        {searchResult.users.map((item:generalObj, index:number) => (
                                                            <div key={index} style={{display: 'flex', alignItems:'center', marginBottom: index + 1 === searchResult.events.length ? "32px" : "24px", cursor:"pointer"}} onClick={() => account && account.isAuthenticated && (account.id === item.id ? navigate(`${UrlType.PROFILE_USER}me`) : navigate(`${UrlType.PROFILE_USER_ID}${item.id}/`))}>
                                                                {item.profile_picture ? (
                                                                    <div className="profilePicture" style={{backgroundColor:"#c4c4c4", width:"24px", height:"24px", marginRight:"5px", marginLeft:0}}>
                                                                        <img src={item.profile_picture} className="profilePictureUser" style={{width:"24px", height:"24px"}} alt="user" />
                                                                    </div>
                                                                ): (
                                                                    <ProfileGenerator title={item.username[0].toUpperCase()} textStyle={{fontSize:"12px", lineHeight:"16px"}} constStyle={{width:"24px", height:"24px", marginRight:"5px", marginLeft:0}} />
                                                                )}
                                                                <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"19px"}}>{item.username}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}

                                            {searchResult.events && searchResult.events.length > 0 && (
                                                <>
                                                    <div style={{marginBottom:"23px", marginTop:searchResult.users.length > 0 ? "23px" : 0, display:'flex', justifyContent:'space-between'}}>
                                                        <span className="postOwnerText" style={{lineHeight:"16px", marginLeft:0}}>Events</span>
                                                    </div>

                                                    <div style={{paddingBottom: "2px"}}>
                                                        {searchResult.events.map((item:generalObj, index:number) => (
                                                            <EventItem item={item} listCont={{marginBottom: index + 1 === searchResult.events.length ? "24px" : "24px"}} imageCont={{width:parentWidth}} actionPressed={(action, id) => updateEventData(action, id)} updateEventItem={(id) => updateEventData("recommend", id)} openRecommendPop={()=>setRecommendPop(true)} />
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                            

                                        </>
                                    )}

                                    {(!searchResult || (searchResult.users.length === 0 && searchResult.events.length === 0)) && (
                                        <div style={{marginBottom:"23px", marginInline:"20px", display:'flex', justifyContent:'center'}}>
                                            <span className="postOwnerText" style={{lineHeight:"16px", marginLeft:0, color:'rgba(255, 255, 255, 0.6)'}}>No result found</span>
                                        </div>
                                    )}
                                    {/* <RecommendModal visible={recommendPop} modalClose={()=>setRecommendPop(false)} /> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchModal;