import ExploreHeader from "./ExploreHeader";
import EventItem from "../home/EventItem";
import { useUserLocation } from "../../ticki_hooks";
import { useState, useRef, useEffect } from 'react'
import { generalObj } from "../../constant/objects_types";
import LocationModal from "../modal/LocationModal";

const ExploreFiltered = ({ visible, explore_item, onModalClose}:{
    visible:boolean,
    explore_item:generalObj | null,
    onModalClose: () => void,
}) => {
    const [locationData, locationStateData] = useUserLocation();
    const [locationModal, setLocationModal] = useState<boolean>(false);
    const [newLocation, setNewLocation] = useState<string | null>(null);

    const [events, setEvents] = useState<generalObj[]>([]);
    const [eventFilters, setEventFilter] = useState<generalObj[]>([]);
    const [filteredName, setFilteredName] = useState<string>("");

    const headerRef = useRef<HTMLDivElement | null>(null);
    const [headerHeight, setHeaderHeight] = useState<number>(180);
    const [showingFilter, setShowingFilter] = useState<boolean>(false);

    useEffect(() => {
        if (!explore_item || !visible) return;
        setEventFilter(explore_item.subFilters);
        setEvents(explore_item.events);
        setFilteredName(explore_item.title);

    }, [explore_item, visible]);

    useEffect(() => {
        if (!headerRef.current) return;
        setHeaderHeight(headerRef.current.clientHeight);
    }, [showingFilter]);

    return (
        <>
        <div className={`modalParentContainer${!visible ? " animate__animated animate__slideOutRight" : " animate__animated animate__slideInRight" }`} style={{backgroundColor:"#0D0404"}}>
            <div className="modalContainer" style={{}}>
                <div className="appCont" style={{position:'relative', height:"100%", overflowX:'hidden', paddingBottom:'180px'}}>
                    <div ref={headerRef} style={{paddingTop:"20px", position:'fixed', width:'100%', top:"0px", zIndex:'99', backgroundColor:'#0D0404'}}>
                        <ExploreHeader eventFilters={eventFilters} locationData={locationData} newLocation={newLocation} isFiltered={true} filteredTitle={filteredName} openLocationModal={(val) => setLocationModal(val)} showDistance={(val) => setShowingFilter(val)} onPress={(val) => console.log(val)} goBack={() => onModalClose()} />
                    </div>
                    <div style={{paddingTop:`${headerHeight + 70}px`}}>
                        {events.map((item:generalObj, index:number) => (
                            <EventItem key={index} item={item} listCont={{marginTop: index === 0 ? "20px" : "40px"}} actionPressed={(action, id)=>console.log(action, id)} updateEventItem={(id)=>console.log(id)} openRecommendPop={() => console.log('open recommend')}  />
                        ))}
                    </div>
                </div>
            </div>
            
        </div>
        {locationStateData && (
            <LocationModal visible={locationModal}  locationData={locationStateData} modalClose={() => setLocationModal(false)} newLocation={(val) => {setNewLocation(val); setLocationModal(false);}} />
        )}
        </>
    )
}

export default ExploreFiltered;