import { generalObj } from "../../constant/objects_types";
import { ActionType, Action } from "../actions/action_types";

const initialState:generalObj = {
    token: null,
    id: null,
    walletId: null,
    fullname: null,
    username: null,
    email: null,
    country: null,
    profileImage: null,
    gender: null,
    bio: null,
    socialLinks: {
        website: null,
        instagram: null,
        twitter: null,
        youtube: null,
        tiktok: null,
        snapchat: null
    },
    dob: null,
    event_recommendation: null,
    event_reminders: null,
    event_cancellation: null,
    ticket_purchase: null,
    ticket_refund: null,
    ticket_sales: null,
    ticket_check_in: null,
    phone: null,
    expiry: null,
    isAuthenticated: false,
    isKyc: false,
    isOnboarding:false
}


const TickiReducer = (state=initialState, action:Action) => {
    switch(action.type) {
        case ActionType.LOGIN_USER:
            if (!action.payload) return;
            let now_time = new Date();
            let expire_time = now_time.setMinutes(now_time.getMinutes() + action.payload.data.expires_in);
            return ({
                ...state,
                token: action.payload.data.token,
                id: action.payload.data.user.id,
                walletId: action.payload.data.user.wallet_id,
                fullname: action.payload.data.user.fullname,
                username: action.payload.data.user.username,
                email: action.payload.data.user.email,
                country: action.payload.data.user.country,
                profileImage: action.payload.data.user.profile_picture,
                gender: action.payload.data.user.gender,
                bio: action.payload.data.user.bio,
                socialLinks: {
                    website: action.payload.data.user.website,
                    instagram: action.payload.data.user.instagram,
                    twitter: action.payload.data.user.twitter,
                    youtube: action.payload.data.user.youtube,
                    tiktok: action.payload.data.user.tiktok,
                    snapchat: action.payload.data.user.snapchat
                },
                dob: action.payload.data.user.dob,
                event_recommendation: action.payload.data.user.event_recommendation,
                event_reminders: action.payload.data.user.event_reminders,
                event_cancellation: action.payload.data.user.event_cancellation,
                ticket_purchase: action.payload.data.user.ticket_purchase,
                ticket_refund: action.payload.data.user.ticket_refund,
                ticket_sales: action.payload.data.user.ticket_sales,
                ticket_check_in: action.payload.data.user.ticket_check_in,
                phone: action.payload.data.user.phone,
                expiry: expire_time,
                isAuthenticated: true,
                isKyc: action.payload.data.user.is_kyc ? true : false
            })
        case ActionType.REGISTER_USER:
            return ({
                ...state,
                isAuthenticated: false,
                isOnboarding:true
            })
        case ActionType.UPDATE_USER:
            let name = action.payload.isSocial ? "socialLinks" : action.payload.name;
            return ({
                ...state,
                [name]:action.payload.isSocial ? {...state.socialLinks, [action.payload.name]:action.payload.data} : action.payload.data
            })
        case ActionType.REFRESH_TOKEN:
            if (!action.payload) return;
            let now_rf_time = new Date();
            let expire_rf_time = now_rf_time.setMinutes(now_rf_time.getMinutes() + action.payload.expires_in);
            return ({
                ...state,
                token: action.payload.token,
                expiry: expire_rf_time
            })
        
        case ActionType.ONBOARDED_USER:
            return ({
                ...state,
                isKyc: true,
                isOnboarding:false,
                isAuthenticated: true
            })
        
        case ActionType.ONBOARDING_USER:
            return ({
                ...state,
                isOnboarding:true,
                isAuthenticated: true
            })

        case ActionType.LOGOUT_USER:
            return ({
                ...initialState
            })
        default:
            return state;
    }
}

export default TickiReducer;