import { useState, useEffect } from 'react';
import { generalObj } from '../../constant/objects_types';
import { RegularIconSvg, VipIconSvg, VvipIconSvg } from '../../styles/IconStyle';
import { calculateSellingPrice } from '../../action/generalFunc';
import SellPriceModal from '../modal/SellPriceModal';
import { SYMBOL } from '../../constant/constant';

const ChangeReviewPrice = ({ visible, itemObj, modalClose, onChange }:{ 
    visible:boolean, 
    itemObj: generalObj,
    modalClose: () => void,
    onChange: (val: generalObj) => void
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [newTicketPrice, setNewTicketPrice] = useState<string>("0");
    const [sellingDetail, setSellingDetail] = useState<boolean>(false); 

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!itemObj) return;
        setNewTicketPrice(itemObj.price.toString());
    }, [itemObj]);

    const updatePrice = () => {
        itemObj.price = newTicketPrice;
        onChange(itemObj);
    }

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`, left:"0px"}}>
                    <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                        <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                        <div className={`actionView${!visible ? " animate__animated animate__slideOutDown" : " animate__animated animate__slideInUp" }`} style={{height:"160px", padding:"10px 0px"}}>
                            <div style={{margin: "20px 0px 0px", position: "relative", height: "100%"}}>
                                <div style={{textAlign:'end', margin:'0px 20px'}}>
                                    <button onClick={() => updatePrice()}>
                                        <span className='sectionTopLink'>Done</span>
                                    </button>
                                </div>
                                <div className="actionItemCont picker" style={{height:'calc(100% - 60px)'}}>
                                    <div style={{paddingInline:"20px"}}>
                                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                {itemObj.title === 'V.V.I.P' ? <VvipIconSvg /> : itemObj.title === 'V.I.P' ? <VipIconSvg /> : <RegularIconSvg />}
                                                <div className="itemOwner" style={{fontWeight:'700', marginLeft:5}}>{itemObj.title ? itemObj.title : "Regular"}</div>
                                            </div>
                                        </div>
                                        <div style={{marginBlock:"8px", display:'flex', position:'relative', width:"100%"}}>
                                            <input type="number" value={newTicketPrice} className="inputText" style={{backgroundColor:"rgba(255, 255, 255, 0.12)"}} placeholder="Update Ticket Price" onChange={(val) => setNewTicketPrice(val.target.value)} />
                                        </div>
                                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                            <div className="itemOwner bodyText" style={{fontWeight:'600', color:"#9E9B9B"}}>Selling Price: {SYMBOL}{calculateSellingPrice(parseFloat(newTicketPrice))}</div>
                                            <button onClick={()=>setSellingDetail(true)}>
                                                <span className="sectionTopLink" style={{fontWeight:'500', fontSize:"13px", lineHeight:"16px"}}>More</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {sellingDetail && <SellPriceModal visible={sellingDetail} modalClose={()=>setSellingDetail(false)} /> }
        </>
    )

}

export default ChangeReviewPrice;