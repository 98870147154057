import { useEffect, useState, useRef } from "react";
import { BackArrow, RegisterContent } from "../../components";
import { checkEmailApi, validateEmail } from "../../action/generalFunc";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState, ractionCreator } from "../../redux";
import { useNavigate } from "react-router-dom";
import { UrlType, generalObj } from "../../constant/objects_types";
import { IoCloseOutline } from "react-icons/io5";

const RegisterStepOne = () => {
    const resgisterState = useSelector((state:RootState) => state.registerInfo);
    const [email, setEmail] = useState<string>('');
    const [emailErr, setEmailErr] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updateInfo } = bindActionCreators(ractionCreator, dispatch);
    const emailRef = useRef<HTMLInputElement | null>();

    useEffect(() => {
        if (!resgisterState || !resgisterState.email) return;
        setEmail(resgisterState.email);
    }, [resgisterState]);

    const checkEmail = async () => {
        let validEmail = await validateEmail(email);
        if (!validEmail) {
          alert('Invalid email address');
          return false
        }
        let result:generalObj = await checkEmailApi(email);
        if (result.status) {
            setEmailErr(result.isExist);
            return !result.isExist;
        }else {
            alert(result.message);
            return false;
        }
    }
    
    const checkResult = async () => {
        if (emailErr || email.length === 0) return;
        
        if (await checkEmail()) {
            await updateInfo("email", email);
            navigate(UrlType.REGISTER_STEP2);
        }
    }

    return (
        <>
            <div className='authContainer' style={{justifyContent:'flex-start'}}>
                <BackArrow onPress={() => {window.history.back()}} />
                <div className="register">
                    <RegisterContent 
                        header="What's your Email?" 
                        btnTitle="Next" 
                        id={'email'}
                        description="A confirmation code will be sent to this email address." 
                        inputProp={{value:email, text:'email', onBlur:checkEmail}}
                        refInput={(input)=>emailRef.current = input}
                        isIcon={<IoCloseOutline size={"24px"} color="#FFF" /> } 
                        onPress={checkResult}
                        btnStyle={!emailErr && email.length >=4 ? {opacity:'1', cursor:"pointer"} : {cursor:'not-allowed'}}
                        onTextChange={(val)=>{setEmail(val); setEmailErr(false)}}
                        iconPress={() => {setEmail(''); setEmailErr(false)}}
                        message={{
                            addComponent: true,
                            show: emailErr,
                            text: (
                                <div className="SignupFooter" style={{position:'relative', width:'auto', border:'none', padding:'0px', fontSize:'12px'}}>
                                    <span>This email already has an account. Do you want to</span>

                                    <a href={UrlType.LOGIN} style={{color:"#DB012E"}}>
                                        <span>Sign In ?</span>
                                    </a>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default RegisterStepOne;