import { useCallback, useEffect, useRef, useState } from "react"
import { generalObj } from "../../constant/objects_types"
import BackArrow from "../BackArrow"
import { DateIconSvg, DownloadBigSvg, LineBigSvg, LocationSvg, PriceTagSmallSvg, TimeIconSvg } from "../../styles/IconStyle"
import QRCode from "react-qr-code"
import ProfileGenerator from "../ProfileGenerator"
import { getDateStr, getTimeStr, getUTCStr, processAddressnMap } from "../../action/generalFunc"
import { QR_URL, SYMBOL } from "../../constant/constant";
import ModalLoader from "../modal/ModalLoader";
import html2canvas from "html2canvas"

const TicketDetail = ({ visible, ticket_item, accountUser, loadShare, onModalClose }:{
    visible:boolean,
    ticket_item:generalObj | null,
    accountUser: generalObj,
    loadShare: () => void,
    onModalClose: () => void
}) => {

    const ticketRef = useRef<HTMLDivElement | null>(null);
    const [ticketLink, setTicketLink] = useState<string>("");
    const [modalLoading, setModalLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!visible || !ticket_item) return;

        setTicketLink(`${QR_URL}${ticket_item.ticket_purchase.id}`);
    }, [visible, ticket_item]);

    // const copyLink = async () => {
    //     // navigator.clipboard.writeText(ticketLink).then(
    //     //     () => {
    //     //         // invoked if the data is copied
    //     //         alert("Copied successfully")
    //     //     },
    //     //     () => {
    //     //         // handle data copy error
    //     //         alert("Copying failed")
    //     //     }
    //     // )
    //     await copyToClipboard(ticketLink);
    // }

    // const convertToImage = () => {
    //     if (!ticketRef.current) return;
    //     let divCanvas = ticketRef.current.canvas
    // }
    

    const toDownloadImg = useCallback(() => {
        if (!ticketRef.current || !ticket_item) return;
        setModalLoading(true);
        let new_obj = ticketRef.current
        let obj_remove = new_obj.querySelector("#actionComp");
        if (obj_remove) {
            obj_remove.remove();
        }
        html2canvas(new_obj).then((canvas) => {
            const link = document.createElement('a');
            link.download = `ticket_7987${ticket_item.ticket_purchase.id}.jpg`;
            link.href = canvas.toDataURL();
            link.click();
            obj_remove && new_obj.childNodes[0].appendChild(obj_remove);
            setModalLoading(false);
        })
    }, [ticketRef, ticket_item]);

    return (
        <>
            <div className={`modalParentContainer${!visible ? " animate__animated animate__slideOutDown" : " animate__animated animate__slideInUp" }`} style={{backgroundColor:"#0D0404"}}>
                <div className="modalContainer" style={{paddingTop:"20px"}}>
                    <div className="appCont" style={{position:'relative', height:"100%", display:'flex', width:'100%', flexDirection:'column', gap:'20px', padding:"0px 20px"}}>
                        <div style={{}}>
                            <BackArrow arrowStyle={{position:'relative', left:"-5px"}} arrowBtnStyle={{position:"relative", padding:"0px", top:"0px"}} onPress={onModalClose} />
                        </div>
                        {ticket_item && (
                            <div ref={ticketRef} style={{height:"calc(100% - 140px)", display:"flex", width:"100%", position:'relative'}}>
                                <div className="ticketBg" style={{padding:"24px", display:'flex', flexDirection:'column', justifyContent:'space-between', borderRadius:'20px 20px 0px 0px', width:'100%', position:'relative'}}>
                                    <div className="ticketBg image" style={{backgroundImage:`url(${ticket_item.event.cover_image ? ticket_item.event.cover_image : require('../../assets/img/mpt.png')})`, width:"100%", height:"100%", position:'absolute', left:"0px", top:"0px", borderRadius: "20px 20px 0px 0px"}}>
                                        <div style={{borderRadius: "20px 20px 0px 0px"}}></div>
                                    </div>
                                    <div style={{width:"100%", height:"100%", position:'absolute', backgroundColor:'rgba(52, 39, 38, 0.7)', borderRadius:'18px 18px 0px 0px', left:"0px", top:"0px"}}></div> 

                                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', gap:"8px", position:'relative'}}>
                                        <div style={{width:"60px", height:"60px", backgroundColor:'rgba(255, 255, 255, 0.7)', padding:"8px", borderRadius:"12px"}}>
                                            <QRCode value={ticketLink} style={{width:'100%', height:'100%', objectFit:'contain'}} bgColor={"transparent"} />
                                        </div>
                                        <p className="buttonFrameText">{accountUser.fullname}</p>
                                        <p className="buttonFrameText">ID: 7987{ticket_item.ticket_purchase.id}</p>
                                    </div>

                                    <div style={{marginTop:"12px", marginBottom:"32px", width:"100%", position:'relative'}}>
                                        <div style={{position:'absolute', left:"0px"}}>
                                            <LineBigSvg width={ticketRef.current ? ticketRef.current.clientWidth : 390} />
                                        </div>
                                        <div className="bgMain" style={{width:"71px", height:"71px", position:'absolute', borderRadius:"50px", top:"-20px", left:"-105px", transform:"scaleX(2)"}}></div>
                                        <div className="bgMain" style={{width:"71px", height:"71px", position:'absolute', borderRadius:"50px", top:"-20px", right:"-105px", transform:"scaleX(2)"}}></div>
                                    </div>
                                    
                                    <div style={{padding:"10px 12px", borderRadius:"12px", backgroundColor:'rgba(255, 255, 255, 0.3)', position:'relative'}}>
                                        <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
                                            {ticket_item.event.user.profile_picture ? (
                                                <div className="profilePictureUser" style={{backgroundColor:'#c4c4c4'}}>
                                                    <img src={ticket_item.event.user.profile_picture} className="profilePictureUser" alt="user profile" />
                                                </div>
                                            ): (
                                                <ProfileGenerator title={ticket_item.event.user.username?ticket_item.event.user.username[0].toUpperCase():"A"} constStyle={{marginBottom:"0px"}} textStyle={{fontSize:"13px", lineHeight:"16px"}} />
                                            )}
                                            <span className="itemOwner">{ticket_item.event.user.username}</span>
                                        </div>
                                        <p className="sectionTitle" style={{fontSize:"14px", marginTop:"12px", lineHeight:"16px"}}>{ticket_item.event.name}</p>
                                        
                                        <div style={{marginTop:"12px"}}>
                                            {/* <div className="postOwner" style={{alignItems:'center', gap:"4px"}}>
                                                <PriceTagSmallSvg />
                                                <p className="postOwnerTextl" style={{fontSize:"12px", marginLeft:"0px"}}>Price: {ticket_item.price === 0 ? "Free" : `${SYMBOL}${parseFloat(`${ticket_item.price}`).toLocaleString()}`}</p>
                                            </div> */}
                                            <div className="postOwner" style={{gap:"8px"}}>
                                                <div style={{display:'flex', alignItems:'center', gap:"4px"}}>
                                                    <PriceTagSmallSvg />
                                                    <p className="postOwnerTextl" style={{fontSize:"10px",fontWeight:'500', lineHeight:"14px", marginLeft:'0px'}}>Price: {parseFloat(ticket_item.price) > 0 ? `${SYMBOL}${parseFloat(`${ticket_item.price}`).toLocaleString()}` : "Free"}</p>
                                                </div>
                                                <div style={{display:'flex', alignItems:'center', gap:"4px"}}>
                                                    <PriceTagSmallSvg />
                                                    <p className="postOwnerTextl" style={{fontSize:"10px",fontWeight:'500', lineHeight:"14px", marginLeft:'0px'}}>Quantity: x{ticket_item.ticket_purchase.quantity}</p>
                                                </div>
                                            </div>
                                            <div className="postOwner" style={{marginTop:"12px", flexDirection:'column', justifyContent:'space-between', alignItems:'flex-start', gap:"12px"}}>
                                                <div style={{display:'flex', alignItems:'center', gap:"4px"}}>
                                                    <DateIconSvg /> 
                                                    <p className="postOwnerTextl" style={{fontSize:"12px", marginLeft:"0px"}}>Date: {getDateStr(getUTCStr(ticket_item.event.start_date))}</p>
                                                </div>
                                                <div style={{display:'flex', alignItems:'center', gap:"4px"}}>
                                                    <TimeIconSvg /> 
                                                    <p className="postOwnerTextl" style={{fontSize:"12px", marginLeft:"0px"}}>Time: {getTimeStr(getUTCStr(ticket_item.event.start_date), false)}</p>
                                                </div>
                                            </div>
                                            <div className="postOwner" style={{marginTop:"12px"}}>
                                                <LocationSvg />
                                                <p className="postOwnerTextl" style={{fontSize:"12px", marginLeft:"0px"}}>Location: {ticket_item.event.location_type === 'venue' ? `${processAddressnMap(ticket_item.event.location, "addressLabel").slice(0, 40)}` : ticket_item.event.online_link}</p>
                                            </div>
                                        </div>
                                    </div>  
                                    <div id="actionComp" style={{display:'flex', justifyContent:'center', marginTop:"24px", position:'relative'}}>
                                        {/* <button className="actionBtn" onClick={handleShare}>
                                            <div className="actionIconBtn" style={{width:"30px", height:"30px", padding:"2px"}}>
                                                <ShareBigSvg />
                                            </div>
                                            <p className="locationRegion" style={{fontSize:"12px"}}>Share</p>
                                        </button> */}
                                        {/* <button className="actionBtn" onClick={() => copyLink()}>
                                            <div className="actionIconBtn" style={{width:"30px", height:"30px", padding:"2px"}}>
                                                <LinkBigSvg />
                                            </div>
                                            <p className="locationRegion" style={{fontSize:"12px"}}>Copy Link</p>
                                        </button> */}

                                        <button className="actionBtn" onClick={toDownloadImg}>
                                            <div className="actionIconBtn" style={{width:"30px", height:"30px", padding:"2px"}}>
                                                <DownloadBigSvg />
                                            </div>
                                            <p className="locationRegion" style={{fontSize:"12px"}}>Download</p>
                                        </button>
                                    </div>                       
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
                
            </div>
            <ModalLoader visible={modalLoading} />
        </>
    )
}

export default TicketDetail;