import { useState, useEffect } from "react";
import { getDateStr, getTimeStr } from '../../action/generalFunc';
import { useSelector } from "react-redux";
import { UrlType, generalObj } from "../../constant/objects_types";
import { RootState } from "../../redux";
import ProfileGenerator from "../ProfileGenerator";
import { IoEllipseSharp } from "react-icons/io5";
import ButtonFrame from "../ButtonFrame";
import { useNavigate } from "react-router-dom";
import { SYMBOL } from "../../constant/constant";


const ResellTicketItem = ({ item, listCont, itemCont, imageCont, is_searched=false, updateTicket }:{
    item: generalObj;
    listCont?: object;
    itemCont?: object;
    imageCont?: object;
    is_searched?:boolean;
    updateTicket:(val:generalObj) => void;
}) => {
    const account = useSelector((state:RootState) => state.account);
    const [eventOwner, setEventOwner] = useState<generalObj | null>(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (!item || !account || !account.isAuthenticated) return;
        //if (item.user_obj) {
        setEventOwner(item.user_obj);
        // }else {
        //     // console.log(item);
        //     // const loadUser = async () => {
        //     //     const result:generalObj = await fetchUser(account.token, item.user_id);
        //     //     if (result.status && result.data.data.user) {
        //     //         setEventOwner(result.data.data.user);
        //     //         //updateTicket(result.data.data.user);
        //     //     }else {
        //     //         setEventOwner(null);
        //     //     }
        //     // }
        //     // loadUser()
        // }
    }, [item, account, updateTicket]);

    return (
        item && (
            <>
                <div className="listContainer" style={{cursor:"pointer", ...listCont}}>
                    <div style={itemCont}>
                        <div className="displayImageContainer" style={imageCont} onClick={()=> navigate(`${UrlType.RESELL_TICKET_DETAIL}${item.id}`)}>
                            <div className="displayImage" style={{backgroundColor:'#c4c4c4'}}>
                                <img src={item.image_url} className="displayImage" style={{backgroundColor:'#302929'}} alt="ticket item" />
                            </div>
                        </div>
                        {eventOwner ? (
                            <div className="sectionDisplay" style={{marginTop: 15}}>
                                <a href={"#userprofile"} onClick={() => account && account.isAuthenticated && (account.id === item.user_id ? navigate(`${UrlType.PROFILE_USER}me`) : navigate(`${UrlType.PROFILE_USER_ID}${item.user_id}/`))} style={{display:'flex', alignItems:'center', gap:'5px'}}>
                                    {eventOwner.profile_picture ? (
                                        <div className="profilePictureUser" style={{backgroundColor:'#c4c4c4'}}>
                                            <img src={eventOwner.profile_picture} className="profilePictureUser" alt="user profile" />
                                        </div>
                                    ): (
                                        <ProfileGenerator title={eventOwner.username?eventOwner.username[0].toUpperCase():"A"} constStyle={{marginBottom:"0px"}} textStyle={{fontSize:"13px", lineHeight:"16px"}} />
                                    )}
                                    <span className="itemOwner">{eventOwner.username}</span>
                                </a>
                            </div>
                        ):(
                            <div className="postOwner" style={{alignItems:'center'}}>
                                <div className="profilePictureUser" style={{backgroundColor:'rgba(255, 255, 255, 0.12)'}}></div>
                                <div className="postOwnerText" style={{backgroundColor:'rgba(255, 255, 255, 0.12)', width:"100px", height:"16px", borderRadius:"10px"}}></div>
                            </div>
                        )}
                        <div className="sectionDisplay">
                            <div onClick={()=> navigate(`${UrlType.RESELL_TICKET_DETAIL}${item.id}`)} style={{marginTop: item.isOwner?"15px": "5px", maxWidth:account && item.user_id === account.id?'100%':'65%'}}>
                                <p className="itemOwner" style={is_searched ? {fontSize:14, marginTop:5} : {marginTop:10, fontSize:16}}>{item.ticket_name}</p>
        
                                <p className="itemOwner" style={{marginTop:10, fontSize:16}}>{`${SYMBOL}${parseFloat(`${item.price}`).toLocaleString()}`}</p>
        
                                <div style={{display:'flex', marginTop:"8px", alignItems:'center'}}>
                                    <span className="itemOwner" style={{fontWeight:"700", fontSize:"13px", color:"#9E9B9B"}}>{getDateStr(`${item.start_date}`, true)}</span>
                                    <IoEllipseSharp size={"4px"} color="#9E9B9B" style={{margin:"0px 5px", top:"7px"}} />
                                    <span className="itemOwner" style={{fontWeight:"700", fontSize:"13px", color:"#9E9B9B"}}>{getTimeStr(`${item.start_date}`, false)}</span>
                                </div>
                            </div>
                            {account && item.user_id !== account.id ? (
                                <div style={{display:"flex", alignItems:'center', justifyContent:'flex-end'}}>
                                    <ButtonFrame title="Buy" contStyle={{
                                        backgroundColor:'#DB012E', 
                                        border:"none",
                                        width: 'auto',
                                        padding:"4px 18px",
                                        borderRadius:"8px"
                                    }} onPress={() => navigate(`${UrlType.RESELL_TICKET_DETAIL}${item.id}`)}  />
                                </div>
                            ):null}
                        </div>
                    </div>
                </div>
            </>
        )
    )
}

export default ResellTicketItem;