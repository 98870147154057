import { useRef } from 'react';

const Switch = ({ value, onValueChange }:{
    value:boolean,
    onValueChange: (val:boolean) => void
}) => {

    const inputRef = useRef<HTMLInputElement | null>(null);
    
    const updateSlider = () => {
        if (!inputRef) return;
        inputRef.current?.setAttribute('checked', `${!value}`);
    }

    return (
        <label className="switch">
            <input ref={inputRef} type="checkbox" checked={value} onChange={(e) => onValueChange(e.target.value === "on" ? true : false)} />
            <span className="switch_slider" onClick={updateSlider}></span>
        </label>
    )
}

export default Switch;