import { useState, useEffect } from 'react';
import { PayConfirmSvg } from '../../styles/IconStyle';
import FooterAction from '../FooterAction';
import ButtonFrame from '../ButtonFrame';
import { useNavigate } from 'react-router-dom';
import { UrlType } from '../../constant/objects_types';

const EventComplete = ({ visible, isEditting }:{
    visible: boolean,
    isEditting: boolean,
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <>
                <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                    <div className="modalContainer" style={{}}>
                        <div style={{margin:"0px 20px", width:'100%', height:'100%', position:'relative'}}>
                            <div style={{height:"calc(100% - 90px)", display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                <PayConfirmSvg />
                                <div style={{marginTop:"25px"}}>
                                    <div className='sectionTitle' style={{textAlign:'center', fontSize:"14px"}}>Awesome!</div>
                                    <div className="locationRegion" style={{textAlign:'center', marginTop:"5px"}}>{isEditting ? "Your event has been updated and good to go" : "Your event is all set up and good to go" }.</div>
                                </div>
                            </div>
                            <FooterAction contStyle={{border:'none', padding:"0", bottom:"20px"}}>
                                <>
                                    <ButtonFrame title="Go to my Events" contStyle={{marginTop: "12px", backgroundColor:'#DB012E', border:"none"}} onPress={() => navigate(`${UrlType.PROFILE_USER}me`)} />
                                    <ButtonFrame title="Return to Home" contStyle={{backgroundColor:'#4F4F4F', border:"none"}} onPress={() => navigate(UrlType.HOME)} />
                                </>
                            </FooterAction>
                        </div>
                    </div>
                </div>
            </>
            )}
            
        </>
    )
}

export default EventComplete;