import { useEffect, useState } from "react";
import { generalObj } from "../../constant/objects_types";
import InputField from "../InputField";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import PickerModal from "../modal/PickerModal";
import { getPhoneCode } from "../../action/generalFunc";

const NewResellContact = ({ item, editting, isError, onChange }:{
    item: generalObj,
    editting?: boolean,
    isError:generalObj,
    onChange: (name:string, val:any) => void
}) => {
    // const [editLoaded, setEditLoaded] = useState<boolean>(false);
    
    const [openPicker, setOpenPicker] = useState<boolean>(false);

    const [phoneCode, setPhoneCode] = useState<generalObj[]>([]);

    useEffect(() => {
        (async () => {
            let result = await getPhoneCode();
            let data:generalObj[] = [];
            result.forEach((element:generalObj) => {
                data.push({
                    id:element.id,
                    label:`${element.flag} ${element.phone_code}`,
                    value:element.phone_code
                })
            })
            setPhoneCode(data.sort((a:generalObj, b:generalObj) => {
                return a.value - b.value
            }));
        })();
    }, []);

    return (
        <>
            <div>
                <div className="inputField" style={styles.contSpace}>
                    <div className="inputLabel" style={styles.labelStyle}>Contact Number</div>
                </div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"10px"}}>
                    {/* Phone code */}
                    <div className="inputField" style={{...styles.contSpace, marginTop:0, width:"30%"}}>
                        <div className="inputTextCont" style={{flexDirection:'column'}}>
                            <button className={`inputText ${isError.eventType ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}} onClick={()=>setOpenPicker(true)}>
                                <div style={styles.activeText}>{item.fields.phone?item.fields.phone.label:''}</div>
                                <div style={{display:'flex'}}>
                                    {openPicker ? (
                                        <IoChevronUpOutline size={"16px"} color={'#FFF'} />
                                    ):(
                                        <IoChevronDownOutline size={"16px"} color={'#FFF'} />
                                    )}
                                </div>
                            </button>
                            
                        </div>
                    </div>
                    <div style={{width:"70%"}}>
                        <InputField id={`number`} inputProp={{value:item.fields.phone_number, type:'number', placeholder:'Input phone number'}} isError={isError.phone_number} customInputField={{paddingTop:0}} labelStyle={styles.labelStyle} onTextChange={(val) => onChange('phone_number', val)} message={isError.phone_number && {text:"Number must have upto 10 characters", style: {}}} />
                    </div>
                </div>    

                {openPicker && <PickerModal visible={openPicker} pickerItem={phoneCode} selectedValue={item.fields.phone?item.fields.phone.label:''} onChange={(val) => onChange('phone', val)} modalClose={()=>setOpenPicker(false)} /> }    
                
                {/* Contact Email */}
                <InputField id="email" label='Contact Email' inputProp={{value:item.fields.email, type:"email", placeholder:'Input Email'}} isError={isError.email} customInputField={styles.contSpace} labelStyle={styles.labelStyle} onTextChange={(val) => onChange('email', val)} message={isError.email && {text:"Enter a valid email address", style: {}}} />

                {/* Description */}
                <InputField id="information" label='More Information' multiline={true} inputProp={{value:item.fields.information, maxLength:250}} isError={isError.information} customInputText={{lineHeight:"20px", height:"50px", maxHeight:"50px"}} customInputField={styles.contSpace} labelStyle={styles.labelStyle} onTextChange={(val) => {
                    onChange('information', val)
                }} message={isError.information && {text:"Description must have atleast 15 characters", style: {}}} />
                
            </div>
        </>
    )
}

export default NewResellContact;

const styles:generalObj = {
    labelStyle: {
        color:'#FFF'
    },
    dateText: {
        fontFamily:'400',
        fontSize:"13px",
        textAlign: 'start',
        color:'rgba(255, 255, 255, 0.6)'
    },
    activeText: {
        color: 'rgba(255, 255, 255, 0.8)',

    },
    locationStlye: {
        textOverflow:'ellipsis', 
        whiteSpace:'nowrap',
        width: "100%",
        overflow: "hidden"
    },
    contSpace: {
        marginTop:"24px",
        padding: "0px"
    },
    messageText: {
        fontWeight: '400',
        lineHeight: "19px",
        color: '#FFF'
    },
    messageTextOne: {
        color: 'rgba(255, 255, 255, 0.3);',
        alignSelf: 'flex-start'
    },
}