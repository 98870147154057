import { useCallback, useEffect, useState } from "react";
import { generalObj } from "../../constant/objects_types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import InputField from "../InputField";
import { IoChevronDownOutline, IoChevronUpOutline, IoClose } from "react-icons/io5";
import PickerModal from "../modal/PickerModal";
import { CalendarLightSvg, RemoveSvg } from "../../styles/IconStyle";
import DateTimePickerModal from "../modal/DateTimePickerModal";
import { fetchEventSubCategory, fetchEventType } from "../../action/generalFunc";

const NewResellDetail = ({ item, editting, isError, onChange, scrollRef }:{
    item: generalObj,
    editting?: boolean,
    isError:generalObj,
    onChange: (name:string, val:any) => void,
    scrollRef: generalObj | null
}) => {

    const [eventType, setEventType] = useState<generalObj[]>([
        {
            id:'00',
            label:'--Please select--',
            value:''
        }
    ]);
    const [eventSubType, setEventSubType] = useState<generalObj[]>([
        {
            id:'00',
            parent_id: "00",
            label:'--Please select--',
            value:'',
            show:true
        }
    ]);
    
    const [openPicker, setOpenPicker] = useState<boolean>(false);
    const [openSubPicker, setOpenSubPicker] = useState<boolean>(false);
    
    const [startDate, setStartDate] = useState<Date>(new Date());
    const startMinDate = new Date();
    const [fstartDate, setFStartDate] = useState<string | null>(null);
    const [fstartTime, setFStartTime] = useState<generalObj>({
        value: null,
        format: ''
    });
    const [openStart, setOpenStart] = useState<boolean>(false);
    const [openStartTime, setOpenStartTime] = useState<boolean>(false);    
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [endMinDate, setEndMinDate] = useState<Date>(new Date());
    const [fendDate, setFEndDate] = useState<string | null>(null);
    const [fendTime, setFEndTime] = useState<generalObj>({
        value: null,
        format: ''
    });
    const [openEnd, setOpenEnd] = useState<boolean>(false);
    const [openEndTime, setOpenEndTime] = useState<boolean>(false);

    const [tagSuggestActive, setTagSuggestActive] = useState<boolean>(false);
    const [tagSuggest, setTagSuggest] = useState<Array<string>>([]);
    const [editLoaded, setEditLoaded] = useState<boolean>(false);
    const [tagText, setTagText] = useState<string>('');
    const [performerText, setPerformerText] = useState<string>('');
    const [performerSuggestActive, setPerformerSuggestActive] = useState<boolean>(false);
    const [performerSuggest, setPerformerSuggest] = useState<Array<string>>([]);
    

    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        if (!account) return;
        
        const fetchevtSubType = async () => {
            const result:generalObj = await fetchEventSubCategory(account.token);
            if (result.status) {
                let evtSubType:generalObj[] = [
                    {
                        id:'00',
                        label:'--Please select--',
                        value:'',
                        show: true
                    }
                ];
                result.data.data.forEach((element:generalObj) => {
                    evtSubType.push({
                        id: `${element.id}`,
                        parent_id: `${element.event_type_id}`,
                        label: element.name,
                        value: element.name,
                        show: false
                    });
                });
                console.log(evtSubType);
                setEventSubType([...evtSubType]);
            }else {
                alert(result.message);
            }
        }
        const fetchevtType = async () => {
            const result:generalObj = await fetchEventType(account.token);
            if (result.status) {
                let evtType = [
                    {
                        id:'00',
                        label:'--Please select--',
                        value:''
                    }
                ];
                result.data.data.forEach((element:generalObj) => {
                    evtType.push({
                        id: `${element.id}`,
                        label: element.name,
                        value: element.name
                    });
                });

                console.log(evtType);
                setEventType([...evtType]);
            }else {
                alert(result.message);
            }
        }

        fetchevtType();
        fetchevtSubType();
    }, [account]);

    const displayDate = useCallback((selectedDate:Date, fieldName:string) => {
        const currentDate = selectedDate;
        let fDate = `${currentDate.getDate()} / ${currentDate.getMonth() + 1} / ${currentDate.getFullYear()}`;
        let hour = '';
        if (currentDate.getHours() > 12) {
            hour = `${currentDate.getHours() - 12}`;
        }else {
            if (currentDate.getHours() < 10) {
                hour = `0${currentDate.getHours()}`;
            }else {
                hour = `${currentDate.getHours()}`
            }
        }

        let fTime = `${hour} : ${currentDate.getMinutes() < 10?`0${currentDate.getMinutes()}`: currentDate.getMinutes()} : ${currentDate.getSeconds()< 10?`0${currentDate.getSeconds()}`: currentDate.getSeconds()}`;
        setFStartDate(fDate);
        setFStartTime({
            value: fTime,
            format: currentDate.getHours() <= 11 ? 'AM' : 'PM'
        });
        setStartDate(new Date(currentDate));
        setEndMinDate(new Date(currentDate));
        onChange(fieldName, {
            startDate: currentDate,
            endDate: '' //endDate ? endDate : ''
        });
    }, [onChange])
    
    const displayEndDate = useCallback((selectedDate:Date, fieldName:string) => {
        const currentDate = selectedDate;
        
        let fDate = `${currentDate.getDate()} / ${currentDate.getMonth() + 1} / ${currentDate.getFullYear()}`;
        let hour = '';
        if (currentDate.getHours() > 12) {
            hour = `${currentDate.getHours() - 12}`;
        }else {
            if (currentDate.getHours() < 10) {
                hour = `0${currentDate.getHours()}`;
            }else {
                hour = `${currentDate.getHours()}`
            }
        }
        
        let fTime = `${hour} : ${currentDate.getMinutes() < 10?`0${currentDate.getMinutes()}`: currentDate.getMinutes()} : ${currentDate.getSeconds()< 10?`0${currentDate.getSeconds()}`: currentDate.getSeconds()}`;
        setFEndDate(fDate);
        setFEndTime({
            value: fTime,
            format: currentDate.getHours() <= 11 ? 'AM' : 'PM'
        });
        setEndDate(new Date(currentDate));
        onChange(fieldName, {
            startDate: startDate,
            endDate: currentDate
        });
    }, [onChange, startDate]);

    const updateEventType = useCallback((val:generalObj) => {
        setEventSubType((prevEventSubType) => (
            prevEventSubType.filter((item:generalObj) => {
                if (item.parent_id === val.id) {
                    item.show = true;
                }else if (item.id === "00") {
                    item.show = true;
                }else {
                    item.show = false;
                }
                return item;
            })
        ));
        onChange('eventType', val);
    }, [onChange]);

    useEffect(() => {
        if (!item) return;
        setPerformerSuggest([...item.fields.performers]);
        setTagSuggest([...item.fields.tags]);
    }, [item]);

    useEffect(() => {
        if (!item || !editting || editLoaded) return;
        displayDate(new Date(`${item.fields.oneTime.startDate}`), 'oneTime');
        displayEndDate(new Date(item.fields.oneTime.endDate), 'oneTime');
        
        updateEventType(item.fields.eventType);
        setEditLoaded(true);
    }, [item, editting, editLoaded, displayDate, updateEventType, displayEndDate]);

    
    const submitTag = (evt:generalObj) => {
        if (tagText.length <= 1 || !scrollRef || evt.key !== "Enter") return;
        const get_existing = tagSuggest.filter(item => item.toLowerCase() === tagText.toLowerCase());
        if (get_existing.length >= 1) return;

        setTagSuggest([tagText, ...tagSuggest]);
        tagInputChange('');
        onChange('tags', [tagText, ...tagSuggest]);
        setTagText('');
    }

    const submitPerformer = (evt:generalObj) => {
        if (performerText.length <= 1 || !scrollRef || evt.key !== "Enter") return;
        const get_existing = performerSuggest.filter(item => item.toLowerCase() === performerText.toLowerCase());
        if (get_existing.length >= 1) return;

        setPerformerSuggest([performerText, ...performerSuggest]);
        performerInputChange('');
        onChange('performers', [performerText, ...performerSuggest]);
        setPerformerText('');
    }

    const tagInputChange = (val:string) => {
        const new_val = val.toString().replace(" ", "")
        setTagText(new_val);
        if (new_val.length >= 2) {
            setTagSuggestActive(false);
        }else {
            setTagSuggestActive(false);
        }
    }

    const performerInputChange = (val:string) => {
        const new_val = val//.toString().replace(" ", "")
        setPerformerText(new_val);
        if (new_val.length >= 2) {
            setPerformerSuggestActive(false);
        }else {
            setPerformerSuggestActive(false);
        }
    }

    const removeTag = (val:string) => {
        const newSuggest = tagSuggest.filter(item => item.toLowerCase() !== val.toLowerCase());
        setTagSuggest([...newSuggest])
    }

    const removePerformer = (val:string) => {
        const newPerformer = performerSuggest.filter(item => item.toLowerCase() !== val.toLowerCase());
        setPerformerSuggest([...newPerformer])
    }

    return (
        <>
        <div>
            {/* Name of Event */}
            <InputField id="name" label='Ticket Name' inputProp={{value:item.fields.name, maxLength:30}} isError={isError.name} customInputField={styles.contSpace} labelStyle={styles.labelStyle} onTextChange={(val) => onChange('name', val)} message={isError.name && {text:"Name must have atleast 5 characters", style: {}}} />
            
            {/* Performers */}
            <div className="inputField" style={{...styles.contSpace}}>
                <InputField id="performers" label='Performers (If applicable)' inputProp={{value:performerText, placeholder:"Hit ENTER after entering a performer"}} customInputField={{marginTop:"0px", paddingBlock:"0px"}} customInputText={performerSuggestActive ? {borderEndEndRadius:"0px", borderEndStartRadius:"0px"} : {}} labelStyle={styles.labelStyle} onTextChange={(val) => performerInputChange(val)} submitEditing={submitPerformer} />
                
                <div style={{display:'flex', gap:"12px", marginTop:"12px",width: "100%", overflowY: "hidden"}}>
                    {performerSuggest.map((item, index) => (
                        <button onClick={()=>removePerformer(item)} key={index} style={{display:'flex', justifyContent:'center', alignItems:'center', padding:"4px 8px", backgroundColor:"#302929", borderRadius:"24px", gap:"8px"}}>
                            <div className="skipText bodyText"  style={{fontWeight:'700'}}>#{item}</div>
                            <div style={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                                <IoClose size={"14px"} color={"#FFF"} />
                            </div>
                        </button>
                    ))}
                </div>
                
            </div>
            
            {/* Type of Event */}
            <div className="inputField" style={styles.contSpace}>
                <div className="inputLabel" style={styles.labelStyle}>Event Type</div>
                <div className="inputTextCont" style={{flexDirection:'column'}}>
                    <button className={`inputText ${isError.eventType ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}} onClick={()=>setOpenPicker(true)}>
                        <div style={styles.activeText}>{item.fields.eventType?item.fields.eventType.label:'--Please select--'}</div>
                        <div style={{display:'flex'}}>
                            {openPicker ? (
                                <IoChevronUpOutline size={"16px"} color={'#FFF'} />
                            ):(
                                <IoChevronDownOutline size={"16px"} color={'#FFF'} />
                            )}
                        </div>
                    </button>
                    {isError.eventType && (
                        <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                            Please select an event type.
                        </span>
                    )}
                    
                </div>
            </div>

            {/* Type of Sub Event */}
            <div className="inputField" style={styles.contSpace}>
                <div className="inputLabel" style={styles.labelStyle}>Sub Category</div>
                <div className="inputTextCont" style={{flexDirection:'column'}}>
                    <button className={`inputText ${isError.eventSub ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}} onClick={()=>setOpenSubPicker(true)}>
                        <div style={styles.activeText}>{item.fields.eventSub?item.fields.eventSub.label:'--Please select--'}</div>
                        <div style={{display:'flex'}}>
                            {openPicker ? (
                                <IoChevronUpOutline size={"16px"} color={'#FFF'} />
                            ):(
                                <IoChevronDownOutline size={"16px"} color={'#FFF'} />
                            )}
                        </div>
                    </button>
                    {isError.eventSub && (
                        <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                            Please select an event Sub Category.
                        </span>
                    )}
                    
                </div>
            </div>
            
            {/* Ticket Price */}
            <div style={styles.contSpace}>
                <InputField id={`price_ticket`} label='Ticket Price' inputProp={{value:item.fields.price, type:'number', placeholder:'19,400'}} isError={isError.price} customInputField={{paddingTop:0}} labelStyle={styles.labelStyle} onTextChange={(val) => onChange('price', val)} message={isError.price && {text:"Price field cannot be empty", style: {}}} />
            </div>

            
            {/* Date Time */}
            <div className="inputField" style={styles.contSpace}>
                <div className="inputLabel" style={styles.labelStyle}>Date</div>
                <div style={{marginTop:"10px"}}>
                    <div style={{display:'flex', gap:"8px", height:"40px", alignItems:'center'}}>
                        <button className={`inputText ${isError.onceStartDate ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}} onClick={()=>setOpenStart(true)}>
                            <div style={styles.dateText}>{fstartDate ?fstartDate: 'Start Date'}</div>
                            <div>
                                <CalendarLightSvg />
                            </div>
                        </button>
                        <div style={{marginInline:"8px", display:'flex', justifyContent:'center'}}>
                            <RemoveSvg />
                        </div>
                        <button className={`inputText ${isError.onceEndDate &&  "errorBorder"}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}} onClick={()=>setOpenEnd(true)}>
                            <div style={styles.dateText}>{fendDate ? fendDate : 'End Date'}</div>
                            <div>
                                <CalendarLightSvg />
                            </div>
                        </button>
                    </div>
                    {isError.onceStartDate && (
                        <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                            Please start date & time.
                        </span>
                    )}
                    {isError.onceEndDate && (
                        <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                            Please end date & time.
                        </span>
                    )}
                </div>
            </div>

            {openStart && <DateTimePickerModal visible={openStart} date={startDate} mode={'date'} onChange={(val) => val && displayDate(val, 'oneTime')} minimumDate={startMinDate} modalClose={()=>setOpenStart(false)} /> }
            {openEnd && <DateTimePickerModal visible={openEnd} date={endDate} mode={'date'} onChange={(val) => val && displayEndDate(val, 'oneTime')} minimumDate={endMinDate} modalClose={()=>setOpenEnd(false)} /> }
                    

            {/* Time */}
            <div className="inputField" style={styles.contSpace}>
                <div className="inputLabel" style={styles.labelStyle}>Time</div>
                <div className="inputTextCont">
                    <div style={{display:'flex', height:'40px', width:"100%", alignItems:'center'}}>
                        <button className={`inputText ${isError.onceStartDate ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}} onClick={()=>setOpenStartTime(true)}>
                            <div style={styles.dateText}>{fstartTime.value ?fstartTime.value: 'Start Time'}</div>
                            <div>
                                <span className="sectionTitle" style={{fontSize:"13px"}}>{fstartTime.format}</span>
                            </div>
                        </button>
                        <div style={{marginInline:"8px", display:'flex', justifyContent:'center'}}>
                            <RemoveSvg />
                        </div>
                        <button className={`inputText ${isError.onceEndDate &&  "errorBorder"}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}} onClick={()=>setOpenEndTime(true)}>
                            <div style={styles.dateText}>{fendTime.value ?fendTime.value : 'End Time'}</div>
                            <div>
                                <span className="sectionTitle" style={{fontSize:"13px"}}>{fendTime.format}</span>
                            </div>
                        </button>
                    </div>
                    
                </div>
            </div>
            {openStartTime && <DateTimePickerModal visible={openStartTime} date={startDate} mode={'time'} onChange={(val) => val && displayDate(val, 'oneTime')} modalClose={()=>setOpenStartTime(false)} /> }
            {openEndTime && <DateTimePickerModal visible={openEndTime} date={endDate} mode={'time'} onChange={(val) => val && displayEndDate(val, 'oneTime')} minimumDate={endMinDate} modalClose={()=>setOpenEndTime(false)} /> }

            {/* Tags */}
            <div className="inputField" style={{...styles.contSpace}}>
                <InputField id="tags" label='Tags (Optional)' inputProp={{value:tagText, placeholder:"Hit ENTER after entering a tag"}} customInputField={{marginTop:"0px", paddingBlock:"0px"}} customInputText={tagSuggestActive ? {borderEndEndRadius:"0px", borderEndStartRadius:"0px"} : {}} labelStyle={styles.labelStyle} onTextChange={(val) => tagInputChange(val)} submitEditing={submitTag} />
                
                <div style={{display:'flex', gap:"12px", marginTop:"12px",width: "100%", overflowY: "hidden"}}>
                    {tagSuggest.map((item, index) => (
                        <button onClick={()=>removeTag(item)} key={index} style={{display:'flex', justifyContent:'center', alignItems:'center', padding:"4px 8px", backgroundColor:"#302929", borderRadius:"24px", gap:"8px"}}>
                            <div className="skipText bodyText"  style={{fontWeight:'700'}}>#{item}</div>
                            <div style={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                                <IoClose size={"14px"} color={"#FFF"} />
                            </div>
                        </button>
                    ))}
                </div>
                
            </div>
            
        </div>
        {openPicker && <PickerModal visible={openPicker} pickerItem={eventType} selectedValue={item.fields.eventType?item.fields.eventType.label:''} onChange={(val) => updateEventType(val)} modalClose={()=>setOpenPicker(false)} /> }
        {openSubPicker && <PickerModal visible={openSubPicker} pickerItem={eventSubType.filter((item) => item.show)} selectedValue={item.fields.eventSub?item.fields.eventSub.label:''} onChange={(val) => onChange('eventSub', val)} modalClose={()=>setOpenSubPicker(false)} /> }
        </>
    )
}

export default NewResellDetail;

const styles:generalObj = {
    labelStyle: {
        color:'#FFF'
    },
    dateText: {
        fontFamily:'400',
        fontSize:"13px",
        textAlign: 'start',
        color:'rgba(255, 255, 255, 0.6)'
    },
    activeText: {
        color: 'rgba(255, 255, 255, 0.8)',

    },
    locationStlye: {
        textOverflow:'ellipsis', 
        whiteSpace:'nowrap',
        width: "100%",
        overflow: "hidden"
    },
    contSpace: {
        marginTop:"24px",
        padding: "0px"
    },
    messageText: {
        fontWeight: '400',
        lineHeight: "19px",
        color: '#FFF'
    },
    messageTextOne: {
        color: 'rgba(255, 255, 255, 0.3);',
        alignSelf: 'flex-start'
    },
}