const ShareModal = ({ visible=false, modalClose }:{
    visible?:boolean,
    modalClose: () => void,
}) => {
    return (
        <div className={`modalParentContainer${!visible ? " d-none" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"rgba(0, 0, 0, 0.70)", zIndex:"9999"}}>
            <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                <div className="actionView animate__animated animate__slideInUp" style={{height:"auto"}}>
                    <div style={{margin: "20px 20px 22px 20px", position: "relative", height: "100%"}}>
                        <div className="postOwnerText" style={{marginLeft:"0px", borderBottom:"1px solid rgba(255, 255, 255, 0.10)", padding:"16px 0px"}}>
                            Share to
                        </div>
                        <div className="actionItemCont shareItemCont" style={{position:'relative', height:'auto'}}>
                            <a href="#0" className="actionItem">
                                <span>
                                    <img src={require('../../assets/img/social/whatsapp.png')} alt="whatsapp" />
                                </span>
                                <span className="locationRegion" style={{fontSize:"13px"}}>Whatsapp</span>
                            </a>

                            <a href="#0" className="actionItem">
                                <span>
                                    <img src={require('../../assets/img/social/twitter.png')} alt="twitter" />
                                </span>
                                <span className="locationRegion" style={{fontSize:"13px"}}>Twitter</span>
                            </a>

                            <a href="#0" className="actionItem">
                                <span>
                                    <img src={require('../../assets/img/social/gmail.png')} alt="gmail" />
                                </span>
                                <span className="locationRegion" style={{fontSize:"13px"}}>Email</span>
                            </a>

                            <a href="#0" className="actionItem">
                                <span>
                                    <img src={require('../../assets/img/social/instagram.png')} alt="instagram" />
                                </span>
                                <span className="locationRegion" style={{fontSize:"13px"}}>Instagram</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareModal;