import { IoCheckmarkSharp } from "react-icons/io5";
import { generalObj } from "../../constant/objects_types";

type KycListObj = {
    index:number,
    item:generalObj,
    contStyle?:object | {},
    isAnim?:boolean,
    onPress:(id:number) => void
}

const KycList = ({ index, item, contStyle, isAnim, onPress }:KycListObj) => (
    <div className={`kycItem ${!isAnim?index === 0 ? "animate__animated animate__zoomIn" : `animate__animated animate__zoomIn animate__delay-1s` :""}`} style={{...contStyle, animationDelay: index > 0 ? `${index/5}s` : "", WebkitAnimationDelay: index > 0 ? `${index/5}s` : ""}}>
        <button style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', cursor:'pointer'}} onClick={() => onPress(item.id)}>
            <span style={{height:"100%", width:"100%", borderRadius:"12px", position:'relative', display:"inline-flex"}}>
                <img src={item.image} style={{width:'100%', height:'100%', borderRadius:"12px", position:'relative'}} alt="genre" />
                {item.selected && (
                    <>
                        <span style={{height:"100%", width:'100%', left:'0px', borderRadius:"12px", position:'absolute', backgroundColor:'rgba(49, 49, 49, 0.4)'}}></span>
                        <span style={{width:"16px",height:"16px",borderRadius:"50%",backgroundColor:'#DB012E',position:'absolute', right: "5px", top: "5px", display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <IoCheckmarkSharp size={"13px"} color="#FFF" />
                        </span>  
                    </>
                )}
            </span>
            
            <span style={{marginTop:"5px", color:'#FFF',fontWeight: '400',lineHeight: "19px",fontSize: "14px",textAlign:'center'}}>{item.title}</span>
        </button>
    </div>
)

export default KycList;