import { generalObj } from "../../constant/objects_types";
import { useState, useRef } from 'react';
import EventImageCropper from "../createevent/EventImageCropper";
import { getBlobUri } from "../../action/generalFunc";
import ModalLoader from "../modal/ModalLoader";

const NewResellImage = ({ itemuri, onChange, parentObj }:{ 
    itemuri: generalObj,
    onChange: (name:string, val:any) => void,
    parentObj: generalObj
}) => {
    const [image, setImage] = useState<string | null>(null);
    const [imageCropping, setImageCropping] = useState<string | null>(null);
    const [openCropper, setOpenCropper] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);

    const imageInputRef = useRef<HTMLInputElement | null>(null);
    const parentRef = useRef<HTMLInputElement | null>(null);


    const openImage = () => {
        if (!imageInputRef.current) return;
        imageInputRef.current.click();
    }

    const pickImage = async (e:generalObj) => {
        if (!e.target.files.length) return;
        if (e.target.files[0].size > 3000000) {
            alert("Cover image should not be greater than 3mb");
            e.target.value = "";
            return;
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0]);
        reader.onload = () => {
            if (!reader || !reader.result) return;

            const blob = new Blob([reader.result]);
            const url = URL.createObjectURL(blob);
            setImageCropping(url);
            setOpenCropper(true);
        }
    };

    

    const onModalClose = () => {
        setOpenCropper(false);
        setTimeout(() => {
            setImageCropping(null);
            if (imageInputRef.current) {
                imageInputRef.current.value = "";
            }
        }, 1000);
    }

    const handleImageCropped = async (val:Blob) => {
        setModalLoading(true);
        const url = URL.createObjectURL(val);
        setImage(url);
        onChange("coverImage", val);
        onChange("coverImageUrl", "");
        setModalLoading(false);
        
        onModalClose();
    }

    return (
        <div style={{height:"100%"}} ref={parentRef}>
            <div className="inputLabel" style={{lineHeight:"16px", marginTop:"32px", textAlign:'center'}}>Upload the cover image for your event and a promotion video to get people hyped up. This image would be used for the ticket as well.</div>
            <div style={{marginTop:"24px"}}>
                <div className="promotion__container">
                    <div className="promotion__item">
                        <div className="inputLabel" style={{marginBottom:"12px", textAlign:'center', color:'#FFF', fontWeight:"bold"}}>Cover Image</div>
                        <button id="imgID" className="borderColor" style={{border:"1px dashed rgba(255, 255, 255, 0.6)", borderRadius:"12px", display:'flex', justifyContent:'center', alignItems:'center', width: !parentRef || !parentRef.current ? `100%`: `${parentRef.current.clientWidth}px`, height: !parentRef || !parentRef.current ? `75%`: `${parentRef.current.clientWidth * (3/4)}px`}} onClick={openImage}>
                            {image || itemuri.fields.coverImage || itemuri.fields.coverImageUrl ? (
                                <img src={image ? image: itemuri.fields.coverImage ? getBlobUri(itemuri.fields.coverImage) : itemuri.fields.coverImageUrl} style={{width:'100%', aspectRatio:"4 / 3", borderRadius:"12px"}} alt="cover"  />
                            ): (
                                <div style={{width:'100%', aspectRatio:"4 / 3", borderRadius:"12px", display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                    <div className="sectionTopLink" style={{fontSize:"13px", lineHeight:"33px", marginTop:"30.5px"}}>Import Image</div>
                                    <div className="inputLabel" style={{lineHeight:"33px", marginTop:"8px", marginBottom:"30.5px"}}>Only support jpg, svg and png</div>
                                </div>
                            )}
                        </button>
                        <input type={"file"} accept={"image/*"} ref={imageInputRef} onChange={(e) => pickImage(e)} style={{display:"none"}}  />
                    </div>
                </div>
            </div>
            {imageCropping && parentObj && <EventImageCropper visible={openCropper} modalClose={() => onModalClose()} source={imageCropping} onCropChange={(valObj) => handleImageCropped(valObj)} parentRef={parentObj} />}
            <ModalLoader visible={modalLoading} />
        </div>
    )
}

export default NewResellImage;