import { combineReducers } from "redux";
import TickiReducer from "./accountReducer";
import RegisterReducer from "./registerationReducer";

const reducerCombiner = combineReducers({
    account: TickiReducer,
    registerInfo: RegisterReducer
});

export default reducerCombiner;

export type RootState = ReturnType<typeof reducerCombiner>;