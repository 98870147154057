
type buttonFrameObj = {
    title:string,
    titleStyle?:object | {},
    contClass?:object,
    contStyle?:object | {},
    iconType?:string | null,
    imgProp?:object | {},
    iconObj?:any,
    iconRight?: boolean,
    iconStyle?:object | {},
    onPress:() => void
};

const ButtonFrame = ({ title, titleStyle, contClass, contStyle, iconType=null, imgProp, iconObj, iconRight, iconStyle, onPress}:buttonFrameObj) => (
    <div className="buttonFrame" style={contClass}>
        <button style={contStyle} onClick={onPress}>
            <span>
                {iconType === 'image' && (
                    <img {...imgProp} style={iconStyle} alt={title}  />
                )}

                {iconType === 'icon' && !iconRight && iconObj && (
                    {iconObj}
                )}
            </span>
            <span className="buttonFrameText" style={titleStyle}>
                {title}
            </span>
            <span>
                {iconType === 'icon' && iconRight && iconObj && (
                    {iconObj}
                )}
            </span>

        </button>
    </div>
);

export default ButtonFrame;