import { IoChevronBackOutline } from "react-icons/io5";

const BackArrow = ({ arrowStyle, arrowBtnStyle, onPress }:{
    arrowStyle?:object,
    arrowBtnStyle?:object,
    onPress:() => void
}) => {
    return (
        <div className="backArrow" style={arrowStyle}>
            <button style={arrowBtnStyle} onClick={onPress}>
                <span>
                    <IoChevronBackOutline color="#FFF" size={'24px'} />
                </span>
            </button>
        </div>
    )
}

export default BackArrow;