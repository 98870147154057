import { ActionType, Action } from "./action_types";
import axios from "axios";
import { API_URL } from "../../constant/constant";
import { validateEmail } from "../../action/generalFunc";
import { Dispatch } from "redux";
import { generalObj } from '../../constant/objects_types';


export const login = ( principal:string, password:string ) => async (dispatch: Dispatch<Action>) => {
    let data = {}
    if (validateEmail(principal)) {
        data = {
            email:principal,
            password:password
        }
    }else {
        data = {
            username:principal,
            password:password
        }
    }
    var config = {
        method: 'post',
        url:`${API_URL}login`,
        data: data
    };
    
    let is_Success = await axios(config)
    .then((response) => {
        dispatch({
            type: ActionType.LOGIN_USER,
            payload: response.data
        });    
        return true;
    })
    .catch((error) => {
        if (error.response && error.response.data.error) {
            alert(error.response.data.message);
        }else {
            alert('Error connecting to server, try again later');
        }
         
        return false
    })

    return is_Success;
}

export const googleAuth = ( fullname:string, username:string, token:string, profile_picture:string, email:string ) => async (dispatch: Dispatch<Action>) => {
    let data = {
        fullname: fullname,
        username: username,
        token: token,
        profile_picture: profile_picture,
        email: email
    }
    
    var config = {
        method: 'post',
        url:`${API_URL}google/signup`,
        data: data
    };
    
    let is_Success = await axios(config)
    .then((response) => {
        dispatch({
            type: ActionType.LOGIN_USER,
            payload: response.data
        });    
        return true;
    })
    .catch((error) => {
        if (error.response && error.response.data.error) {
            if (typeof(error.response.data.message) === "object") {
                let msg = "";
                for (let key in error.response.data.message) {
                    msg += msg.length > 0 ? `, ${error.response.data.message[key]}` : error.response.data.message[key]
                }
                alert(msg);
            }else {
                alert(error.response.data.message);
            }
            
        }else {
            alert('Error connecting to server, try again later');
        }
         
        return false
    })

    return is_Success;
}

export const refreshToken = ( token:string ) => async (dispatch: Dispatch<Action>) => {
    
    var config = {
        method: 'post',
        url:`${API_URL}refresh`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    };
    
    let is_Success = await axios(config)
    .then((response) => {
        dispatch({
            type: ActionType.REFRESH_TOKEN,
            payload: {
                token: response.data.token,
                expires_in: response.data.expires_in
            }
        });    
        return true;
    })
    .catch((error) => {
        if (error.response && error.response.data.error) {
            console.log(error.response.data.message);
        }else {
            console.log('Error connecting to server, try again later');
        }
         
        return false
    })

    return is_Success;
}

export const register = (data:generalObj) => async (dispatch: Dispatch<Action>) => {
    var config = {
        method: 'post',
        url:`${API_URL}signup`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
    };

    let is_Success = await axios(config)
    .then((response) => {
        dispatch({
            type: ActionType.REGISTER_USER
        })
        return true;
    })
    .catch((error) => {
        if (error.response && error.response.data.error) {
            if (typeof(error.response.data.message) === 'object') {
                let error_msg:any = Object.values(error.response.data.message)[0];
                if (typeof(error_msg) === 'object') {
                    alert(error_msg[0]);
                }else {
                    alert(error_msg);
                }
            }else {
                alert(error.response.data.message);
            }
        }else {
            alert('Unable to create your account at the moment');
        }
        return false
    })

    return is_Success;
}

export const updateProfile = ( token:string, key:string, value:any, isSocial:boolean=false ) => async (dispatch: Dispatch<Action>) => {
    let formData = new FormData();
    if (key === "profile_picture") {;
        formData.append("profile_picture", value);
    }else {
        formData.append(key, value);
    }

    var config = {
        method: 'post',
        url:`${API_URL}update/profile`,
        headers: { 
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'multipart/form-data'
        },
        data: formData
    };

    let is_Success = await axios(config)
    .then((response) => {
        dispatch({
            type: ActionType.UPDATE_USER,
            payload: {
                name: key === 'profile_picture' ? "profileImage": key,
                data: key === 'profile_picture' ? response.data.data.user.profile_picture : value,
                isSocial: isSocial
            }
        });    
        return true;
    })
    .catch((error) => {
        if (error.response && error.response.data.error) {
            alert(error.response.data.message);
        }else {
            alert(`Error updating your ${key} profile, try again later`);
        }
        return false
    })

    return is_Success;
}

export const onboarding = () => async (dispatch: Dispatch<Action>) => {
    
    dispatch({
        type: ActionType.ONBOARDED_USER
    });
}


export const loadOnboarding = () => async (dispatch: Dispatch<Action>) => {
    
    dispatch({
        type: ActionType.ONBOARDING_USER
    });
}

export const logout = () => async (dispatch: Dispatch<Action>) => {
    dispatch({
        type: ActionType.LOGOUT_USER
    })
}