import { useRef } from "react";
import { SearchInputSvg } from "../styles/IconStyle";
import { generalObj } from "../constant/objects_types";
import { IoCloseOutline } from "react-icons/io5";

const SearchBar = ({ searchBarStyle, inputProp, clearSize="16px", customInputText, onTextChange, onKeyPressed }:{
    searchBarStyle?: object | {},
    inputProp?: generalObj,
    clearSize?: string,
    customInputText?:object | {},
    onTextChange:(event:any) => void,
    onKeyPressed?:(event:any) => void
}) => {
    const refInput = useRef<HTMLInputElement | null>(null);
    return (
        // SearchBar

        <div className="searchBarComp" style={searchBarStyle}>
            <div style={{display:'flex'}}>
                <SearchInputSvg />
            </div>
            <input ref={refInput} className="inputText" {...inputProp} style={customInputText}  onChange={(event) => onTextChange(event.target.value)} onKeyDown={(event) => onKeyPressed && onKeyPressed(event)} />
            {inputProp && inputProp.value.length > 0 && (
                <div style={{width:'10%'}}>
                    <button onClick={() => onTextChange('')}>
                        <span>
                            <IoCloseOutline size={clearSize} color="rgba(255, 255, 255, 0.6)" />
                        </span>
                    </button>
                </div>
            )}
            {/* <TextInput style={[globalStyle.inputText, {width:'90%', backgroundColor:'transparent'}]} autoFocus={true} value={searchText} placeholderTextColor={'rgba(255, 255, 255, 0.6)'} placeholder='Search events, artist' onChangeText={(val)=>searchTextOnChange(val)} submitEditing={()=>{
                searchTextOnChange(searchText); 
            }} /> */}
        </div>
    )
}

export default SearchBar;