import { useState, useEffect } from 'react';
import { generalObj } from '../../constant/objects_types';

const PickerModal = ({ visible, pickerItem, selectedValue, modalClose, onChange, customStlye }:{ 
    visible:boolean, 
    pickerItem: generalObj[],
    selectedValue: generalObj,
    modalClose: () => void,
    onChange: (val: generalObj) => void,
    customStlye?: object | {}
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`, left:"0px", ...customStlye}}>
                    <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                        <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                        <div className={`actionView${!visible ? " animate__animated animate__slideOutDown" : " animate__animated animate__slideInUp" }`} style={{height:"180px", padding:"10px 0px"}}>
                            <div style={{margin: "20px 0px 0px", position: "relative", height: "100%"}}>
                                <div style={{textAlign:'end', margin:'0px 20px'}}>
                                    <button onClick={modalClose}>
                                        <span className='sectionTopLink'>Done</span>
                                    </button>
                                </div>
                                <div className="actionItemCont picker" style={{height:'calc(100% - 60px)'}}>
                                    {pickerItem && (
                                        pickerItem.map((item:generalObj, index:number) => (
                                            <button className={`actionItem ${item.value === selectedValue ? " active" : ""}`} key={index} onClick={() => onChange(item)}>
                                                <span>{ item.label }</span>
                                            </button>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )

}

export default PickerModal;