import { SYMBOL } from "../../constant/constant";
import { generalObj } from "../../constant/objects_types";
import { AddSvg, SubstractSvg } from "../../styles/IconStyle";
import { useEffect, useState } from "react";

const TicketType = ({ item, counter, onPress, contStyle }:{
    item:generalObj, 
    counter:number,
    onPress: (id:number, qty:number) => void, 
    contStyle?:object
}) => {

    const [ticketQty, setTicketQty] = useState<number>(1);
    const palette = ["#DB012E", "#F45D01", "#033F63", "#DB5461", "#9F2042", "#FF715B", "#502274", "#F5AF00", "#003E1F", "#246A73"]

    useEffect(() => {
        setTicketQty(item.qty ? item.qty : 1);
    }, [item]);

    const ticketType:generalObj = {
        vvip: {
            is_background: false,
            gradient: {background: "linear-gradient(225deg, #956F12 0%, #DBAE3C 22.48%, #E8C97A 46.57%, #DBAE3C 72.80%, #976F0C 98.69%)"},
            color: '#443206',
            title: 'V.V.I.P'
        },
        vip: {
            is_background: false,
            gradient: {background: "linear-gradient(225deg, #696968 0%, #B2B2B1 35.88%, #CECECE 48.02%, #B5B5B3 57.32%, #696968 98.69%)"},
            color: '#2D2D2D',
            title: 'V.I.P'
        },
        regular:{
            is_background: true,
            color: '#FFF',
            title: 'Regular'
        }
    }

    const updateTicketQty = (id:number, qty:number) => {
        onPress(id, qty);
    }

    const buttonClicked = (id:number, newQty:number) => {
        let updatedObj = newQty < 1 ? 1 : newQty;
        setTicketQty(updatedObj);
        updateTicketQty(id, updatedObj);
    }

    const getColor = () => {
        let hexCodeIndex = counter <= palette.length ? counter : counter - palette.length;
        return palette[hexCodeIndex];
    }

    // if (!ticketType[item.category]) return(null);

    return (
        <>
            {!ticketType[item.category] ? (
                <div style={{border:"1px solid rgba(255, 255, 255, 0.15)", position:'relative', backgroundColor: item.active ? getColor() : 'transparent', borderRadius:"12px", marginTop:counter === 0 ? "0px": "24px", width:"100%", ...contStyle}}>
                    <div onClick={() => !item.active && updateTicketQty(item.id, 1)}>
                        <div style={{padding: "16px", position:'relative', paddingBottom:item.active ? 0 : '16px'}}>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div className="postOwnerTextl" style={{fontSize:"14px", lineHeight:"19px", color:'#FFF'}}>{ item.title }</div>
                                <div className="postOwnerTextl" style={{fontSize:"14px", lineHeight:"19px", color:'#FFF'}}>{item.price > 0 ? `${SYMBOL}${parseFloat(`${item.price}`).toLocaleString()}` : "Free" }</div>
                            </div>
                            {item.description && (
                                <div className="postOwnerTextl" style={{fontSize:"14px", lineHeight:"19px", marginTop:"8px", color:'#FFF'}}>{ item.description.slice(0, 75) }</div>
                            )}
                        </div>
                    </div>
                    {item.active && (
                        <div style={{display:"flex", gap:"10px", justifyContent:"flex-end", alignItems:"center", padding:"16px", paddingBlock:"8px", position:"relative"}}>
                            <button style={{display:"flex"}} onClick={() => buttonClicked(item.id, ticketQty - 1)}>
                                <SubstractSvg color={'#FFF'} />
                            </button>
                            <div style={{fontWeight:"600", fontSize:"14px", color:'#FFF', lineHeight:"16px"}}>{item.qty}</div>
                            <button style={{display:"flex", opacity:(item.qty >= (item.quantity - item.sold)) || item.qty === 10 ? "0.6" : "1"}} onClick={() => (item.qty >= (item.quantity - item.sold)) || item.qty === 10 ? null : buttonClicked(item.id, ticketQty + 1)}>
                                <AddSvg color={'#FFF'} />
                            </button>
                        </div>
                    )}
                    {item.sold >= item.quantity && (
                        <div style={{position:"absolute", width:"100%", backgroundColor:"rgba(8, 0, 0, 0.85)", color:"#DB012E", top:0, height:"100%", textAlign:"center", borderRadius:"12px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <span style={{fontWeight:"600", fontSize:"14px"}}>Sold Out</span>
                        </div>
                    )}
                </div>
            ): (
                <div style={{border:"1px solid rgba(255, 255, 255, 0.15)", position:'relative', backgroundColor: ticketType[item.category].is_background && item.active? '#DB012E':'transparent', borderRadius:"12px", marginTop:counter === 0 ? "0px": "24px", width:"100%", ...contStyle}}>
                    <div onClick={() => !item.active && updateTicketQty(item.id, 1)}>
                        {item.active && !ticketType[item.category].is_background && (
                            <div style={{position:'absolute', width:'100%', height:'100%', borderRadius:"12px", ...ticketType[item.category].gradient}}></div>
                        )}
                        <div style={{padding: "16px", position:'relative', paddingBottom:item.active ? 0 : '16px'}}>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div className="postOwnerTextl" style={{fontSize:"14px", lineHeight:"19px", color:item.active?ticketType[item.category].color:'#FFF'}}>{ ticketType[item.category].title }</div>
                                <div className="postOwnerTextl" style={{fontSize:"14px", lineHeight:"19px", color:item.active?ticketType[item.category].color:'#FFF'}}>{item.price > 0 ? `${SYMBOL}${parseFloat(`${item.price}`).toLocaleString()}` : "Free" }</div>
                            </div>
                            {item.description && (
                                <div className="postOwnerTextl" style={{fontSize:"14px", lineHeight:"19px", marginTop:"8px", color:item.active?ticketType[item.category].color:'#FFF'}}>{ item.description.slice(0, 75) }</div>
                            )}
                        </div>
                    </div>
                    {item.active && (
                        <div style={{display:"flex", gap:"10px", justifyContent:"flex-end", alignItems:"center", padding:"16px", paddingBlock:"8px", position:"relative"}}>
                            <button style={{display:"flex"}} onClick={() => buttonClicked(item.id, ticketQty - 1)}>
                                <SubstractSvg color={ticketType[item.category].color} />
                            </button>
                            <div style={{fontWeight:"600", fontSize:"14px", color:ticketType[item.category].color, lineHeight:"16px"}}>{item.qty}</div>
                            <button style={{display:"flex", opacity:(item.qty >= (item.quantity - item.sold)) || item.qty === 10 ? "0.6" : "1"}} onClick={() => (item.qty >= (item.quantity - item.sold)) || item.qty === 10 ? null : buttonClicked(item.id, ticketQty + 1)}>
                                <AddSvg color={ticketType[item.category].color} />
                            </button>
                        </div>
                    )}
                    {item.sold >= item.quantity && (
                        <div style={{position:"absolute", width:"100%", backgroundColor:"rgba(8, 0, 0, 0.85)", color:"#DB012E", top:0, height:"100%", textAlign:"center", borderRadius:"12px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <span style={{fontWeight:"600", fontSize:"14px"}}>Sold Out</span>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default TicketType;