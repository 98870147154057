import { RActionType, RAction } from "./action_types";
import { Dispatch } from "redux";


export const updateInfo = ( key:string, value:string ) => async (dispatch: Dispatch<RAction>) => {
    dispatch({
        type: RActionType.UPDATE_INFO,
        payload: {
            [key]:value
        }
    })
    return true;
}

export const clearInfo = () => async (dispatch: Dispatch<RAction>) => {
    dispatch({
        type: RActionType.CLEAR_INFO
    })
    return true;
}

