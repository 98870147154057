import { useEffect, useState } from "react";
import { generalObj } from "../../constant/objects_types";
import TicketType from "./TicketType";

const TicketModal = ({ visible, ticketObject, onSelect, modalClose }:{ 
    visible:boolean, 
    ticketObject: generalObj,
    onSelect: (val: generalObj) => void,
    modalClose: () => void,
}) => {
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [nticketObject, setNticketObject] = useState<generalObj | null>(null);
    const [activeId, setActiveId] = useState<string | number | null>(null);
    const [activeQty, setActiveQty] = useState<string | number | null>(null);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!ticketObject) return;
        setNticketObject(ticketObject);
        const activeTicket = ticketObject.ticket.filter((item:generalObj) => item.active);

        if (activeTicket.length > 0) {
            setActiveId(activeTicket[0].id);
            setActiveQty(activeTicket[0].qty ? activeTicket[0].qty : 1);
        }
    }, [ticketObject]);

    const updateTicket = (id:number | string, qty:number) => {
        if (!nticketObject) return;
        setNticketObject({...nticketObject, ticket:nticketObject.ticket.filter((item:generalObj) => {
            if (item.id === id) {
                item.active = true;
                item.qty = qty;
                setActiveId(id);
                setActiveQty(qty);
            }else {
                item.active = false;
            }
            return item;
        })})
    }

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`, left:"0px"}}>
                    <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                        <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                        <div className={`actionView${!visible ? " animate__animated animate__slideOutDown" : " animate__animated animate__slideInUp" }`} style={{padding:"10px 20px"}}>
                            <div style={{margin: "20px 0px 0px", position: "relative", height: "100%"}}>
                                <div style={{textAlign:'end', margin:'0px 20px'}}>
                                    <button onClick={()=>nticketObject && activeId && onSelect({
                                        id: nticketObject.id,
                                        ticket: activeId,
                                        quantity: activeQty
                                    })}>
                                        <span className='sectionTopLink'>Done</span>
                                    </button>
                                </div>
                                    {nticketObject && (
                                        <div className="actionItemCont picker" style={{overflow:"visible"}}>
                                            <div>            
                                                {nticketObject.ticket.length > 0 && nticketObject.ticket.map((ticket:generalObj, ind:number) => (
                                                    <TicketType key={ind} item={ticket} counter={ind} onPress={(id, qty)=>updateTicket(id, qty)} />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default TicketModal;