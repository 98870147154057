import { StarMedSvg, StarSmallSvg } from '../../styles/IconStyle';

const EventRating = () => {
    return (
        <div>
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <span className="sectionTitle">Ratings</span>
            </div>
            <div style={{display:'flex', marginTop:"16px", alignItems:'center'}}>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <div className='sectionTitle'>0</div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', margin:"8px 0px"}}>
                        <div>
                            <StarMedSvg />
                        </div>
                        <div>
                            <StarMedSvg />
                        </div>
                        <div>
                            <StarMedSvg />
                        </div>
                        <div>
                            <StarMedSvg />
                        </div>
                        <div>
                            <StarMedSvg />
                        </div>
                    </div>
                    <span className='inputLabel' style={{fontWeight:'500'}}>0 Reviews</span>
                </div>
                {/* <div style={{height:'100%', width:"1px", backgroundColor:'rgba(255, 255, 255, 0.08)', margin:"0px 18px"}}></div> */}
                <div style={{width:"100%", display:'flex', flexDirection:'column', justifyContent:'center', borderLeft: "1px solid rgba(255, 255, 255, 0.08)", marginLeft:'18px', paddingLeft: "18px"}}>
                    <div style={{display:'flex', marginBottom:"12px", alignItems:'center'}}>
                        <div style={{display:'flex'}}>
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                        </div>
                        <div style={{height:"2px", flex:1, borderRadius:"5px", margin:"0px 8px", backgroundColor:'rgba(255, 255, 255, 0.08)'}}></div>
                        <div>
                            <span className='locationRegion' style={{fontWeight:'400'}}>0</span>
                        </div>
                    </div>
                    <div style={{display:'flex', marginBottom:"12px", alignItems:'center'}}>
                        <div style={{display:'flex'}}>
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={false} />
                        </div>
                        <div style={{height:"2px", flex:1, borderRadius:"5px", margin:"0px 8px", backgroundColor:'rgba(255, 255, 255, 0.08)'}}></div>
                        <div>
                            <span className='locationRegion' style={{fontWeight:'400'}}>0</span>
                        </div>
                    </div>
                    <div style={{display:'flex', marginBottom:"12px", alignItems:'center'}}>
                        <div style={{display:'flex'}}>
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={false} />
                            <StarSmallSvg active={false} />
                        </div>
                        <div style={{height:"2px", flex:1, borderRadius:"5px", margin:"0px 8px", backgroundColor:'rgba(255, 255, 255, 0.08)'}}></div>
                        <div>
                            <span className='locationRegion' style={{fontWeight:'400'}}>0</span>
                        </div>
                    </div>
                    <div style={{display:'flex', marginBottom:"12px", alignItems:'center'}}>
                        <div style={{display:'flex'}}>
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={false} />
                            <StarSmallSvg active={false} />
                            <StarSmallSvg active={false} />
                        </div>
                        <div style={{height:"2px", flex:1, borderRadius:"5px", margin:"0px 8px", backgroundColor:'rgba(255, 255, 255, 0.08)'}}></div>
                        <div>
                            <span className='locationRegion' style={{fontWeight:'400'}}>0</span>
                        </div>
                    </div>
                    <div style={{display:'flex', marginBottom:"12px", alignItems:'center'}}>
                        <div style={{display:'flex'}}>
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={true} />
                            <StarSmallSvg active={false} />
                            <StarSmallSvg active={false} />
                            <StarSmallSvg active={false} />
                        </div>
                        <div style={{height:"2px", flex:1, borderRadius:"5px", margin:"0px 8px", backgroundColor:'rgba(255, 255, 255, 0.08)'}}></div>
                        <div>
                            <span className='locationRegion' style={{fontWeight:'400'}}>0</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventRating;