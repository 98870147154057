import { useEffect, useState } from "react";
import { getLocation, getLocationStates } from "../action/generalFunc";
import { generalObj } from "../constant/objects_types";

const useUserLocation = () => {

    const [locationData, setLocationData] = useState<generalObj | null>(null);
    const [locationStateData, setLocationStateData] = useState<generalObj[]>([]);

    useEffect(() => {
      (async () => {
          const location = await getLocation();
          setLocationData(location);
          return location;
      })().then(async (response) => {
        if (!response || !response.countryCode) {
          return;
        }
        const states:generalObj[] = await getLocationStates(response.countryCode);
        setLocationStateData(states);
      });
    }, []);

    return [locationData, locationStateData]
}

export default useUserLocation;