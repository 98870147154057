import InputField from "../InputField";
import ButtonFrame from "../ButtonFrame";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { useState } from "react";
import PickerModal from "../modal/PickerModal";
import { generalObj } from "../../constant/objects_types";

type RegContObj = {
    header:string,
    description:string,
    btnTitle:string,
    btnStyle?: object | {},
    id:string,
    allow_paired?: boolean | null,
    selectedObj?: generalObj,
    objList?: generalObj[]
    label?:string | null,
    multiline?: boolean | false,
    inputProp: object,
    refInput: (input:any) => void,
    isIcon?: any,
    iconPress?: () => void,
    onTextChange: (event:any) => void,
    updateCode?: (val:generalObj) => void,
    message?:object,
    onPress:() => void

}

const RegisterContent = ({ header, description, btnTitle, allow_paired, selectedObj, objList, btnStyle, id, label, multiline, inputProp, refInput, isIcon, iconPress, onTextChange, updateCode, message, onPress }:RegContObj) => {
    
    const [openPicker, setOpenPicker] = useState<boolean>(false);

    return (
        <>
            <h1 className="header">{header}</h1>
            <p className="desc">{description}</p>
            <div style={{display:"flex", alignItems:"flex-start", justifyContent:"center", gap:"10px", marginTop:'10px'}}>
                {/* Phone code */}
                {allow_paired && (
                    <div className="inputField" style={{marginTop:"8px", padding:0, width:"30%"}}>
                        <div className="inputTextCont" style={{flexDirection:'column', marginTop:0}}>
                            <button className={`inputText`} style={{display:'flex', justifyContent:'space-between', alignItems:'center',borderBottom:'1px solid rgba(255, 255, 255, 0.1)', backgroundColor: 'transparent', borderRadius: "0px", paddingInline:'8px'}} onClick={()=>setOpenPicker(true)}>
                                <div style={{color: 'rgba(255, 255, 255, 0.8)'}}>{selectedObj?selectedObj.label:''}</div>
                                <div style={{display:'flex'}}>
                                    {openPicker ? (
                                        <IoChevronUpOutline size={"16px"} color={'#FFF'} />
                                    ):(
                                        <IoChevronDownOutline size={"16px"} color={'#FFF'} />
                                    )}
                                </div>
                            </button>
                        </div>
                    </div>
                )}
                
                <div style={{width:allow_paired?"70%":"100%"}}>
                    <InputField id={id} label={label} customInputText={{borderBottom:'1px solid rgba(255, 255, 255, 0.1)', backgroundColor: 'transparent', borderRadius: "0px", paddingLeft:"8px", paddingRight:"8px", fontSize: "13px", fontWeight: '500'}} multiline={multiline} inputProp={inputProp} refInput={(input) => refInput(input)} isIcon={isIcon} iconPress={iconPress} message={message} onTextChange={(val) => onTextChange(val)} />
                </div>
            </div>

            <ButtonFrame title={btnTitle} contClass={{position:'absolute', bottom: "36px", width: "calc(100% - 40px)"}} contStyle={{backgroundColor:'#DB012E', border:"none", opacity:"0.6", ...btnStyle}} onPress={onPress} />

            
            {openPicker && selectedObj && objList && updateCode && <PickerModal visible={openPicker} pickerItem={objList} selectedValue={selectedObj?selectedObj.label:''} onChange={(val)=>updateCode(val)} modalClose={()=>setOpenPicker(false)} /> }
        </>
    )
}

export default RegisterContent;