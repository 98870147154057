import Lottie from 'lottie-react';

const ModalLoader = ({ visible }:{ visible?:boolean }) => (
    <div className={`modalParentContainer${visible ? " animate__animated animate__fadeIn" : " d-none" }`} style={{zIndex:"99999999999", left:0}}>
        <div className="modalContainer">
            <div className="loadingView">
                <Lottie 
                    animationData={require('../../assets/anim/modal.json')} 
                    loop={true} 
                    autoPlay
                    style={{
                        width: '100px',
                        height: '100px'
                    }}
                />
            </div>
        </div>
    </div>
)

export default ModalLoader;