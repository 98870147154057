import { SearchInputSvg } from "../styles/IconStyle";

const SearchBarButton = ({ searchBarStyle, customInputText }:{
    searchBarStyle?: object | {},
    customInputText?:object | {},
}) => {
    
    return (
        // SearchBar

        <div className="searchBarComp" style={searchBarStyle}>
            <div style={{display:'flex'}}>
                <SearchInputSvg />
            </div>
            <div className="inputText" style={{backgroundColor:'transparent', ...customInputText}}>Search "artist, event"</div>
        </div>
    )
}

export default SearchBarButton;