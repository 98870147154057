import { generalObj } from '../constant/objects_types';
import '../styles/style.css';

type msgObj = {
    [key:string]: any
}

type InputFieldObj = {
    ref?:(evt:any) => void,
    id:string,
    label?:string | null,
    labelStyle?:object | {},
    customInputField?:object | {},
    customClassInputText?:string,
    customInputText?:object | {},
    customInputTextCont?:object | {},
    multiline?: boolean | false,
    inputProp: msgObj,
    refInput?: (input:any) => void,
    isIcon?: any,
    iconPress?: () => void,
    isError?:msgObj,
    message?:msgObj,
    onTextChange: (event:any) => void,
    submitEditing ?: (evt:generalObj) => void
}

const InputField = ({ref, id, label, labelStyle, customInputField, customClassInputText, customInputText, customInputTextCont, multiline, inputProp, refInput, isIcon, iconPress, isError, message, onTextChange, submitEditing }:InputFieldObj) => (
    <div className="inputContainer" style={customInputField}>
        {label && <label className="inputLabel" htmlFor={id} style={labelStyle}>{label}</label>}
        <div className="inputTextCont" style={customInputTextCont}>
            {!multiline ? (
                <input id={id} className={`${customClassInputText} ${isError ? "errorBorder" : ""}`} ref={refInput} {...inputProp} style={customInputText}  onChange={(event) => onTextChange(event.target.value)} onKeyUp={submitEditing} />
            ): (
                <textarea id={id} className={`${customClassInputText} ${isError ? "errorBorder" : ""}`} ref={refInput} {...inputProp} style={customInputText} onChange={(event) => onTextChange(event.target.value)} />
            )}

            {isIcon && (
                <div className='inputIconCont'>
                    <button onClick={iconPress}>
                        <span>{isIcon}</span>
                    </button>
                </div>
            )}
        </div>
        {/* message and character counter */}
        <div style={{marginTop:'5px', justifyContent: !inputProp.maxLength ? "flex-end":"space-between", display: inputProp.maxLength || message ? 'flex':'none'}}>
            {inputProp.maxLength && (
                <div style={{fontWeight:'400', lineHeight: '19px', color: 'rgba(255, 255, 255, 0.3)', alignSelf:'flex-start'}}>
                    <span style={{color:"#9E9B9B", fontSize:"12px"}}>{inputProp.value?.length ?? "0"}/{inputProp.maxLength}</span>
                </div>
            )}
            {message && (
                message.addComponent ? (
                    message.show && (<>
                        {message.text}
                    </>)
                ): (
                    <div>
                        <span style={{fontWeight:'400', lineHeight: '19px', fontSize:"12px", ...message.style}}>
                            { message.text }
                        </span>
                    </div>
                )
            )}
        </div>
    </div>
);

export default InputField;