import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { generalObj } from "../constant/objects_types";

const ProfileGenerator = ({ title, constStyle = {}, textStyle = {}, constClass, useAccount = false, useImage=null}:{
    title?: string | null | undefined;
    constStyle?: object;
    textStyle?: object;
    constClass?: string;
    useAccount?: boolean;
    useImage?: string | null;

}) => {
    const [accountUser, setAccountUser] = useState<generalObj | null>(null);
    const account = useSelector((state:RootState) => state.account);


    useEffect(() => {
        if (!account) return;
        if (account.email) {
            setAccountUser(account);
        }else {
            setAccountUser({
                email: 'Anonymous@ticki.co'
            })
        }
    }, [account]);

    return (
        <>
        { accountUser && useAccount ? (
            <div>
                {accountUser.profileImage ? (
                    <div className="profilePicture profilePicBackground" style={constStyle}>
                        <img src={accountUser.profileImage} alt="profile" className="profilePicture" style={constStyle} />
                    </div>
                ): (
                    <div className="profilePictureUser profilePicBackground isText" style={constStyle}>
                        <span className="sectionTitle" style={textStyle}>{accountUser.email[0].toUpperCase()}</span>
                    </div>
                )}
            </div>
        ): (
            useImage ? (
                <div className={`profilePictureUser profilePicBackground ${constClass}`} style={constStyle}>
                    <img src={useImage} alt="profile" className="profilePictureUser" style={constStyle} />
                </div>
            ): (

                <div className="profilePictureUser profilePicBackground isText" style={constStyle}>
                    <span className="sectionTitle" style={textStyle}>{title}</span>
                </div>
            )
        )}
        </>
    )
}

export default ProfileGenerator;