import { useState } from "react";
import InputField from "../InputField";
import ButtonFrame from "../ButtonFrame";
import { generalObj } from "../../constant/objects_types";
import { ticketDiscountCode, validateEmail } from "../../action/generalFunc";
import { useParams } from "react-router-dom";

const ApplyDiscountModal = ({ visible, isLoading, account, modalClose, onComplete }:{ 
    visible:boolean,
    modalClose: () => void,  
    isLoading: (val:boolean) => void,
    account: generalObj | undefined,
    onComplete: (val:generalObj, email:string) => void
}) => {
    const [formData, setFormData] = useState<generalObj>({
        email: "",
        code: ""
    });
    const [formError, setFormError] = useState<generalObj>({});
    let { id } = useParams();
    
    const checkAccount = () => {
        if (!account || !account.isAuthenticated) {
            return validateEmail(formData.email);
        }else {
            return true
        }
    }

    const onSubmit = async () => {
        if (formData.code.length < 3) {
            setFormError({...formError, code:true, email:false});
            return;
        };

        if (!checkAccount()) {
            setFormError({...formError, code:false, email:true});
            return;
        };

        setFormError({});
        isLoading(true);
        const payload:generalObj = {
            code: formData.code,
            email: (account && account.isAuthenticated) ? account.email : formData.email,
            event_id: id,
        }
        let result:generalObj =  await ticketDiscountCode(payload);
        if (result.status) {
            onComplete(result.data.data, payload.email);
            alert(result.data.message);
        }else {
            alert(result.message);
        }
        isLoading(false);
    }

    

    return (
        <>
            <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`}}>
                <div className="modalContainer" style={{overflow:'hidden'}}>
                
                    <div className={`actionView${!visible ? " animate__animated animate__slideInRight d-none" : " animate__animated animate__slideInRight" }`} style={{height:"auto", width:"calc(100% - 80px)", borderRadius:'14px', padding:'22px 20px'}}>
                        <div className={"rateHeader"}>Discount Code</div>
                        <div  className={"rateDesc"} style={{marginBottom:"16px"}}>Enter your information to apply a discount code</div>
                        {(!account || !account.isAuthenticated) && <InputField id="Email" inputProp={{value:formData.email, placeholder:"Email address"}} isError={formError.email} onTextChange={(val)=>setFormData({...formData, "email": val})}  customInputText={{backgroundColor:'rgba(255, 255, 255, 0.12)'}} message={formError.email && {text:"Invalid email address", style: {}}} />}
                        <InputField id="Code" inputProp={{value:formData.code, placeholder:"Discount code"}} isError={formError.code} onTextChange={(val)=>setFormData({...formData, "code": val})}  customInputText={{backgroundColor:'rgba(255, 255, 255, 0.12)'}} message={formError.code && {text:"Code must have atleast 3 characters", style: {}}} />
                        <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:"16px", gap:'5px'}}>
                            <ButtonFrame 
                                title="Cancel" contStyle={{marginTop:"0px", backgroundColor:"rgba(255, 255, 255, 0.16)", border:"none"}} onPress={modalClose}
                            />
                            {formData.code.length >= 3 && checkAccount() ? (
                                <>
                                    <ButtonFrame title="Submit" contStyle={{marginTop: 0, backgroundColor:'#DB012E', borderWidth:0}} onPress={() => onSubmit()} />
                                </>
                            ):(
                                <ButtonFrame title="Submit" contStyle={{marginTop:0, backgroundColor:"#DB012E", border:"none", opacity:"0.6"}} onPress={() => null} /> 
                            )}
                        </div>
                    </div>
                
                </div>
            </div>
        </>
    )
}

export default ApplyDiscountModal;