export const APP_LOCAL_DEBUG = false;
export const APP_ENV_DEBUG = false;
export const PAYSTACK_SECRET_KEY = APP_ENV_DEBUG ? process.env.REACT_APP_PAYSTACK_TEST_SECRET_KEY : process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;
export const PAYSTACK_PUBLIC_KEY = APP_ENV_DEBUG ? process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY : process.env.REACT_APP_PAYSTACK_LIVE_PUBLIC_KEY;
export const API_URL = APP_LOCAL_DEBUG ? "http://localhost:8888/ticki_backend/public/api/user/" : APP_ENV_DEBUG ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_LIVE_URL;
export const RESALE_URL = APP_ENV_DEBUG ? "https://jamesmaduka.com/tickiresell/api/" : "https://tickiresell-28665f02e081.herokuapp.com/api/"; //;"http://127.0.0.1:8000/api/"
export const BASE_URL = APP_ENV_DEBUG ? "https://ticki.netlify.app" : "https://www.ticki.co";
export const QR_URL = APP_ENV_DEBUG ? "https://ticki.netlify.app/tickets/7987" : "https://ticki.co/tickets/7987";
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_API_GOOGLE_MAP;
export const validColor = "#029522";
export const invalidColor = "#F00D35";
export const radarAPIKEY = "prj_test_pk_53b65d897a97a00ab3aba105c6c4ca0eede17b03";
export const SERVICE_FEE = 2;
export const PROCESSING_FEE = 2;
export const GOOGLECLIENTID = "834978895047-q1hk7coqpgihvc728mhs5mtb9ei8fgov.apps.googleusercontent.com";
export const SYMBOL = "₦";