import { useState, useEffect, useRef, useCallback } from 'react';
import BackArrow from '../BackArrow';
import BorderDivider from '../BorderDivider';
import { resetPasswordRequest } from '../../action/generalFunc';
import ModalLoader from '../modal/ModalLoader';

const ForgotDetail = ({ visible, email, modalClose }:{
    visible:boolean,
    email:string,
    modalClose: () => void,
}) => {
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [resend, setResend] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const resendRef = useRef<any>(null);
    const [timer, setTimer] = useState<string>('00:00');

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const getTimeRemaining = (e:Date) => {
        const new_date = new Date();
        const total = Date.parse(e.toISOString()) - Date.parse(new_date.toISOString());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }

    const startTimer = useCallback((e:Date) => {
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }else {
            clearInterval(resendRef.current);
            setResend(true);
        }
    }, []);

    const clearTimer = useCallback((e:Date) => {
        setTimer('06:00');
        if (resendRef.current) clearInterval(resendRef.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        resendRef.current = id;
    }, [startTimer]);

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 360);
        return deadline;
    }
    
    useEffect(() => {
        if (resend) return
        clearTimer(getDeadTime());
    }, [resend, clearTimer]);

    const submitPasswordResend = async () => {
        if (!resend) return;
        setModalLoading(true);
        const result = await resetPasswordRequest(email);
        if (result) {
            setResend(false);
        }
        setModalLoading(false);
    }

    return (
        <>
            {firstShow && (
                <>
                    <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <BackArrow onPress={modalClose} />

                                <h3 className="fp_header">Reset Password</h3>
                                <p className="fp_desc">Click the reset password link sent to {email} to reset your password.</p>
                                
                                <BorderDivider borderStyle={{marginBlock: '40px'}} />

                                <div style={{textAlign:'center'}}>
                                    <span className='nomText'>Didn’t get link?</span>
                                    <button onClick={submitPasswordResend}>
                                        <span className='fp_forgotText' style={!resend ? {color:'rgba(255, 255, 255, 0.24)'}:{}}>Resend Link</span>
                                    </button>
                                </div>

                                {!resend && <div className='nomText' style={{marginTop:"8px"}}>Please wait <span style={{color:"#F00D35"}}>{timer}</span> before resending another link.</div>}
                                <ModalLoader visible={modalLoading} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default ForgotDetail;