import { useState } from "react";
import { copyToClipboard } from "../../action/generalFunc";
import { generalObj } from "../../constant/objects_types";
import { ContactEmailSvg, ContactPhoneSvg, CopyLinkSmallSvg } from "../../styles/IconStyle";
import PopAlert from "./PopAlert";

const ContactInfoModal = ({ visible=false, contact, modalClose }:{
    visible?:boolean,
    contact:generalObj,
    modalClose: () => void,
}) => {

    const [openPop, setOpenPop] = useState<boolean>(false);
    
    const copyInfo = async (data:string) => {
        await copyToClipboard(data);
        setOpenPop(true);
        modalClose();
    }

    return (
        <>
            <div className={`modalParentContainer${!visible ? " d-none" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"rgba(0, 0, 0, 0.70)", zIndex:"9999"}}>
                <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                    <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                    <div className="actionView animate__animated animate__slideInUp" style={{height:"auto"}}>
                        <div style={{margin: "20px 10px 22px 10px", position: "relative", height: "100%"}}>
                            <div className="actionItemCont shareItemCont contact" style={{position:'relative', height:'auto', flexDirection:"column"}}>
                                <div style={{display:"flex", alignItems:"center", gap:"10px"}} onClick={() => copyInfo(contact.email)}>
                                    <span style={{display:"inline-flex", width:"28px", height:"28px"}}>
                                        <ContactEmailSvg />
                                    </span>
                                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                                        <span className="postOwnerTextl" style={{margin:0, fontSize:"14px"}}>{contact.email}</span>
                                        <span style={{display:"inline-flex", width:"20px", height:"20px"}}>
                                            <CopyLinkSmallSvg color={"#FFF"} />
                                        </span>
                                    </div>
                                </div>
                                <div style={{display:"flex", alignItems:"center", gap:"10px"}} onClick={() => copyInfo(contact.phone.replace("-", ""))}>
                                    <span style={{display:"inline-flex", width:"28px", height:"28px"}}>
                                        <ContactPhoneSvg />
                                    </span>
                                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                                        <span className="postOwnerTextl" style={{margin:0, fontSize:"14px"}}>{contact.phone}</span>
                                        <span style={{display:"inline-flex", width:"20px", height:"20px"}}>
                                            <CopyLinkSmallSvg color={"#FFF"} />
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openPop && <PopAlert visible={openPop} description="Copied!" modalClose={() => setOpenPop(false)} /> }
        </>
    )
}

export default ContactInfoModal;