import {useEffect, useRef, useState } from 'react';
import { generalObj } from '../../constant/objects_types';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { getDateStr, getTimeStr, calculateSellingPrice, getBlobUri } from '../../action/generalFunc';
import { DateIconSvg, TimeIconSvg, RecurringTagSmallSvg, PriceTagSmallSvg, LocationSvg } from '../../styles/IconStyle';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import HeroDetails from '../home/HeroDetails';
import BorderDivider from '../BorderDivider';
import EventRating from '../home/EventRating';
import { SYMBOL } from '../../constant/constant';

const PreviewEvent = ({ visible, itemObj, modalClose, eventSubmit}: {
    visible: boolean,
    itemObj: generalObj,
    modalClose: () => void,
    eventSubmit: () => void
}) => {
    const bodyRef = useRef<HTMLDivElement | null>(null);
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [seeMore, setSeeMore] = useState<boolean>(false)
    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const getBasePrice = () => {
        if (itemObj.reviewEventT.eventHasTicket) {
            if (itemObj.reviewEventT.ticketCategory) {
                let total_price:Array<number> = [];
                itemObj.reviewEventT.categories.forEach((element:generalObj) => {
                    if (!element.isDeleted) {
                        total_price.push(element.price);
                    }
                });
                let min_price = Math.min(...total_price);
                if (min_price > 0)  {
                    return `${SYMBOL} ${itemObj.reviewEventT.UsersIncurCharge ? calculateSellingPrice(min_price) : min_price.toLocaleString()}`
                }else {
                    return "Free";
                }
            }else {
                if (itemObj.reviewEventT.noCategory.price > 0)  {
                    return `${SYMBOL} ${itemObj.reviewEventT.UsersIncurCharge ? calculateSellingPrice(parseFloat(itemObj.reviewEventT.noCategory.price)) : parseFloat(itemObj.reviewEventT.noCategory.price).toLocaleString()}`
                }else {
                    return "Free";
                }
            }
        }else {
            return "Free";
        }
    }

    return (

        <>
            {firstShow && (
                <>
                    <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 0px", width:'100%', height:'100%'}}>
                                <div style={{width:'100%', height: "calc(100% - 10px)", overflowX: "hidden"}}>
                                    <HeroDetails heroObj={{
                                        name: itemObj.reviewEvent.name,
                                        user: {
                                            "id": account?.id,
                                            "fullname": account?.fullname,
                                            "username": account?.username,
                                            "profile_picture": account?.profileImage
                                        },
                                        cover_image: itemObj.reviewEventP.coverImage ? getBlobUri(itemObj.reviewEventP.coverImage) : itemObj.reviewEventP.coverImageUrl,
                                        promotion_video: itemObj.reviewEventP.promotionVideo ? getBlobUri(itemObj.reviewEventP.promotionVideo) : null,
                                        event:{
                                            "end_date": itemObj.reviewEvent.date.oneTime ? itemObj.reviewEvent.oneTime.endDate : itemObj.reviewEvent.recurring.endDate
                                        }
                                    }} parentWidth={bodyRef.current?.clientWidth} isPreview={true} modalClose={() => modalClose()} SubmitEvent={() => eventSubmit()} />

                                    <div className="listContainer">
                                        <div style={{width:"100%"}} ref={bodyRef}>
                                            {/* Event details */}
                                            <div style={{margin:"24px 0px 0px 0px"}}>
                                                {/* Date time display */}
                                                {itemObj.reviewEvent.date.oneTime ? (
                                                    <div className={"postOwner"}>
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                            <DateIconSvg />
                                                            <span className='postOwnerTextl' style={{fontSize:14}}>Date: {getDateStr(`${itemObj.reviewEvent.oneTime.startDate}`)}</span>
                                                        </div>
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                            <TimeIconSvg />
                                                            <span className='postOwnerTextl' style={{fontSize:14}}>Time: {getTimeStr(`${itemObj.reviewEvent.oneTime.startDate}`)}</span>
                                                        </div>
                                                    </div>
                                                ):(
                                                    <>
                                                        <div className={"postOwner"}>
                                                            <div style={{display:'flex', alignItems:'center'}}>
                                                                <DateIconSvg />
                                                                <span className='postOwnerTextl' style={{fontSize:14}}>Date: {getDateStr(`${itemObj.reviewEvent.recurring.startDate}`)}</span>
                                                            </div>
                                                            <div style={{display:'flex', alignItems:'center'}}>
                                                                <TimeIconSvg />
                                                                <span className='postOwnerTextl' style={{fontSize:14}}>Time: {getTimeStr(`${itemObj.reviewEvent.recurring.startDate}`)}{itemObj.reviewEvent.recurring.withEndDate && ` - ${getTimeStr(`${itemObj.reviewEvent.recurring.endDate}`)}`}</span>
                                                            </div>
                                                        </div>
                                                        <div className='postOwner' style={{marginTop:"16px", display:'flex', alignItems:'center'}}>
                                                            <RecurringTagSmallSvg />
                                                            <span className='postOwnerTextl' style={{fontSize:14}}>Recurring: {itemObj.reviewEvent.recurring.occurence.label}</span>
                                                        </div>
                                                    </>
                                                )}
                                                
                                                <div className='postOwner' style={{marginTop:"16px", display:'flex', alignItems:'center'}}>
                                                    <PriceTagSmallSvg />
                                                    <span className='postOwnerTextl' style={{fontSize:14}}>Price: {getBasePrice()}</span>
                                                </div>

                                                {/* Location display */}
                                                <div className='postOwner' style={{marginTop:"16px", display:'flex', alignItems:'center'}}>
                                                    <span style={{display:"inline-flex"}}><LocationSvg /></span>
                                                    <span className='postOwnerTextl' style={{fontSize:"14px"}}>Location: {itemObj.reviewEvent.location.online ? "Online" : itemObj.reviewEvent.venue_location.addressLabel}.</span>
                                                </div>


                                                {/* Description */}
                                                <div style={{marginTop:"16px"}}>
                                                    <span className='inputLabel'>Description</span>
                                                    {itemObj.reviewEvent.description.length <= 100? (
                                                        <p className="itemOwner" style={{marginTop:"5px", fontSize:"14px", lineHeight:"16px", fontWeight:'400'}}>
                                                            {itemObj.reviewEvent.description}
                                                        </p>
                                                    ): (
                                                        <>
                                                            <p className='itemOwner' style={{marginTop:"5px", fontSize:"14px", lineHeight:"16px", fontFamily:"400"}}>
                                                                {!seeMore ? `${itemObj.reviewEvent.description.slice(0, 100)}...` : itemObj.reviewEvent.description}
                                                            </p>
                                                            <button className="postOwnerTextl" style={{marginTop:"5px", display:'flex', alignSelf:'center', fontSize:"14px", color:'rgba(225, 225, 225, 0.6)'}} onClick={()=>setSeeMore(!seeMore)}>
                                                                <span>{!seeMore?"Show more":"Show less"}</span>
                                                            </button>
                                                        </>
                                                    )}
                                                </div>

                                                {/* Action Button */}
                                                <div style={{marginTop:"32px", display:'flex', gap:"10px", justifyContent:'space-between'}}>
                                                    <button className='postOwner buttonFrameCont heroBtn geryBtn'>
                                                        <span className='buttonFrameText'>Share Event</span>
                                                    </button>

                                                    <button  className='postOwner buttonFrameCont heroBtn'>
                                                        <span className='buttonFrameText'>Buy Ticket</span>
                                                    </button>
                                                
                                                </div>
                                                {/* Ratings */}
                                                <BorderDivider borderStyle={{marginVertical: 32}} />
                                                <EventRating />

                                                <BorderDivider borderStyle={{marginVertical: "32px"}} />

                                                {/* Comments */}
                                                <div>
                                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                                        <div className='sectionTitle'>Comments</div>
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                            <span className='inputLabel' style={{fontSize:"14px", lineHeight:"24px"}}>{0}</span>
                                                            <div style={{marginLeft:"5px", display:'flex', flexDirection:'column'}}>
                                                                <IoChevronUpOutline size={"10px"} color='#FFF' />
                                                                <IoChevronDownOutline size={"10px"} color='#FFF' />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{backgroundColor:'rgba(66, 66, 66, 0.25)', marginTop:"26px", borderRadius:"12px"}}>
                                                        <div style={{margin:"0px 16px", padding:"16px 0px", display:'flex', justifyContent:'center'}}>
                                                            <p className='postOwnerText' style={{color:'rgba(255, 255, 255, 0.6)'}}>Tap to add comment</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )} 
        </>
    )
}

export default PreviewEvent;