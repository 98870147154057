import SearchBar from "../SearchBar";
import { useState, useEffect } from "react";
import { generalObj } from "../../constant/objects_types";

const LocationModal = ({ visible=false, locationData, modalClose, newLocation }:{
    visible?:boolean,
    locationData:any,
    modalClose: () => void,
    newLocation: (val:string) => void
}) => {

    const [regions, setRegions] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        if (!locationData) return;
        setRegions(locationData);
    }, [locationData]);

    const locStateSearch = (val:string) => {
        setSearchText(val);
        setRegions((prevRegions:generalObj[]) => {
            return (
                prevRegions.filter((rItem:generalObj) => {
                    if (rItem.name.toLowerCase().includes(val)) {
                        rItem.show = true;
                    }else {
                        rItem.show = false
                    }
                    return rItem;
                })
            )
        })
    }

    const onModalClose = () => {
        setSearchText('');
        //setRegions([]);
        modalClose();
    }

    const onSelectState = (data:string) => {
        setSearchText('');
        newLocation(data);
        //setRegions([]);
    }

    return (
        <div className={`modalParentContainer${!visible ? " d-none" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:"9999"}}>
            <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={onModalClose}></div>
                <div className="actionView animate__animated animate__slideInUp">
                    <div style={{margin: "20px 20px 0 20px", position: "relative", height: "100%"}}>
                        {/* Search bar */}
                        <SearchBar inputProp={{ value:searchText, placeholder:"Search states, territories, districts" }} customInputText={{backgroundColor:'transparent'}} onTextChange={(val:string) => locStateSearch(val)} onKeyPressed={(val:any) => console.log(val)} />
                        <div className="actionItemCont">
                            {regions && (
                                regions.map((item:generalObj, index:number) => (
                                    <button className={`actionItem ${item.show === false ? "d-none" : ""}`} key={index} onClick={() => onSelectState(item.name)}>
                                        <span>{ item.name }</span>
                                    </button>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LocationModal;