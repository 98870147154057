import { useState, useEffect } from "react"
import BackArrow from "../BackArrow";
const WhatWeDo = ({visible, modalClose}:{
    visible:boolean,
    modalClose:() => void
}) => {
    const [firstShow, setFirstShow] = useState<boolean>(false);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <>
                    <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                        <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>What we do</div>
                                    </div>
                                </div>
                                
                                <div style={{width:'100%', marginTop:"24px", height:"100%"}}>
                                    <div className="wwd" style={{height:"calc(100% - 100px)", paddingBottom:'90px', overflowX:'hidden'}}>
                                        <h3 className="header">Making it <span>easier</span> for you to attend your <span>favourite events</span>.</h3>
                                        <p style={{marginTop:"24px"}}>Ticki Global Services is an online ticketing service and application  incorporated and stationed in FCT,Nigeria and Lagos,Nigeria.Ticki Global Services  is a dynamic organization specializing in the sales, distribution and creation of tickets online. Ticki Global Services allows you to post videos and pictures of the events you attend and also allows you to give reviews of your experience.</p>
                                        <p style={{marginTop:"24px"}}>Ticki creates a platform for event managers to post  events their planning as well as other events they have planned. Allows upcoming artist, comedians and even mc’s to post performances of their shows linking all these events together like a general hub.</p>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default WhatWeDo;