import { useCallback, useEffect, useState } from "react";
import { IoEllipseSharp } from "react-icons/io5";
import ProfileGenerator from "../ProfileGenerator";
import { TicketSmallSvg } from "../../styles/IconStyle";
import { generalObj } from "../../constant/objects_types";
import { createResale, getBlobUri, processResellData } from "../../action/generalFunc";
import ButtonFrame from "../ButtonFrame";
import ModalLoader from "../modal/ModalLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { monthsCode } from "../../constant/shortcodes";
import { SYMBOL } from "../../constant/constant";
import ResaleComplete from "./ResaleComplete";
import { useParams } from "react-router-dom";

const ReviewResell = ({ visible, eventObj, isEditting, modalClose, parentRef }:{
    visible:boolean,
    eventObj:generalObj[],
    isEditting: boolean,
    modalClose:() => void,
    parentRef: generalObj
}) => {
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [reviewResell, setReviewResell] = useState<generalObj>({});
    const [reviewResellP, setReviewResellP] = useState<generalObj>({});
    const [reviewResellT, setReviewResellT] = useState<generalObj>({});

    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [completeResell, setCompleteResell] = useState<boolean>(false);

    const account = useSelector((state:RootState) => state.account);
    const { ticketId } = useParams();

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!eventObj || !eventObj.length) return;
        setReviewResell({...eventObj[0].fields});
        setReviewResellP({...eventObj[1].fields});
        setReviewResellT({...eventObj[2].fields});
    }, [eventObj])

    const getDate = useCallback((dateObj:string)  => {
        const new_date = new Date(dateObj);
        let date = `${new_date.getDate()} ${monthsCode[new_date.getMonth()]} ${new_date.getFullYear()}`;
        return date;
    }, []);


    const getTime = useCallback((timeObj:string)  => {
        const new_time = new Date(timeObj);
        
        let hour = '';
        let nformat = '';
        if (new_time.getHours() > 12) {
            hour = `${new_time.getHours() - 12 < 10 ?`0${new_time.getHours() - 12}`:new_time.getHours() - 12}`;
            nformat = "PM"
        }else {
            if (new_time.getHours() < 10) {
                hour = `0${new_time.getHours()}`;
            }else {
                hour = `${new_time.getHours()}`
            }
            nformat = "AM";
        }
        let time = `${hour} : ${new_time.getMinutes() < 10? `0${new_time.getMinutes()}`: new_time.getMinutes()} ${nformat}`;
        return time;
    }, []);

    const submitEventCreation = async () => {
        if (!account || !account.isAuthenticated) return;
        setModalLoading(true);
        const process_data:generalObj = await processResellData(reviewResell, reviewResellP, reviewResellT, account.email, account.id);
        if (!process_data.error) {
            if (isEditting && ticketId) {
                process_data.data.ticket_id = ticketId
            }
            const createSale = isEditting ? await  createResale(process_data.data, "PUT"): await createResale(process_data.data, "POST");
            
            if (createSale && !createSale.error) {
                setCompleteResell(true);
            }else {
                alert(createSale?.message);
            }
        }else {
            alert(process_data.message);
        }
        setModalLoading(false);
    }

    return (
        <>
            {firstShow && (
                <>
                    <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%', position:'relative'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"20px", marginBottom:"20px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Ticket Summary</div>
                                    </div>
                                </div>
                                <div style={{width:'100%', height: "calc(100% - 80px)", overflowX: "hidden"}}>
                                    <div className="listContainer" style={{paddingBottom:"120px", margin:0}}>
                                        <div style={{width:'100%'}}>
                                            <div className="review_asset__container">
                                                <div className="review_asset__item" id={`review_asset__1`}>
                                                    {reviewResellP && (reviewResellP.coverImage || reviewResellP.coverImageUrl) && (
                                                        <div className="displayImageContainer" id="imgID" style={{width:"100%", height:`${(parentRef.clientWidth) / (4/3)}`}}>
                                                            <img src={reviewResellP.coverImage ? getBlobUri(reviewResellP.coverImage) : reviewResellP.coverImageUrl} className="displayImage" style={{objectFit:'cover'}} alt="cover"  />
                                                        </div> 
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="sectionDisplay" style={{marginTop: "10px"}}>
                                                    <div style={{display:'flex', alignItems:'center', gap:"5px"}}>
                                                        <ProfileGenerator constClass="profilePictureUser" constStyle={{width:"24px", height:"24px", borderRadius:"24px"}} textStyle={{fontSize:"13px", lineHeight:"17px"}} useAccount={true} />
                                                        <div className="itemOwner">{account && account.username}</div>
                                                        <div>
                                                            <TicketSmallSvg />
                                                        </div>
                                                    </div>
                                                </div>

                                                {reviewResell && reviewResell.name && (
                                                    <div className="sectionDisplay" style={{marginTop:0}}>
                                                        <div>
                                                            
                                                            <div className="itemOwner" style={{marginTop:"10px", fontSize:"16px", lineHeight:"16px"}}>{reviewResell.name}</div>
                                                            <div className="itemOwner" style={{marginTop:"8px", fontSize:"16px"}}>{SYMBOL} {reviewResell.price}</div>
                                                            <div style={{display:'flex', marginTop:"8px"}}>
                                                                <div className="itemOwner bodyText" style={{lineHeight:"13px", color:'#9E9B9B'}}>{getDate(reviewResell.oneTime.startDate)}</div>
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                    <IoEllipseSharp size={"4px"} color="#9E9B9B" style={{marginInline:"5px"}} />
                                                                </div>
                                                                <div className="itemOwner bodyText" style={{lineHeight:"13px", color:'#9E9B9B'}}>{getTime(reviewResell.oneTime.startDate)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:"100%",position:'absolute',bottom:"0px", backgroundColor:"#0D0404", paddingBottom: "25px"}}>
                                    <div style={{width: "100%", margin:"0px auto"}}>
                                    <ButtonFrame title={isEditting ? "Update Resell" : "Create Resell"} contStyle={{backgroundColor:'#DB012E', borderWidth:0}} onPress={submitEventCreation} />
                                    <ButtonFrame title="Go back" contStyle={{marginTop: 12, backgroundColor:'#4F4F4F', borderWidth:0}} onPress={() => modalClose()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <ModalLoader visible={modalLoading} />
            <ResaleComplete visible={completeResell} isEditting={isEditting} />
        </>
    )
}

export default ReviewResell;