import dayjs from 'dayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { MultiSectionDigitalClock, StaticDateTimePicker } from '@mui/x-date-pickers';
// import { TimeField } from '@mui/x-date-pickers/TimeField';
import { useState, useEffect } from 'react';

const DateTimePickerModal = ({ visible, date, mode, minimumDate=null, maximumDate=null, pickerStyle, modalClose, onChange }:{ 
    visible:boolean, 
    date: Date,
    mode: string,
    minimumDate?: Date | null,
    maximumDate?: Date | null,
    pickerStyle ?: Object,
    modalClose: () => void,
    onChange: (val: Date | null) => void,
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`, left:"0px"}}>
                    <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                        <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                        <div className={`actionView${!visible ? " animate__animated animate__slideOutDown" : " animate__animated animate__slideInUp" }`} style={{height:"66%", ...pickerStyle}}>
                            <div style={{margin: "20px 20px 0 20px", position: "relative", height: "100%"}}>
                                <div style={{textAlign:'end'}}>
                                    <button onClick={modalClose}>
                                        <span className='sectionTopLink'>Done</span>
                                    </button>
                                </div>
                                <div className="actionItemCont datePicker">
                                    {mode === 'date' ? (
                                        <StaticDatePicker defaultValue={dayjs(date)} maxDate={dayjs(maximumDate)} minDate={dayjs(minimumDate)} onChange={(val) => onChange(val ? new Date(val.toISOString()) : null)} />
                                    ): (
                                        mode === 'time' ? (
                                            // <StaticTimePicker defaultValue={dayjs(date)} maxTime={dayjs(maximumDate)} minTime={dayjs(minimumDate)} onChange={(val) => onChange(val ? new Date(val.toISOString()) : null)} />
                                            <MultiSectionDigitalClock value={dayjs(date)} views={['hours', 'minutes', 'seconds']} onChange={(val) => onChange(val ? new Date(val.toISOString()) : null)} />
                                            //<TimeField value={dayjs(date)} format="HH:mm:ss" onChange={(val) => onChange(val ? new Date(val.toISOString()) : null)} />
                                            // <TimeField value={dayjs(date)} format="HH:mm" ampm={true} onChange={(val) => console.log(val)} />
                                        ): (
                                            <StaticDateTimePicker defaultValue={dayjs(date)} maxDateTime={dayjs(maximumDate)} minDateTime={dayjs(minimumDate)} onChange={(val) => onChange(val ? new Date(val.toISOString()) : null)} />
                                        )
                                    )}    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )

}

export default DateTimePickerModal;