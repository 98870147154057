import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducerCombiner from './reducers/index';

const saveToStorage = (state:object) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    }catch (e) {
        localStorage.setItem('state', "");
    }
}

const loadFromStorage = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null || serializedState === "") {
            return undefined;
        }
        return JSON.parse(serializedState);
    }catch (e) {
        return undefined;
    }
}

const persistedState = loadFromStorage();

export const Store = createStore(
    reducerCombiner,
    persistedState,
    applyMiddleware(thunk)
)


Store.subscribe(() => saveToStorage(Store.getState()));