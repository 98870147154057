import { IoCloseOutline } from "react-icons/io5";
import { BackArrow, ButtonFrame, ForgotDetail, InputField, ModalLoader } from "../../components";
import { useRef, useState, useEffect } from "react";
import { resetPasswordRequest, validateEmail } from "../../action/generalFunc";

const ForgotPassword = () => {
    const emailRef = useRef<HTMLInputElement | null>(null);
    const [email, setEmail] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<boolean>(false);

    useEffect(() => {
        if (email.length < 5) {
            setIsValid(false);
            return
        };
        const checkEmail = validateEmail(email);
        setIsValid(checkEmail);
    }, [email]);

    const submitPasswordResend = async () => {
        if (isValid) {
            setModalLoading(true);
            const result = await resetPasswordRequest(email);
            if (result) {
                setShowDetail(true); 
            }
            setModalLoading(false);
        }
    }

    return (
        <>
            <div className='authContainer' style={{justifyContent:'flex-start'}}>
                <BackArrow onPress={() => window.history.back()} />

                <h3 className="fp_header">Forgot Password?</h3>
                <p className="fp_desc">Don’t worry we got you! Enter the email used to create your account to get a link to reset your password.</p>
                <InputField id={"email"} refInput={(input) => emailRef.current = input} customInputField={{marginTop:"15px"}} customInputText={{borderBottom: "1px solid rgba(255, 255, 255, 0.1)",backgroundColor: 'transparent',borderRadius: 0,paddingInline:"8px",fontSize: "16px",fontWeight: '400'}} inputProp={{value:email, type:'email', placeholder:'Email Address' }} isIcon={<IoCloseOutline size={"24px"} color="#FFF" />} iconPress={() => setEmail('')} onTextChange={(val) => setEmail(val)} />

                <ButtonFrame title={"Done"} contStyle={{position:'absolute', backgroundColor:'#DB012E', border:"none", bottom:"36px", width: "calc(100% - 40px)", opacity:isValid?"1":"0.6"}} onPress={submitPasswordResend} />

                <ModalLoader visible={modalLoading} />
                <ForgotDetail visible={showDetail} email={email} modalClose={() => setShowDetail(false)} />
            </div>
        </>
    )
}

export default ForgotPassword;