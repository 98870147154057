import { useState } from "react";
import { getDateStr, getTimeStr, getUTCStr, processAddressnMap } from "../../action/generalFunc"
import { SYMBOL } from "../../constant/constant"
import { generalObj } from "../../constant/objects_types"
import { DateIconSvg, LocationSvg, PriceTagSmallSvg, RecurringTagSmallSvg, TimeIconSvg } from "../../styles/IconStyle"
import ProfileGenerator from "../ProfileGenerator";

const EventInfo = ({event, account, eventMinTicket}:{
    event: generalObj,
    account: generalObj,
    eventMinTicket: number
}) => {
    const [seeMore, setSeeMore] = useState<boolean>(false)
    return (
        <>
            {/* Date time display */}
            <div className={"postOwner"} style={{gap:"24px"}}>
                <div style={{display:'flex', alignItems:'center', gap:"6px"}}>
                    <div>
                        <span style={{display:"inline-flex", width:"18px", height:"18px", backgroundColor:"#302929", borderRadius:"50%", padding:"4px", justifyContent:"center", alignItems:"center"}}>
                            <DateIconSvg />
                        </span>
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <span className='postOwnerTextl' style={{fontSize:13, color:"#9E9B9B"}}>Date</span>
                        <span className='postOwnerTextl' style={{fontSize:13}}>{getDateStr(getUTCStr(event.start_date))}</span>
                    </div>
                </div>
                <div style={{display:'flex', alignItems:'center', gap:"6px"}}>
                    <div>
                        <span style={{display:"inline-flex", width:"18px", height:"18px", backgroundColor:"#302929", borderRadius:"50%", padding:"4px", justifyContent:"center", alignItems:"center"}}>
                            <TimeIconSvg />
                        </span>
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <span className='postOwnerTextl' style={{fontSize:13, color:"#9E9B9B"}}>Time</span>
                        <span className='postOwnerTextl' style={{fontSize:13}}>{getTimeStr(getUTCStr(event.start_date))}{event.date_type !== 'one-time' && event.end_type === "end_date" && ` - ${getTimeStr(getUTCStr(event.end_date))}`}</span>
                    </div>
                </div>
            </div>

            {event.date_type !== 'one-time' && (
                <div className='postOwner' style={{marginTop:"16px", display:'flex', alignItems:'center'}}>
                    <div>
                        <span style={{display:"inline-flex", width:"18px", height:"18px", backgroundColor:"#302929", borderRadius:"50%", padding:"4px", justifyContent:"center", alignItems:"center"}}>
                            <RecurringTagSmallSvg />
                        </span>
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <span className='postOwnerTextl' style={{fontSize:13, color:"#9E9B9B"}}>Recurring</span>
                        <span className='postOwnerTextl' style={{fontSize:13}}>{event.occurence}</span>
                    </div>
                </div>
            )}

            <div style={{marginTop:"16px", display:'flex', alignItems:'center', gap:"6px"}}>
                <div>
                    <span style={{display:"inline-flex", width:"18px", height:"18px", backgroundColor:"#302929", borderRadius:"50%", padding:"4px", justifyContent:"center", alignItems:"center"}}>
                        <PriceTagSmallSvg />
                    </span>
                </div>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <span className='postOwnerTextl' style={{fontSize:13, color:"#9E9B9B"}}>Price</span>
                    <span className='postOwnerTextl' style={{fontSize:13}}>{eventMinTicket > 0 ?`${SYMBOL}${parseFloat(`${eventMinTicket}`).toLocaleString()}` :'Free'}</span>
                </div>
            </div>

            {/* Location display */}
            <div className='postOwner' style={{marginTop:"16px", display:'flex', alignItems:'center'}}>
                <div>
                    <span style={{display:"inline-flex", width:"18px", height:"18px", backgroundColor:"#302929", borderRadius:"50%", padding:"4px", justifyContent:"center", alignItems:"center"}}>
                        <LocationSvg />
                    </span>
                </div>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <span className='postOwnerTextl' style={{fontSize:13, color:"#9E9B9B"}}>Location</span>
                    <span className='postOwnerTextl' style={{fontSize:13}}>{event.location_type === "online" ? "Online" : `${processAddressnMap(event.location, "addressLabel")}`}.</span>
                </div>
            </div>

            {/* Permission to scan */}
            {account && account.isAuthenticated && (
                <>
                    {event && event.authorized_scanners_user_data.length > 0 &&(
                        <div style={{marginTop:"16px"}}>
                            <span className='inputLabel' style={{fontSize:"13px"}}>Permission to scan</span>
                            <div style={{display:"flex", alignItems:"center"}}>
                                {event.authorized_scanners_user_data.map((item:generalObj) => (
                                    item && (
                                        <div key={item.id} style={{marginLeft: "-5px", border: "2px solid #000", borderRadius: "50%"}} title={item.fullname}>
                                            {item.profile_picture ? (
                                                <div className="profilePictureUser" style={{backgroundColor:'#c4c4c4'}}>
                                                    <img src={item.profile_picture} className="profilePictureUser" alt="postOwner" />
                                                </div>
                                            ): (
                                                <ProfileGenerator title={item.fullname[0].toUpperCase()} constStyle={{marginBottom:0}} textStyle={{fontSize:"13px", lineHeight:"16px"}} />
                                            )}
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}

            {/* Description */}
            <div style={{marginTop:"16px"}}>
                <span className='inputLabel' style={{fontSize:"13px"}}>Description</span>
                {event && event.description.length <= 100? (
                    <p className="itemOwner" style={{marginTop:"5px", fontSize:"14px", lineHeight:"16px", fontWeight:'400'}}>
                        {event.description}
                    </p>
                ): (
                    <>
                        <p className='itemOwner' style={{marginTop:"10px", fontSize:"14px", lineHeight:"16px", fontFamily:"400", marginLeft:"auto", marginRight:"auto"}}>
                            {!seeMore ? `${event.description.slice(0, 100)}...` : event.description}
                        </p>
                        <button className="postOwnerTextl" style={{marginTop:"5px", display:'flex', alignSelf:'center', fontSize:"14px", color:'rgba(225, 225, 225, 0.6)'}} onClick={()=>setSeeMore(!seeMore)}>
                            <span>{!seeMore?"Show more":"Show less"}</span>
                        </button>
                    </>
                )}
            </div>
        </>
    )
}

export default EventInfo