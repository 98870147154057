import { useEffect, useRef, useState } from 'react';
import { BottomTab, ContactInfoModal, HeroDetails, ModalLoader, ResellTicketInfo } from '../components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UrlType, generalObj } from '../constant/objects_types';
import { useSelector } from 'react-redux';
import { RootState } from '../redux';
import { fetchResaleTicketsById } from '../action/generalFunc';
import { RWebShare } from 'react-web-share';
import { BASE_URL } from '../constant/constant';

const ResellTicketDetail = () => {
    const [modalLoader, setModalLoading] = useState<boolean>(true);
    const [openContact, setOpenContact] = useState<boolean>(false);
    const [contactData, setContactData] = useState<generalObj | null>();
    const [event, setEvent] = useState<generalObj | null>(null);
    const bodyRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const account = useSelector((state:RootState) => state.account);
    let { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id) return;
        (async () => {
            const resold_result:generalObj = await fetchResaleTicketsById(id, account.token);
            if (!resold_result.error) {
                setEvent(resold_result.data);
                setContactData({
                    email: resold_result.data.contact_email,
                    phone: resold_result.data.contact_number
                })
                // const result:generalObj = await fetchUser(account.token, resold_result.data.user_id);
                // if (result.status && result.data.data.user) {
                    
                // }else {
                //     window.history.back();
                // }
            }else {
               window.history.back();
            }
            setModalLoading(false);
        })();
    }, [account, id]);

    return (
        <>
            {event && (
                <div className={`appBody`}>
                    <div className='appCont' ref={bodyRef}>
                        <HeroDetails heroObj={{
                            name: event.ticket_name,
                            user: event.user_obj,
                            event: event,
                            cover_image: event.image_url
                        }} parentWidth={bodyRef.current?.clientWidth} />

                        {/* Event details */}
                        <div style={{margin:"24px 20px 0px 20px"}}>
                            {event && <ResellTicketInfo event={event} />}

                            {/* Action Button */}
                            {event.user_obj && account && event.user_obj.id !== account.id && (
                                <div style={{marginTop:"32px", display:'flex', gap:"10px", justifyContent:'space-between'}}>
                                    <RWebShare data={{
                                        text: `${event.information.slice(0, 100)}...`,
                                        url: `${BASE_URL}${UrlType.RESELL_TICKET_DETAIL}${id}`,
                                        title: event.ticket_name,
                                    }}>
                                        <button className='postOwner buttonFrameCont heroBtn geryBtn' style={{}}>
                                            <span className='buttonFrameText'>Share</span>
                                        </button>
                                    </RWebShare>
                                    
                                    <button  className='postOwner buttonFrameCont heroBtn' onClick={() => setOpenContact(true)}>
                                        <span className='buttonFrameText'>Contact Info</span>
                                    </button>
                                </div>
                            )}
                            
                            {event.user_obj && account && event.user_obj.id === account.id && (
                                <div style={{marginTop:"32px", display:'flex', gap:"8px", justifyContent:'space-between'}}>
                                    <RWebShare data={{
                                        text: `${event.information.slice(0, 100)}...`,
                                        url: `${BASE_URL}${UrlType.RESELL_TICKET_DETAIL}${id}`,
                                        title: event.ticket_name,
                                    }}>
                                        <button className='postOwner buttonFrameCont heroBtn geryBtn' style={{paddingInline:"14px"}}>
                                            <span className='buttonFrameText'>Share</span>
                                        </button>
                                    </RWebShare>
                                    
                                    <button className='postOwner buttonFrameCont heroBtn geryBtn' onClick={() => navigate(`${UrlType.CREATE_RESELL_EVENT_EDIT}${event.id}`)} style={{paddingInline:"14px"}}>
                                        <span className='buttonFrameText'>Edit</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            
            <BottomTab location_path={location.pathname} />
            {contactData && <ContactInfoModal visible={openContact} contact={contactData} modalClose={() => setOpenContact(false)} /> }
            <ModalLoader visible={modalLoader} />

        </>
    )
}

export default ResellTicketDetail;