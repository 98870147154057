import { BackArrow, ButtonFrame, InputField } from "../../components";
import { useState, useRef } from "react";

const HelpCenter = () => {
    const [centerData, setCenterData] = useState({
        subject: "",
        description: ""
    })
    const subjectRef = useRef<HTMLInputElement | null>(null);
    const descRef = useRef<HTMLInputElement | null>(null);

    return (
        <>
            <div style={{height:'100%'}}>
                <div style={{padding:"0px 20px"}}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                        <div style={{display:'flex', alignItems:'center', gap:"3px"}}>
                            <BackArrow onPress={() => window.history.back()} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                            
                            <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Help Center</div>
                        </div>
                    </div>

                    <div style={{width:'100%', marginTop:"24px"}}>
                        <div className="inputLabel" style={{fontSize:"14px", marginTop:"8px", lineHeight:"19px", color:'#9E9B9B'}}>Need help? Let us know what the problem is and we’ll solve it for you.</div>
                        
                        <div style={{marginTop:"14px"}}>
                            <InputField id="subject" label='Subject' refInput={(input) => subjectRef.current = input} inputProp={{value:centerData.subject, maxLength:20}} labelStyle={{color:"#FFF"}} customInputText={{backgroundColor:'rgba(255, 255, 255, 0.15)'}} onTextChange={(val) => setCenterData({...centerData, subject:val})} />
                            <InputField id="description" label='Description' refInput={(input) => descRef.current = input} inputProp={{value:centerData.description, maxLength:150, height:135}} multiline={true} labelStyle={{color:"#FFF"}} customInputText={{backgroundColor:'rgba(255, 255, 255, 0.15)'}} onTextChange={(val) => setCenterData({...centerData, description:val})} />
                        </div>
                    </div>

                    <div style={{marginTop:"20px"}}>
                        <ButtonFrame title="Done" contStyle={{marginTop: "0px", opacity:centerData.subject && centerData.description ? "1": "0.5", border:'none', backgroundColor:"#DB012E"}} onPress={() => console.log('mep')} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpCenter;