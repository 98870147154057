import { generalObj } from "../../constant/objects_types";
import { InfoIconSmallSvg } from "../../styles/IconStyle";
import BackArrow from "../BackArrow";
import BorderDivider from "../BorderDivider";
// import ButtonFrame from "../ButtonFrame";
import { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import WithdrawalComplete from "./WithdrawalComplete";
import { SYMBOL } from "../../constant/constant";
//import { checkEventPast } from "../../action/generalFunc";

const MoneyDetail = ({ visible, payItem, moneyInfo, bankModal, modalClose }:{ 
    visible:boolean, 
    payItem:generalObj, 
    moneyInfo:() => void, 
    bankModal:() => void,
    modalClose: () => void 
}) => {
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const { openWithdrawComplete }:{openWithdrawComplete:boolean} = useOutletContext();

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    // const checkWithdraw = () => {
    //     let isPast = checkEventPast(payItem.event);
    //     if (isPast) {
    //         bankModal();
    //     }else {
    //         alert('Withdrawal is available after event has ended.');
    //     }
    // }

    return (
        <>
            {firstShow && (
                <>
                    <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                    <div style={{display:'flex', alignItems:'center', gap:"3px"}}>
                                        <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                        <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>{payItem.name}</div>
                                    </div>
                                    <div>
                                        <button onClick={moneyInfo}>
                                            <InfoIconSmallSvg color={'rgba(255, 255, 255, 0.6)'} />
                                        </button>
                                    </div>

                                </div>

                                <div style={{width:'100%', marginTop:"24px"}}>
                                    <div>
                                        <div>
                                            <div>
                                                <p className="inputLabel" style={{marginTop:"12px", lineHeight:"18px", color:'#FFF'}}>Balance</p>
                                            </div>
                                            <div>
                                                <p className="inputLabel" style={{marginTop:0, lineHeight:"49px", fontSize:"36px", color:'#FFF'}}>{`${SYMBOL}${parseFloat(`${payItem.amount_made}`).toLocaleString()}`}</p>
                                            </div>
                                        </div>
                                        {/* <div style={{marginTop:"20px"}}>
                                            <ButtonFrame title="Withdraw" contStyle={{marginTop: 0, opacity:payItem.available_balance > 0?"1": "0.5", backgroundColor:"#DB012E", border:"none"}} onPress={() => payItem.available_balance > 0 ? checkWithdraw() : null} />
                                        </div> */}
                                        <BorderDivider borderStyle={{marginVertical:"24px"}} />
                                    </div>

                                    {/* <div style={{width:'100%', height:"100%"}}>
                                        {payItem.transaction && (
                                            <div style={{height:"calc(100vh - 390px)", paddingBottom:'90px', overflowX:'hidden'}}>
                                                {payItem.transaction.map((item:generalObj, index:number) => (
                                                    <div key={index} style={{paddingBlock:"20px", borderBottom: '1px solid rgba(66, 66, 66, 0.25)', borderRadius:"0px", marginTop:index === 0? 0: "16px", width:'100%', position:'relative'}}>
                                                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                                            <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>ID: {item.trx_id}</div>
                                                            <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>{item.amount}</div>
                                                        </div>
                                                        <div style={{display:'flex', alignItems:'center', marginTop:"20px", justifyContent:'space-between'}}>
                                                            <div className="locationRegion" style={{color:'rgba(255, 255, 255, .6)', fontSize:"14px"}}>{payItem.title}</div>
                                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', gap:"4px"}}>
                                                                <span className="locationRegion" style={{color:'rgba(255, 255, 255, .6)'}}>{item.trx_type}</span>   
                                                                <IoEllipseSharp size={"4px"} color="rgba(255, 255, 255, .6)" />
                                                                <span className="locationRegion" style={{color:'rgba(255, 255, 255, .6)'}}>{item.time}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <WithdrawalComplete visible={openWithdrawComplete} amount={`${SYMBOL}${parseFloat(`${payItem.available_balance}`).toLocaleString()}`} />
        </>
    )
}

export default MoneyDetail;