import { useOutletContext } from "react-router-dom";
import { BackArrow, ButtonFrame, MoneyDetail, WithdrawalComplete } from "../../components";
import { generalObj } from "../../constant/objects_types";
import { InfoIconSmallSvg } from "../../styles/IconStyle";
import { useEffect, useState } from "react";
import { IoChevronForwardOutline, IoEllipseSharp } from "react-icons/io5";
import { fetchUserWallet, getTimeStr } from "../../action/generalFunc";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { SYMBOL } from "../../constant/constant";

const MyMoney = () => {
    
    const [eventTrx, setEventTrx] = useState<generalObj[]>([]);
    const [eventPay, setEventPay] = useState<generalObj[]>([]);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [detailItem, setDetailItem] = useState<generalObj | null>();
    const { setEditProfile, setOpenMoneyInfo, setOpenModal, setEventWithdraw, openWithdrawComplete, setWithdrawalAmount, setModalLoading }:{ setEditProfile:Function, setOpenMoneyInfo:Function, setOpenModal:Function, setEventWithdraw:Function, openWithdrawComplete:boolean, setWithdrawalAmount:Function, setModalLoading:Function } = useOutletContext();
    const [eventAmount, setEventAmount] = useState<number>(0);
    const account = useSelector((state:RootState) => state.account);
    const [tableHead, setTableHead] = useState<generalObj[]>([
        {
            id: 1,
            title: 'Events',
            active: true
        },{
            id: 2,
            title: 'Transactions',
            active: false
        }
    ]);
    
    useEffect(() => {
        if (!account) return;
        (async () => {
            setModalLoading(true);
            let result:generalObj = await fetchUserWallet(account.token);
            if (result.status) {
                let amount = result.data.data.wallet_balance
                let trx = result.data.data.transactions_history
                setEventAmount(amount);
                setEventTrx([...trx]);
                setEventPay(result.data.data.events);
            }else {
                alert(result.message);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    useEffect(() => {
        setEditProfile(true);
    }, [setEditProfile]);

    const clearDetailItem = () => {
        setShowDetail(false);
        setTimeout(() => {
            setDetailItem(null);
        }, 1000);
    }

    const prepDetailItem = (item:generalObj) => {
        setDetailItem(item);
        setShowDetail(true);
    }

    const toggleTab = (val:generalObj) => {
        setTableHead((prevHead) => {
            return prevHead.filter((item:generalObj) => {
                if (item.id === val.id) {
                    item.active = true;
                }else {
                    item.active = false;
                }
                return item;
            })
        });
    }

    return (
        <>
            <div style={{height:'100%'}}>
                <div style={{padding:"0px 20px"}}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                        <div style={{display:'flex', alignItems:'center', gap:"3px"}}>
                            <BackArrow onPress={() => window.history.back()} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                            <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>My Money</div>
                        </div>
                        <div>
                            <button onClick={() => setOpenMoneyInfo(true)}>
                                <InfoIconSmallSvg color={'rgba(255, 255, 255, 0.6)'} />
                            </button>
                        </div>

                    </div>

                    <div style={{width:'100%', marginTop:"24px"}}>
                        <div>
                            <p className="inputLabel" style={{fontSize:"14px", marginTop:"8px", lineHeight:"19px", color:'#9E9B9B'}}>Monitor the money received from ticket sales.</p>
                            <div>
                                <div>
                                    <p className="inputLabel" style={{marginTop:"12px", lineHeight:"18px", color:'#FFF'}}>Total Balance</p>
                                </div>
                                <div>
                                    <p className="inputLabel" style={{marginTop:0, lineHeight:"49px", fontSize:"36px", color:'#FFF'}}>{`${SYMBOL}${parseFloat(`${eventAmount}`).toLocaleString()}`}</p>
                                </div>
                            </div>
                            <div style={{marginTop:"20px"}}>
                                <ButtonFrame title="Withdraw" contStyle={{marginTop: 0, opacity:eventAmount > 0 ? 1 : 0.6, backgroundColor:"#DB012E", border:"none"}} onPress={()=>{
                                    if (eventAmount > 0) {
                                        setWithdrawalAmount(eventAmount);
                                        setOpenModal(true);
                                     }
                                }} />
                            </div>
                        </div>
                        
                        <div style={{width:'100%', height:"100%", marginTop:'24px'}}>
                            <div style={{display:'flex', marginBottom:'24px'}}>
                                {tableHead.map((item:generalObj) => (
                                    <button key={item.id} style={{alignItems:'center', display:'flex', flexDirection:'column', width:'50%', borderTop:"1px solid rgba(255, 255, 255, 0.08)", padding:"12px 0px 0px"}} onClick={() => toggleTab(item)}>
                                        <div className="itemOwner"  style={{color:item.active ? '#FFFFFF' : '#FFFFFF99', fontSize:'12px'}}>{item.title}</div>
                                        {item.active && (<div style={{borderBottom:"1px solid #FFF", marginTop:"12px", width:"100%"}}></div>)}
                                    </button>
                                ))}
                            </div>
                            {tableHead.map((item:generalObj) => (
                                <div key={item.id}>
                                    {item.id === 1 && item.active && (
                                        eventPay.length > 0 && (
                                            <div style={{height:"calc(100vh - 410px)", paddingBottom:'90px', overflowX:'hidden'}}>
                                                {eventPay.map((item, index) => (
                                                    <button key={index} style={{padding: "16px", paddingBlock:"20px", backgroundColor: 'rgba(66, 66, 66, 0.25)', borderRadius:"12px", marginTop:index === 0? 0: "16px", width:'100%', position:'relative'}} onClick={() => prepDetailItem(item)}>
                                                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                                            <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>{item.name}</div>
                                                            <div style={{display: "flex", position: "absolute", right: "10px", top: "20px"}}>
                                                                <IoChevronForwardOutline size={20} color={'#FFF'} />
                                                            </div>
                                                        </div>
                                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                                            <div style={{maxWidth:'50%'}}>
                                                                <div className="inputLabel" style={{marginTop:"20px", color:'rgba(255, 255, 255, .6)', fontSize:"14px", textAlign:'start'}}>Total Amount</div>
                                                                <div className="locationRegion" style={{marginTop:"4px", textAlign:'start'}}>{`${SYMBOL}${parseFloat(`${item.amount_made}`).toLocaleString()}`}</div>
                                                            </div>
                                                            {/* {item.withdrawn > 0 && (
                                                                <div style={{maxWidth:'50%'}}>
                                                                    <div className="inputLabel" style={{marginTop:"20px", color:'rgba(255, 255, 255, .6)', fontSize:"14px", textAlign:'start'}}>Total Withdrawn</div>
                                                                    <div className="locationRegion" style={{marginTop:"4px", textAlign:'start'}}>{`${SYMBOL}${parseFloat(`${item.withdrawn}`).toLocaleString()}`}</div>
                                                                </div>
                                                            )} */}
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>
                                        )
                                    )}

                                    {item.id === 2 && item.active && (
                                        eventTrx.length > 0 && (
                                            <div style={{height:"calc(100vh - 410px)", paddingBottom:'90px', overflowX:'hidden'}}>
                                                {eventTrx.map((item:generalObj, index:number) => (
                                                    <div key={index} style={{paddingBlock:"20px", borderBottom: '1px solid rgba(66, 66, 66, 0.25)', borderRadius:"0px", marginTop:index === 0? 0: "16px", width:'100%', position:'relative'}}>
                                                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                                            <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>ID: {item.id}</div>
                                                            <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>{item.amount}</div>
                                                        </div>
                                                        <div style={{display:'flex', alignItems:'center', marginTop:"20px", justifyContent:'space-between'}}>
                                                            <div className="locationRegion" style={{color:'rgba(255, 255, 255, .6)', fontSize:"14px", maxWidth:'60%'}}>{item.description}</div>
                                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', gap:"4px"}}>
                                                                <span className="locationRegion" style={{color:'rgba(255, 255, 255, .6)'}}>{item.type}</span>   
                                                                <IoEllipseSharp size={"4px"} color="rgba(255, 255, 255, .6)" />
                                                                <span className="locationRegion" style={{color:'rgba(255, 255, 255, .6)'}}>{getTimeStr(item.created_at)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    )}
                                </div>        
                            ))}
                            
                            
                        </div>
                    </div>

                </div>
            </div>

            {detailItem && (
                <MoneyDetail visible={showDetail} payItem={detailItem} moneyInfo={() => setOpenMoneyInfo(true)} bankModal={() => {
                    setOpenModal(true);
                    setWithdrawalAmount(detailItem.available_balance);
                    setEventWithdraw(detailItem.id);
                }} modalClose={clearDetailItem}  />
            )}

            <WithdrawalComplete visible={openWithdrawComplete} amount={`${SYMBOL}${eventAmount.toLocaleString()}`} />
        </>
    )
}

export default MyMoney;