import { useEffect, useState } from "react";
import BackArrow from "../BackArrow";
import { IoCloseOutline, IoSearchOutline } from "react-icons/io5";
import ProfileGenerator from "../ProfileGenerator";
import { RadioSvg } from "../../styles/IconStyle";
import { generalObj } from "../../constant/objects_types";
import { searchUser } from "../../action/generalFunc";
import FooterAction from "../FooterAction";
import ButtonFrame from "../ButtonFrame";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

const ScanningPeople = ({ visible, selectedPeople, onChange, modalClose }:{
    visible:boolean,
    selectedPeople:generalObj[],
    onChange: (val:generalObj[]) => void,
    modalClose:() => void
}) => {
    const [scanningPeople, setScanningPeople] = useState<generalObj[]>([]);
    const [userSearch, setUserSearch] = useState<string>("");
    const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
    const [showSuggestionList, setShowSuggestionList] = useState<generalObj[]>([]);
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        if (!selectedPeople) return;
        setScanningPeople([...selectedPeople]);

    }, [selectedPeople])

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const updatePeople = (id:number) => {
        const update_ppl = scanningPeople.filter((item) => {
            if (item.id === id) {
                item.selected = !item.selected;
            }
            return item;
        })
        setScanningPeople([...update_ppl]);
        // setScanningPeople((prevScanningPeople) => {
        //     return (
        //         prevScanningPeople.filter((item) => {
        //             if (item.id === id) {
        //                 item.selected = !item.selected;
        //             }
        //             return item;
        //         })
        //     )
        // })
    }

    const textChangeData = async (val:string) => {
        if (!account) return;
        let val_text = val.toLowerCase();
        setUserSearch(val_text);
        if (val_text.length >= 2) {
            const result:generalObj = await searchUser(account.token, val_text);
            if (result.status) {
                setShowSuggestionList(result.data.data.users);
                setShowSuggestion(true);
            }else {
                setShowSuggestionList([]);
                setShowSuggestion(false);
            }
        }else {
            setShowSuggestionList([]);
            setShowSuggestion(false);
        }
    }

    const addPersonToScan = async (userObj:generalObj) => {
        const scanUser = {...userObj, selected: true}
        setScanningPeople([scanUser, ...scanningPeople]);
    }

    return (
        <>
            {firstShow && (
                <>
                    <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404", left:'0px'}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                        <div onClick={modalClose} className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Add People</div>
                                    </div>
                                </div>
                                
                                <div className="postOwnerTextl" style={{fontSize:"14px", lineHeight:"19px", marginBlock:"24px", color:'#9E9B9B'}}>Add the people you would like to have access to scan tickets on the day of the event.</div>

                                <div style={{position:'relative'}}>
                                    <div className="searchBar" style={{backgroundColor:'#1D1716', borderRadius: showSuggestion ? "16px 16px 0px 0px" : "16px", position:'relative'}}>
                                        <IoSearchOutline size={"16px"} color="rgba(255, 255, 255, 0.6)" />
                                        <input type="text" value={userSearch} className="inputText" style={{width:'90%', backgroundColor:'transparent'}} placeholder="Search friends" onChange={(val) => textChangeData(val.target.value)} />
                                        <button onClick={()=>textChangeData('')}>
                                            <IoCloseOutline size={"24px"} color="rgba(255, 255, 255, 0.6)" />
                                        </button>
                                    </div>
                                    {scanningPeople && (
                                        <>
                                            <div className="postOwnerTextl" style={{color:'rgba(255, 255, 255, 0.6)', fontSize:"16px", marginTop:"24px", zIndex:"0"}}></div>
                                
                                            <div>
                                                {scanningPeople.map((item:generalObj, index:number) => (
                                                    <button key={index} onClick={()=>updatePeople(item.id)} style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:"100%", marginTop:"24px"}}>
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                            {item.profile_picture ? (
                                                                <img src={item.profile_picture} style={{width:"40px", height:"40px"}} className="profilePictureUser" alt="ticketFriend" />
                                                            ): (
                                                                <ProfileGenerator title={item.username ? item.username[0].toUpperCase() : item.fullname[0].toUpperCase()} constStyle={{width:"40px", height:"40px"}} />
                                                            )}
                                                            <div style={{display:"flex", flexDirection:'column', marginLeft:"10px", alignItems:'flex-start'}}>
                                                                <div className="postOwnerText" style={{marginLeft:"0px", fontWeight:'600'}}>{item.fullname}</div>
                                                                {(item.username || item.fullname) && <div className="inputLabel" style={{color:'rgba(255, 255, 255, 0.6)'}}>{item.username ? item.username : item.fullname}</div>}
                                                            </div>
                                                        </div>
                                                        <div style={{alignSelf:'center'}}>
                                                            <RadioSvg active={item.selected} />
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                    {showSuggestion && showSuggestionList.length > 0 && (
                                        <div className="searchBar" style={{backgroundColor:'#1D1716', borderTop:"1px solid rgba(255, 255, 255, 0.15)", position:'absolute', top:"40px", borderTopLeftRadius:0, borderTopRightRadius:0, paddingBlock:"10px", flexDirection:'column', gap:'5px', justifyContent:'center', alignItems:'flex-start'}}>
                                            {showSuggestionList.map((item:generalObj, index:number) => (
                                                <button key={index} style={{display:'flex', alignItems:'center'}} onClick={() => addPersonToScan(item)}>
                                                    {item.profile_picture ? (
                                                        <img src={item.profile_picture} style={{width:"25px", height:"25px"}} className="profilePictureUser" alt="ticketFriend" />
                                                    ): (
                                                        <ProfileGenerator title={item.username[0].toUpperCase()} constStyle={{width:"25px", height:"25px"}} textStyle={{fontSize:"13px", lineHeight:"19px"}} />
                                                    )}
                                                    <div style={{display:'flex', marginLeft:"10px", flexDirection:'column', alignItems:"flex-start"}}>
                                                        <div className="postOwnerText" style={{marginLeft:"0px", fontWeight:'600'}}>{item.fullname}</div>
                                                        {item.username && <div className="inputLabel" style={{color:'rgba(255, 255, 255, 0.6)'}}>{item.username}</div>}
                                                    </div>
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <FooterAction contStyle={{width:"calc(100% - 40px)"}}>
                                <>
                                    <ButtonFrame title="Next" contStyle={{marginTop: "12px", backgroundColor:'#DB012E', border:"none", opacity:scanningPeople.length?"1.0":"0.6"}} onPress={() => onChange(scanningPeople.filter((item) => item.selected))} />
                                </>
                            </FooterAction>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default ScanningPeople;