import { useState, useEffect } from "react";
import InputField from "../InputField";
import ButtonFrame from "../ButtonFrame";
import { generalObj } from "../../constant/objects_types";

const TicketNameModal = ({ visible, ticketObj, modalClose, onComplete }:{ 
    visible:boolean,
    modalClose: () => void,
    ticketObj: generalObj,
    onComplete: (val:string) => void
}) => {
    const [formData, setFormData] = useState<generalObj>({
        name: ""
    });
    const [formError, setFormError] = useState<generalObj>({});

    useEffect(() => {
        setFormData((m) => ({...m, name:ticketObj.title}));
    }, [ticketObj]);
    
    const onSubmit = async () => {
        if (formData.name.length < 3 || formData.name.length > 15) {
            setFormError({...formError, name:true});
            return;
        };

        setFormError({});
        onComplete(formData.name);
    }

    

    return (
        <>
            <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", left:0, zIndex:`${visible ? "9999" : "0"}`}}>
                <div className="modalContainer" style={{overflow:'hidden'}}>
                
                    <div className={`actionView${!visible ? " animate__animated animate__slideInRight d-none" : " animate__animated animate__slideInRight" }`} style={{height:"auto", width:"calc(100% - 80px)", borderRadius:'14px', padding:'22px 20px'}}>
                        <div className={"rateHeader"} style={{marginBottom:'15px'}}>Update Ticket Name</div>
                        <InputField id="Name" inputProp={{value:formData.name, placeholder:"Ticket Name"}} isError={formError.name} onTextChange={(val)=>setFormData({...formData, "name": val})}  customInputText={{backgroundColor:'rgba(255, 255, 255, 0.12)'}} message={formError.code && {text:"Ticket name must have min of 3 & max of 15 characters", style: {}}} />
                        <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:"16px", gap:'5px'}}>
                            <ButtonFrame 
                                title="Cancel" contStyle={{marginTop:"0px", backgroundColor:"rgba(255, 255, 255, 0.16)", border:"none"}} onPress={modalClose}
                            />
                            {formData.name.length >= 3 && formData.name.length <= 15 ? (
                                <>
                                    <ButtonFrame title="Submit" contStyle={{marginTop: 0, backgroundColor:'#DB012E', borderWidth:0}} onPress={() => onSubmit()} />
                                </>
                            ):(
                                <ButtonFrame title="Submit" contStyle={{marginTop:0, backgroundColor:"#DB012E", border:"none", opacity:"0.6"}} onPress={() => null} /> 
                            )}
                        </div>
                    </div>
                
                </div>
            </div>
        </>
    )
}

export default TicketNameModal;