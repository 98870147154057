import { useSelector } from "react-redux";
import { UrlType } from "../constant/objects_types";
import { HomeSvg, ExploreSvg, TicketSvg, EventSvg } from "../styles/IconStyle";
import ProfileGenerator from "./ProfileGenerator";
import { RootState } from "../redux";
import { useState } from "react";
import CreateModal from "./modal/CreateModal";

const BottomTab = ({ location_path }:{
    location_path:any
}) => {
    const path_name = location_path.replace('/', "").split("/")[0];
    const account = useSelector((state:RootState) => state.account);
    const [openCreate, setOpenCreate] = useState<boolean>(false);

    return (
        <>
            <div className="bottomTabContainer">
                <a href={UrlType.HOME} className={path_name === "" ? 'active' : ""}>
                    <span>
                        <HomeSvg tintColor={path_name === "" ? '#FFFFFF' : "#BEBEBE"} active={path_name === "" ? true : false} />
                    </span>
                    <span>
                        Home
                    </span>
                    
                </a>

                <a href={UrlType.EXPLORE_LANDING} className={path_name === "explore" ? 'active' : ""}>
                    <span>
                        <ExploreSvg tintColor={path_name === "explore" ? '#FFFFFF' : "#BEBEBE"} active={path_name === "explore" ? true : false} />
                    </span>
                    <span>
                        Explore
                    </span>
                    
                </a>

                <a href="#create" onClick={() => setOpenCreate(true)}>
                    <span className="createEventIcon">
                        <EventSvg />
                    </span>
                </a>

                <a href={account && account.isAuthenticated ? UrlType.TICKET : `${UrlType.LOGIN}?redirect=${UrlType.TICKET}`} className={path_name === "ticket" ? 'active' : ""}>
                    <span>
                        <TicketSvg tintColor={path_name === "ticket" ? '#FFFFFF' : "#BEBEBE"} active={path_name === "ticket" ? true : false} />
                    </span>
                    <span>
                        Tickets
                    </span>
                    
                </a>

                <a href={account && account.isAuthenticated ? `${UrlType.PROFILE_USER}me` : `${UrlType.LOGIN}?redirect=${UrlType.PROFILE_USER}me`} className={path_name === "profile" ? 'active' : ""}>
                    <span>
                        <ProfileGenerator constStyle={{width:'24px', height:'24px', borderRadius:'24px'}} textStyle={{fontSize:'13px', lineHeight:'16px'}} useAccount={true} />
                    </span>
                    <span>
                        Profile
                    </span>
                </a>
            </div>
            <CreateModal visible={openCreate} modalClose={() => setOpenCreate(false)} />
        </>
    )
}

export default BottomTab;