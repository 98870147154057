import { useEffect, useRef, useState } from "react";
import { generalObj } from "../../constant/objects_types";
import { TicketSmallSvg } from "../../styles/IconStyle";
import BackArrow from "../BackArrow";
import ProfileGenerator from "../ProfileGenerator";
import { IoEllipse, IoPlayCircle, IoPauseCircle } from "react-icons/io5";
import ReactPlayer from "react-player";
import { checkEventPast } from "../../action/generalFunc";

const HeroDetails = ({ heroObj, parentWidth, isPreview, modalClose, SubmitEvent }:{
    heroObj:generalObj | null,
    parentWidth: number | null | undefined,
    isPreview?: boolean,
    modalClose?: () => void,
    SubmitEvent?: () => void,
}) => {
    const assetList:generalObj[] = [
        {
            id:'1',
            active:true
        },{
            id:'2',
            active:false
        }
    ];
    const [assetImg, setAssetImg] = useState<boolean>(true);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isInteracted, setIsInteracted] = useState<boolean>(false);
    const [showControl, setShowControl] = useState<boolean>(false);
    const [heroHeight, setHeroHeight] = useState<string>("100vw");

    const imageRef = useRef<HTMLDivElement | null>(null);
    const videoRef = useRef<HTMLDivElement | null>(null);
    const [touchStart, setTouchStart] = useState<number>(0);
    
    const assetListRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        const handleInteraction = () => { setIsInteracted(true);}
  
        document.addEventListener('click', handleInteraction, true)
        document.addEventListener('scroll', handleInteraction, true)

        return () => {
            document.removeEventListener('click', handleInteraction)
            document.removeEventListener('scroll', handleInteraction)
        }
    }, []);

    const handleSwitch = (target:string, val:boolean) => {
        if (!assetListRef || !assetListRef.current) return;
        assetListRef.current.querySelector(target)?.scrollIntoView({behavior:'smooth'});
        setAssetImg(val);
    }

    useEffect(() => {
        if (heroObj && !heroObj.promotion_video) return;
        
        const playVidS = setTimeout(() => {
            if (isInteracted) {
                handleSwitch("#review_asset__2", false);
                setIsPlaying(true);
            }
        }, 8000);
        
        return () => {
            clearTimeout(playVidS);
        };
    }, [heroObj, isInteracted]);

    const handleScroll = (evt:generalObj, deltaX:number=0) => {
        if (heroObj && !heroObj.promotion_video) return;
        if (evt.target.id === "imgID" && (evt.deltaX > 0 || deltaX < 0)) {
            // setAssetImg(false);
            handleSwitch("#review_asset__2", false)
        }else if (evt.target.id !== "imgID" && (evt.deltaX < 0 || deltaX > 0)) {
            // setAssetImg(true);
            handleSwitch("#review_asset__1", true)
        }
    }

    const handleTouchStart = (e:generalObj) => {
        setTouchStart(e.touches[0].clientX);
    }


    const handleTouchMove = (e:generalObj) => {
        let deltaX = e.touches[0].clientX - touchStart;
        handleScroll(e, deltaX)
    }

    const closePreview = () => {
        if (!modalClose) return;
        modalClose();
    }

    return (
        <>
            <div className="hero" ref={(e) => e && setHeroHeight(`${e.clientWidth}px`)} style={{backgroundImage:heroObj ? `url(${heroObj.cover_image}` : "url()", height:parentWidth ? `${parentWidth}px` : heroHeight, position:'relative'}}>
                <div className="heroBody">
                    <div className="navBar" style={{position:"relative", zIndex:"99"}}>
                        {!isPreview ? (
                            <div>
                                <BackArrow arrowStyle={{position:'relative', left:'0px'}} onPress={() => window.history.back()} />
                            </div>
                        ):(
                            <div style={{display:'flex', justifyContent:"space-between", width:"100%"}}>
                                <div style={{display:'flex', gap:"5px"}}>
                                    <BackArrow arrowStyle={{position:'relative', left:'0px', top:"-18px"}} onPress={() => closePreview()} />
                                    <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px",marginLeft:"18px"}} onClick={() => closePreview()}>Preview</div>
                                </div>
                                <button onClick={SubmitEvent} style={{fontSize:"13px", fontWeight:"600", color:"#FFF"}}>Done</button>
                            </div>
                        )
                        }
                        
                    </div>
                    <div className="review_asset__container" ref={assetListRef} style={{position:"absolute", width: "100%", height:"100%", overflow:'hidden'}} onWheel={(e) => handleScroll(e)} onTouchStart={(e) => handleTouchStart(e)} onTouchMove={(e) => handleTouchMove(e)}>
                        {assetList.map((item, index) => (
                            <div key={index} className="review_asset__item" style={{overflow:'hidden'}} id={`review_asset__${item.id}`}>
                                {item.id === '1' && (
                                    <> 
                                        <div ref={imageRef} id="imgID" className="displayImageContainer" style={{width:"100%", height:"100%"}}>
                                        </div> 
                                    </>
                                )}
                                {item.id === '2' && (
                                    <>
                                        {heroObj && heroObj.promotion_video && (
                                            <div ref={videoRef} id="videoID" style={{width:"100%", backgroundColor: "#000", height:"100%", position:'relative', overflow:'hidden'}}>
                                                <ReactPlayer url={heroObj.promotion_video} style={{width:'100%',height:'100%', objectFit:"contain"}} onEnded={() => setIsPlaying(false)} playing={isPlaying} playsinline={true} />
                                                <div style={{position:'absolute', width: "100%", height:"100%", top:"0px", left:"0px", display:'flex', justifyContent:'center', alignItems:'center'}} onMouseOut={() => setShowControl(false)} onMouseOver={() => setShowControl(true)} onTouchEnd={() => setShowControl(true)} onTouchCancel={() => setShowControl(false)}>
                                                    {!isPlaying ? (
                                                        <button onClick={() => {setIsPlaying(true);}} style={{position:'relative'}}>
                                                            <IoPlayCircle size={"48px"} color={"#FFF"} />
                                                        </button>
                                                    ):(
                                                        <button onClick={() => {setIsPlaying(false);}} style={{position:'relative', display:`${!showControl ? "none" : ""}`}}>
                                                            <IoPauseCircle size={"48px"} color={"#FFF"} />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                    <div style={{padding:"0px 20px", position:'relative'}}>
                        {heroObj ? (
                            <p className="heroTitle">{heroObj.name}</p>
                        ): (
                            <div style={{backgroundColor:'rgba(255, 255, 255, 0.12)', width:'100%', height:26, borderRadius:10}}></div>
                        )}

                        {heroObj && heroObj.user ? (
                            <a href="#0" className="postOwner">
                                {heroObj.user.profile_picture ? (
                                    <div className="profilePictureUser" style={{backgroundColor:'#c4c4c4'}}>
                                        <img src={heroObj.user.profile_picture} className="profilePictureUser" alt="postOwner" />
                                    </div>
                                ): (
                                    <ProfileGenerator title={heroObj.user.username[0].toUpperCase()} constStyle={{marginBottom:0}} textStyle={{fontSize:13, lineHeight:16}} />
                                )}
                                <p className="postOwnerText">{heroObj.user.username}</p>
                                <div style={{justifyContent: "center",display: "flex",alignItems: "center"}}>
                                    <TicketSmallSvg />
                                </div>
                            </a>
                        ): (
                            <div className="postOwner" style={{alignItems:'center'}}>
                                <div className="profilePictureUser" style={{backgroundColor:'rgba(255, 255, 255, 0.12)'}}></div>
                                <div className="postOwnerText" style={{backgroundColor:'rgba(255, 255, 255, 0.12)', width:"100px", height:"16px", borderRadius:"10px"}}></div>
                            </div>
                        )}

                        
                    </div>
                </div>
                {heroObj && checkEventPast(heroObj.event) && 
                    <div className="paidLabel">
                        <span style={{color: '#DB012E', fontWeight:'600'}}>Past event</span>
                    </div>
                }
            </div>
            {heroObj && heroObj.promotion_video &&(
                <div style={{display:'flex', marginTop:"10px", justifyContent:'center', alignItems:'center',gap:"7px"}}>
                    {assetList.map((item, index) => (
                        <div key={index}>
                            {item.id === '1' && (
                                <>
                                    
                                    <button onClick={()=>handleSwitch("#review_asset__1", true)}>
                                        <IoEllipse size={"10px"} color={assetImg ?'#FFFFFF':'rgba(255, 255, 255, 0.12)'} />
                                    </button>
                                </>
                            )}
                            {item.id === '2' && (
                                <>
                                    
                                    <button onClick={()=>handleSwitch("#review_asset__2", false)}>
                                        <IoEllipse size={"10px"} color={!assetImg ?'#FFFFFF':'rgba(255, 255, 255, 0.12)'} />
                                    </button>
                                </>
                            )}
                        </div>
                    ))}
                </div>

            )}
        </>
    )
}

export default HeroDetails;
