import { useState, useEffect, useRef } from 'react';
import { generalObj } from '../../constant/objects_types';
import { getNgBanks, getAccountName, addPayAccount, getPayAccounts, triggerWithdrawal, triggerEventWithdrawal } from '../../action/generalFunc';
import { IoAdd, IoCloseOutline } from 'react-icons/io5';
import ButtonFrame from '../ButtonFrame';
import InputField from '../InputField';
import PickerModal from './PickerModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import ModalLoader from './ModalLoader';

const AccountModal = ({ visible, event_id, withdrawAmount, modalClose, onDone }:{
    visible:boolean,
    event_id: number | null,
    withdrawAmount: number,
    modalClose:() => void,
    onDone: (val:generalObj) => void
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [accSect, setAccSect] = useState<generalObj>({
        selectAcc: true,
        addAcc: false 
    });

    const [accountState, setAccountState] = useState<generalObj>({
        name:'',
        bank:{
            label:'',
            value:''
        },
        acNumber:'',
        checkName: false,
        nameChecked: false
    });
    const accountTypeList = [
        {
            id:'00',
            label:'--Please select--',
            value:''
        },{
            id:'1',
            label:'Current Account',
            value:'Current'
        },{
            id:'2',
            label:'Savings Account',
            value:'Saving'
        }
    ];
    const [seletedAccountType, setSelectedAccountType] = useState<generalObj>({
        label:'--Please select--',
        value:''
    })
    
    const [bankList, setBankList] = useState<generalObj[]>([]);
    const [accountItem, setAccountItem] = useState<generalObj[]>([]);
    const [openPicker, setOpenPicker] = useState<boolean>(false);
    const [openAccPicker, setOpenAccPicker] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);

    const accNumberRef = useRef<HTMLInputElement | null>(null);
    const accNameRef = useRef<HTMLInputElement | null>(null);
    const account = useSelector((state:RootState) => state.account);
    
    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!account) return;

        (async () => {
            let result:generalObj = await getPayAccounts(account.token);
            if (result.status) {
                let result_minimal:generalObj[] = [];
                result.data.data.forEach((item:generalObj, index:number)=> {
                    result_minimal.push({
                        id: item.id,
                        name:item.account_name,
                        bank:{
                            label:item.bank_name,
                            value:item.code
                        },
                        acNumber:item.account_number,
                        selected: index===0?true:false
                    })
                })
                setAccountItem(result_minimal);
            }
        })();
    }, [account]);

    useEffect(() => {
        const loadBank = async() => {
            let result = await getNgBanks();
            if (result.status) {
                let result_minimal:generalObj[] = [];
                result.data.forEach((item:generalObj, index:number)=> {
                    if (item.active && !item.is_deleted) {
                        if (index===0) {
                            setAccountState((prevAccountState) => ({...prevAccountState, bank:{label:item.name, value:item.code}}));
                        }
                        result_minimal.push({
                            id:item.id,
                            label:item.name,
                            slug: item.slug,
                            value:item.code,
                            selected: index===0?true:false
                        })
                    }
                })
                setBankList(result_minimal);
            }
        }
        loadBank();
    }, [])

    useEffect(() => {
        if (accountState.acNumber.length !== 10 || !accountState.checkName || openPicker) return;
        const checkAccNumber = async () => {
            let result = await getAccountName(accountState.acNumber, accountState.bank.value);
            if (result.status) {
                setAccountState({...accountState, name:result.data.account_name, checkName:false, nameChecked:true});
            }
        }
        checkAccNumber();
    }, [accountState, openPicker]);

    const onAccountData = (evt:string, val:any) => {
        let new_data = {
            [evt]:val,
            nameChecked: false
        }
        if (evt === 'acNumber') {
            new_data['checkName'] = true
        }
        setAccountState({...accountState, ...new_data});
    }

    const selectAccount = (id:number | string) => {
        setAccountItem((prevAccountItem) => {
            return (
                prevAccountItem.filter((item) => {
                    if (item.id === id) {
                        item.selected = true;
                    }else {
                        item.selected = false;
                    }

                    return item;
                })
            )
        });
    }

    const addNewAccount = async () => {
        if (!account || !accountState.nameChecked || seletedAccountType.value === '') return;
        setModalLoading(true);
        let new_data = {
            id:`${Math.ceil(Math.random()*1000)}`,
            selected: true, 
            name:accountState.name,
            bank:{
                label:accountState.bank.label,
                value:accountState.bank.value
            },
            acNumber:accountState.acNumber,
            accType: seletedAccountType.value
        }
        const result:generalObj = await addPayAccount(account.token, {
            bank_name: new_data.bank.label,
            account_name: new_data.name,
            account_number: new_data.acNumber,
            account_type: new_data.accType,
            bank_code: new_data.bank.value
        });

        if (result.status) {
            let new_selection = accountItem.filter((item) => {
                item.selected = false
                return item
            });
            new_data.id = result.data.id;
            let ab_new_selection = [new_data, ...new_selection]
            setAccountItem(ab_new_selection);
            setAccSect({
                selectAcc: true,
                addAcc: false 
            });
            setAccountState({
                name:'',
                bank:{
                    label:'',
                    value:''
                },
                acNumber:'',
                checkName: false,
                nameChecked: false
            });
        }else {
            alert(result.message);
        }

        setModalLoading(false);
    }

    const submitAccount = async () => {
        if (!account) return;
        setModalLoading(true)
        let accountResult = accountItem.filter(item => item.selected);

        if (accountResult.length <=0) return;
        let api_data:generalObj = {
            amount: withdrawAmount,
            bank_account_id: accountResult[0].id
        }

        if (event_id) {
            api_data.event_id = event_id;
        }
        
        let result:generalObj = event_id ? await triggerEventWithdrawal(account.token, api_data) : await triggerWithdrawal(account.token, api_data);
        if (result.status) {
            onDone(accountResult[0]);
        }else {
            if (result.data) {
                alert(`${result.message} -> ${result.data.data.message}`);
            }else{
                alert(result.message);
            }
        }
        setModalLoading(false);
    }

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`}}>
                    <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                        <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                        <div className={`actionView ${!visible ? " animate__animated animate__slideOutDown" : " animate__animated animate__slideInUp" }`} style={{height:"auto", borderRadius:'14px', padding:'22px 20px'}}>
                            {
                                accSect.selectAcc && (
                                    <>
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                            <div>
                                                <div className='sectionTitle' style={{fontSize:"14px", lineHeight:"19px"}}>Select Account</div>
                                                <div className='inputLabel' style={{fontSize:"14px", marginTop:"8px"}}>Choose an account to recieve payment</div>
                                            </div>
                                            <button onClick={modalClose}>
                                                <IoCloseOutline size={"24px"} color={'#FFF'} />
                                            </button>
                                        </div>
                                        {accountItem && (
                                            <div style={{maxHeight:"300px", marginTop:"24px", overflowX:'hidden'}}>
                                                {accountItem.map((item, index) => (
                                                    <button key={index} style={{padding: "16px", border:"1px solid rgba(255, 255, 255, 0.12)", backgroundColor: item.selected? '#DB012E':'transparent', display:'flex', flexDirection:'column', gap:"6px", borderRadius:"12px", marginTop:index === 0? 0: "24px", width:"100%"}} onClick={()=>selectAccount(item.id)}>
                                                        <div className='locationRegion'>{item.acNumber}</div>
                                                        <div className='locationRegion'>{item.name.toUpperCase()}</div>
                                                        <div className='locationRegion'>{item.bank.label}</div>
                                                    </button>
                                                ))}
                                            </div>
                                        )}
                                        <button style={{display:'flex', marginBlock:"24px", marginTop:accountItem.length === 0 ? 0 :"24px"}} onClick={()=>setAccSect({selectAcc:false, addAcc:true})}>
                                            <div>
                                                <IoAdd size={"16px"} color={"#DB012E"} />
                                            </div>
                                            <div className='sectionTopLink' style={{fontSize:"13px", lineHeight:"16px"}}>Add Payment Account</div>
                                        </button>
                                        <div>
                                            <ButtonFrame title="Done" contStyle={{marginTop: 0, border:"none", backgroundColor:'#DB012E'}} onPress={submitAccount} />
                                        </div>
                                    </>
                                )
                            }

                            {   
                                accSect.addAcc && (
                                    <>
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                            <div>
                                                <div className='sectionTitle' style={{fontSize:"14px", lineHeight:"19px"}}>Add Payment Account</div>
                                                <div className='inputLabel' style={{fontSize:"14px", marginTop:"8px"}}>Add an account to receive payments</div>
                                            </div>
                                            <button onClick={modalClose}>
                                                <IoCloseOutline size={"24px"} color={'#FFF'} />
                                            </button>
                                        </div>
                                        <div style={{marginTop:'14px'}}>
                                            <InputField refInput={(input) => accNumberRef.current =input} id="acc_no" label='Account Number' inputProp={{value:accountState.acNumber, type:'number', maxLength:10}} labelStyle={{color:"#FFF"}} customInputText={{backgroundColor:'rgba(255, 255, 255, 0.15)'}} onTextChange={(val) => onAccountData('acNumber', val)} />
                                
                                            <div className='inputField'>
                                                <div className='inputLabel' style={{color:'#FFF'}}>Account Type</div>
                                                <div className='inputTextCont'>
                                                    <button className='inputText' style={{ display:'flex', justifyContent:'flex-start', backgroundColor:'rgba(255, 255, 255, 0.15)'}} onClick={()=>setOpenAccPicker(true)}>
                                                        <span className='activeText'>{seletedAccountType.label}</span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className='inputField'>
                                                <div className='inputLabel' style={{color:'#FFF'}}>Account Provider</div>
                                                <div className='inputTextCont'>
                                                    <button className='inputText' style={{ display:'flex', justifyContent:'flex-start', backgroundColor:'rgba(255, 255, 255, 0.15)'}} onClick={()=>setOpenPicker(true)}>
                                                        <span className='activeText'>{accountState.bank.label}</span>
                                                    </button>
                                                </div>
                                            </div>

                                            <InputField refInput={(input) => accNameRef.current =input} label='Account Name' id='acc_name' inputProp={{value:accountState.name, readOnly:true}} labelStyle={{color:'#FFF'}} customInputText={{backgroundColor:'rgba(255, 255, 255, 0.15)'}} onTextChange={(val) => val} />
                                        </div>
                                        
                                        <div style={{marginTop:"20px"}}>
                                            <ButtonFrame title="Done" contStyle={{marginTop: 0, border:"none", backgroundColor:'#DB012E', opacity:accountState.nameChecked?"1": "0.5"}} onPress={addNewAccount} />
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>
                </div>
            )}
            <PickerModal visible={openPicker} pickerItem={bankList} selectedValue={accountState.bank.value} onChange={(val) => {setAccountState({...accountState, bank:{label:val.label, value:val.value}})}} modalClose={()=>setOpenPicker(false)} />

            <PickerModal visible={openAccPicker} pickerItem={accountTypeList} selectedValue={seletedAccountType.value} onChange={(val) => {setSelectedAccountType({label:val.label, value:val.value})}} modalClose={()=>setOpenAccPicker(false)} />

            <ModalLoader visible={modalLoading} />                               
        </>
    )
}

export default AccountModal;