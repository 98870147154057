import { IoAddOutline, IoEllipsisVerticalSharp } from "react-icons/io5";
import { useState, useEffect } from "react";
import { EventItem, ManageAccount, ProfileGenerator, ProfilePictureModal } from "../../components";
import { generalObj } from "../../constant/objects_types";
import { InstagramSvg, TwitterSvg, WebsiteSvg, YoutubeSvg, TikTokSvg, SnapchatSvg } from "../../styles/IconStyle";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchMyEvent } from "../../action/generalFunc";
import { useOutletContext } from "react-router-dom";
import ProfileEditList from "../../components/profile/ProfileEditList";

const Profilediv = () => {
    const [profileLinks, setProfileLinks] = useState<generalObj[]>([
        {
            id:'1',
            name:"Website",
            slug: "website",
            url:null,
            icon: <WebsiteSvg />,
            show: false
        },{
            id:'2',
            name:"Instagram",
            slug: "instagram",
            url:null,
            icon:<InstagramSvg />,
            show: false
        },{
            id:'3',
            name:"Twitter",
            slug: "twitter",
            url:null,
            icon: <TwitterSvg />,
            show: false
        },{
            id:'4',
            name:"Youtube",
            slug: "youtube",
            url:null,
            icon: <YoutubeSvg />,
            show: false
        },{
            id:'5',
            name:"Tiktok",
            slug: "tiktok",
            url:null,
            icon: <TikTokSvg />,
            show: false
        },{
            id:'6',
            name:"Snapchat",
            slug: "snapchat",
            url:null,
            icon: <SnapchatSvg />,
            show: false
        }
    ]);
    const [newPic, setNewPic] = useState<string | null>(null);
    const [profileInfo, setProfileInfo] = useState<generalObj>({
        name: '',
        username: '',
        bio: "",
        email: ''
    });
    const [events, setEvents] = useState<generalObj[] | null>(null);
    const [showMore, setShowMore] = useState<boolean>(true);
    const [isSticky, setIsSticky] = useState<boolean>(false);
    const account = useSelector((state:RootState) => state.account);
    const { parentRef, setOpenMenu, setEditProfile, openManageAccount, setOpenManageAccount }:{ parentRef:generalObj, setOpenMenu:Function, setEditProfile:Function, openManageAccount:boolean, setOpenManageAccount:Function } = useOutletContext();
    
    const [getEventOffset, setEventOffset] = useState<number | null>();

    const [openProfileEdit, setOpenProfileEdit] = useState<boolean>(false);
    const [openProfileImage, setOpenProfileImage] = useState<boolean>(false);
    
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        setProfileInfo({
            name: account.fullname,
            username: account.username,
            gender: account.gender,
            phone: account.phone,
            email: account.email,
            walletID: account.walletId,
            bio: account.bio,
            country: account.country,
            dob: account.dob,
            profileImage:account.profileImage,
            socialLinks: account.socialLinks,
            event_cancellation: account.event_cancellation,
            event_recommendation: account.event_recommendation,
            event_reminders: account.event_reminders,
            ticket_check_in: account.ticket_check_in,
            ticket_purchase: account.ticket_purchase,
            ticket_refund: account.ticket_refund,
            ticket_sales: account.ticket_sales
        });
        setNewPic(account.profileImage);

        setProfileLinks((prevProfileLinks) => {
            return (
                prevProfileLinks.filter((item:generalObj) => {
                    if (account.socialLinks[item.slug]) {
                        item.url = account.socialLinks[item.slug];
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })

        const getMyEvent = async () => {
            const myevt:generalObj = await fetchMyEvent(account.token);
            if (myevt.status) {
                setEvents(myevt.data.data);
            }
        }

        getMyEvent();

    }, [account]);

    const handleScrolling = () => {
        if (getEventOffset && parentRef.current.scrollTop > getEventOffset) {
            setIsSticky(true);
        }else {
            setIsSticky(false);
        }
    }

    useEffect(() => {
        if (!parentRef) return;
        const parent_ref = parentRef.current;
        parent_ref.addEventListener('scroll', handleScrolling);

        return () => parent_ref.removeEventListener('scroll', handleScrolling);
    });

    const setProfileEditState = (val:boolean) => {
        setEditProfile(val);
        setOpenProfileEdit(val);
    }

    const setProfileImageState = (val:boolean) => {
        setEditProfile(val);
        setOpenProfileImage(val);
    }

    return (
        <>  
            <div style={{height:'100%'}}>
                <div style={{padding:"0px 20px"}}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"20px"}}>
                        <div style={{flexDirection:'row', alignItems:'center'}}>
                            <span className="sectionTitle">My Profile</span>
                        </div>
                        <button onClick={() => setOpenMenu(true)}>
                            <IoEllipsisVerticalSharp size={20} color="#FFF" />
                        </button>
                    </div>

                    <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginTop:"20px", alignItems:'center'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <button style={{position:'relative'}} onClick={() => setProfileImageState(true)}>
                                {newPic ? (
                                    <img src={newPic} style={{ width:"48px", height:"48px", borderRadius:"48px"}} alt="profile" />    
                                ): (
                                    <ProfileGenerator constStyle={{ width:"48px", height:"48px", borderRadius:"48px"}} useAccount={true} />
                                )}

                                <div className="bgMain" style={{width:"15px", height:"15px", borderRadius:"50px", display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', position:'absolute', bottom:"2px", right:"1px"}}>
                                    <div className="" style={{width:"10px", height:"10px", borderRadius:"50px", backgroundColor:"#DB012E", display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <IoAddOutline size={"8px"} color={"#FFF"} style={{left:"1px", top:"0.5px"}} />
                                    </div>
                                </div>
                            </button>
                            <div style={{marginLeft:"8px"}}>
                                <div className="postOwnerText" style={{marginLeft:"0px", fontSize:"13px", lineHeight:"16px"}}>{profileInfo.name}</div>
                                <div className="inputLabel" style={{color:'rgba(255, 255, 255, 0.6)', fontWeight:'500', marginTop:"6px"}}>@{profileInfo.username}</div>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <div className="postOwnerText" style={{marginLeft:"0px", fontSize:"18px", lineHeight:"25px", fontWeight:'500'}}>{events ? events.length : 0}</div>
                            <div className="inputLabel" style={{color:'rgba(255, 255, 255, 0.6)', fontWeight:'500', marginTop:"3px"}}>Events</div>
                        </div>
                    </div>

                    {/* Action Button */}
                    <div style={{marginTop:24, flexDirection:'row', width:'100%'}}>
                        <button className="postOwner buttonFrameCont heroBtn geryBtn" style={{marginTop:"0px", borderRadius:"7px"}} onClick={() => setProfileEditState(true)}>
                            <span className="buttonFrameText" style={{fontSize:"12px", lineHeight:"16px"}}>Edit Profile</span>
                        </button>
                    </div>

                    {/* Description */}
                    {profileInfo.bio && (
                        <>
                            <div style={{marginTop:"24px"}}>
                                <p className="locationRegion" style={{marginTop:"5px", fontSize:"14px", lineHeight:"19px", fontWeight:'400'}}>
                                    {profileInfo.bio.length >= 100 ? (
                                        showMore ? `${profileInfo.bio.slice(0, 100)}...` : profileInfo.bio
                                    ): profileInfo.bio }
                                </p>
                            </div>

                            {/* Truncate Bio */}
                            {profileInfo.bio.length >= 100 && (
                                <div style={{marginTop:"24px", display:'flex', width:'100%', justifyContent:'center'}}>
                                    <button onClick={()=>setShowMore(!showMore)}>
                                        <span className="buttonFrameText" style={{fontSize:"12px", lineHeight:"16px", color:'rgba(255, 255, 255, 0.6)'}}>{showMore ? "SHOW MORE" : "SHOW LESS"}</span>
                                    </button>
                                </div>
                            )}
                        </>
                    )}

                    {/* Profile Events links */}
                    <div style={{margin:"24px 0px", display:'flex', gap:"32px", overflowY:'hidden'}}>
                        {profileLinks.map((item:generalObj, index:number) => (
                            <a key={index} rel={"noreferrer"} target={item.show && item.url ? "_blank":"_self"} href={item.show && item.url ? item.url:"#0"} style={{alignItems:'center', display:'flex', flexDirection:'column', justifyContent:'center', opacity:item.show && item.url ? "1" : "0.3"}}>
                                <div style={{width:32, height:32, borderRadius:32, backgroundColor:'#191818', justifyContent:'center', alignItems:'center', display:'flex'}}>
                                    {item.icon}
                                </div>
                                <div className="locationRegion" style={{fontSize:"10px", lineHeight:"14px", color:'rgba(255, 255, 255, 0.6)', marginTop:"2px"}}>{item.name}</div>
                            </a>
                        ))}
                    </div>
                </div>

                {/* Event list */}
                <div className="bgMain" style={{marginBottom:"10px", marginTop:"12px", width:'100%', paddingBottom:"90px"}}>
                    <div className={`${isSticky? "sticky":""}`} ref={(e) => {e && e.offsetTop > 0 && setEventOffset(e.offsetTop);}} style={{alignItems:'center', display:'flex', flexDirection:'column', borderTop:"1px solid rgba(255, 255, 255, 0.08)", padding:"12px 20px 0px"}}>
                        <div className="itemOwner">Events</div>
                        <div style={{borderBottom:"1px solid #FFF", marginTop:"12px", width:"100%"}}></div>
                    </div>
                    {events && (
                        events.map((item:generalObj, index:number) => (
                            <EventItem key={index} item={item} listCont={{marginTop: index === 0 ? "20px" : "40px"}} imageCont={{width:`${parentRef.current.clientWidth - 40}px`, height:`${parentRef.current.clientWidth / (4/3)}px`}} actionPressed={(action, id)=>console.log(action, id)} updateEventItem={(id)=>console.log(id)} openRecommendPop={() => console.log('open recommend')} />
                        ))
                    )}
                </div>
            </div>
            
            {profileInfo.socialLinks && (
                <ProfileEditList visible={openProfileEdit} profileInfo={profileInfo} modalClose={() => setProfileEditState(false)} />
            )}

            <ProfilePictureModal visible={openProfileImage} modalClose={()=>setOpenProfileImage(false)} />

            {profileInfo.socialLinks && ( <ManageAccount visible={openManageAccount} profileInfo={profileInfo} modalClose={() => setOpenManageAccount(false)} />  )}
        </>
    )
}

export default Profilediv;