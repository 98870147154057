import BackArrow from "../BackArrow";
import { useEffect, useRef, useState } from "react";
import ProfileGenerator from "../ProfileGenerator";
import ButtonFrame from "../ButtonFrame";
import { generalObj } from "../../constant/objects_types";
import { useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState, actionCreator } from "../../redux";
import ImageCropper from "./ImageCropper";

const ProfilePictureModal = ({ visible, modalClose }:{
    visible:boolean,
    modalClose: () => void,
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [image, setImage] = useState<string | null>(null);
    const [imageCropping, setImageCropping] = useState<string | null>(null);
    const [imageObj, setImageObj] = useState<Blob | null>(null);
    const [openCropper, setOpenCropper] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { parentRef, setModalLoading }:{ parentRef:generalObj, setModalLoading:Function } = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const dispatch = useDispatch();
    const { updateProfile } = bindActionCreators(actionCreator, dispatch);
    
    
    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const pickImage = async () => {
        if (!inputRef.current) return;
        inputRef.current.click();
    };

    const handleImageChange = (e:generalObj) => {
        if (!e.target.files.length) return;
        const reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0]);
        reader.onload = () => {
            if (!reader || !reader.result) return;

            const blob = new Blob([reader.result]);

            const url = URL.createObjectURL(blob);
            setImageCropping(url);
            setOpenCropper(true);
        }
    }

    const onModalClose = () => {
        setOpenCropper(false);
        setTimeout(() => {
            setImageCropping(null);
            if (inputRef.current) {
                inputRef.current.value = "";
            }
        }, 1000);
    }

    const handleImageCropped = (val:Blob) => {
        const url = URL.createObjectURL(val);
        setImage(url);
        setImageObj(val);
        onModalClose();
    }


    const uploadProfilePicture = async () => {
        if (!imageObj || !account) {
            modalClose();
            return;
        }
        setModalLoading(true);
        await updateProfile(account.token, "profile_picture", imageObj, false);
        setModalLoading(false);
        modalClose();
    }

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" : " animate__animated animate__slideOutRight" }`} style={{backgroundColor:"#0D0404"}}>
                    <div className="modalContainer" style={{alignItems:'flex-start', flexDirection:'column'}}>
                        <div style={{width:'calc(100% - 40px)', display:"flex", justifyContent:'space-between', paddingTop:"20px", marginLeft:'auto', marginRight:'auto'}}>
                            <div style={{display:'flex', gap:"0px", alignItems:'center', width:'100%'}}>
                                <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Edit Profile</div>
                            </div>
                            <button onClick={uploadProfilePicture}>
                                <span className="sectionTitle" style={{fontSize:"15px", lineHeight:"16px", color:'#DB012E'}}>Done</span>
                            </button>
                        </div>

                        <div style={{height:"100%", width:'100%', padding:"40px 0px", position:'relative', display: "flex", flexDirection: "column",justifyContent: "space-between",paddingBottom: "90px"}}>
                            <div style={{width:`${parentRef.current ? `${parentRef.current.clientWidth}px` : "280px"}`, height:`${parentRef.current ? `${parentRef.current.clientWidth}px` : "280px"}`, display:'flex', justifyContent:'center', alignItems:'center'}}>
                                {image ? (
                                    <div style={{width:"100%", height:"100%"}}>
                                        <img src={image} style={{width:"100%", height:"100%"}} alt="profile" />
                                    </div>
                                ): (
                                    <ProfileGenerator constStyle={{ width:`${parentRef.current ? `${parentRef.current.clientWidth}px` : "280px"}`, height:`${parentRef.current ? `${parentRef.current.clientWidth}px` : "280px"}`, borderRadius:"0px"}} textStyle={{fontSize:`${Math.floor(parentRef.current.clientWidth / 5)}px`}} useAccount={true} />
                                )}
                            </div>
                            <div style={{margin:"0px 20px", position:'relative'}}>
                                <input type={"file"} accept={"image/*"} ref={inputRef} style={{position: "absolute",bottom: "0px",visibility: "hidden"}} onChange={(e) => handleImageChange(e)} />
                                <ButtonFrame title={'Change Profile Picture'} contStyle={{
                                    backgroundColor:'#DB012E', 
                                    border:"0px",
                                    marginTop:"24px",
                                    position:'relative'
                                }} onPress={pickImage} />
                            </div>
                        </div>
                    </div>
                    
                </div>
            )}
            {imageCropping && <ImageCropper visible={openCropper} modalClose={() => onModalClose()} source={imageCropping} onCropChange={(valObj) => handleImageCropped(valObj)} />}
        </>
    )
}

export default ProfilePictureModal;