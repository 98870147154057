import { IoEllipseSharp } from "react-icons/io5";

const ListProfile = ({ label, value, iconText, fieldStyle, inputStyle, onPress }:{
    label:string,
    value:string,
    iconText:JSX.Element,
    fieldStyle?:object,
    inputStyle?:object,
    onPress:()=>void,
}) => {
    return (
        <button className="inputField" style={{textAlign:'left', ...fieldStyle}} onClick={onPress}>
            <span className="inputLabel" style={{fontWeight:'500', fontSize:"14px", lineHeight:"19px", color:'rgba(255, 255, 255, 0.3)'}}>{label}</span>
            <div className="inputTextCont" style={{borderBottom:"1px solid rgba(255, 255, 255, 0.1)", paddingBottom:'16px'}}>
                <div className="inputText" style={{padding:"0px",backgroundColor: 'transparent', display:'flex', justifyContent:'space-between',...inputStyle}}>
                    {label === 'Password' ? (
                        <div style={{display:'flex', gap:"2px", justifyContent:'center', alignItems:'center'}}>
                            <IoEllipseSharp size={"8px"} color="rgba(255, 255, 255, 1)" />
                            <IoEllipseSharp size={"8px"} color="rgba(255, 255, 255, 1)" />
                            <IoEllipseSharp size={"8px"} color="rgba(255, 255, 255, 1)" />
                            <IoEllipseSharp size={"8px"} color="rgba(255, 255, 255, 1)" />
                            <IoEllipseSharp size={"8px"} color="rgba(255, 255, 255, 1)" />
                            <IoEllipseSharp size={"8px"} color="rgba(255, 255, 255, 1)" />
                        </div>
                    ):(
                        <span style={{fontWeight:'500', fontSize:"14px", lineHeight:"19px", color:'#FFF', width:'100%', textAlign:'left', display: "flex", alignItems:"center"}}>{value}</span>
                    )}
                    {iconText}
                </div>
            </div>
        </button>
    )
}

export default ListProfile;