import { useEffect, useState, useRef } from "react";
import { BackArrow, RegisterContent } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState, ractionCreator } from "../../redux";
import { useNavigate } from "react-router-dom";
import { UrlType } from "../../constant/objects_types";
import { IoCloseOutline } from "react-icons/io5";
import { checkUsername } from "../../action/generalFunc";
import { validColor, invalidColor } from "../../constant/constant";

const RegisterStepThree = () => {
    type msgRObj = {
        [key:string]:any
    }
    const resgisterState = useSelector((state:RootState) => state.registerInfo);  
    const [username, setUserName] = useState<string>('');
    const [nameErr, setNameErr] = useState<boolean>(false);
    const [message, setMessage] = useState<msgRObj>({
        color:'#029522',
        message: '',
        isValid: false
    })
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updateInfo } = bindActionCreators(ractionCreator, dispatch);
    const nameRef = useRef<HTMLInputElement | null>();

    useEffect(() => {
        if (!resgisterState || !resgisterState.email || !resgisterState.fullname) {
            navigate(UrlType.REGISTER_STEP1);
            return;
        }
        if (!resgisterState.username) return;
        setUserName(resgisterState.username);
    }, [resgisterState, navigate]);

    useEffect(() => {
        if (username.length < 3) {
          setMessage((prevMessage) => ({...prevMessage, isValid:false}))
          return
        };
        const usernameCheck = async () => {
          let isUsername = await checkUsername(username);
          if (isUsername.status) {
            setMessage((prevMessage) => ({...prevMessage, color:isUsername.valid?validColor:invalidColor, message:isUsername.message, isValid:isUsername.valid}));
            setNameErr(true);
          }
        }
        usernameCheck();
  }, [username]);
    
      const checkName = () => {
        if (username.length >= 3) {
          setNameErr(false);
          return true;
        }else {
          setNameErr(true);
          setMessage({...message, color:invalidColor, message:"Username must be atleast 3 characters", isValid:false});
          return false;
        }
      }
    
      const onSubmit = async () => {
        if (username.length === 0) return;
        if (message.isValid) { 
            await updateInfo("username", username);
            setNameErr(false);
            navigate(UrlType.REGISTER_STEP31);
        }
      }
    
      const onTextChange = (val:string) => {
        setUserName(val.toString().replace(" ", "")); 
        setNameErr(false);
      }

    return (
        <>
            <div className='authContainer' style={{justifyContent:'flex-start'}}>
                <BackArrow onPress={() => {window.history.back()}} />
                <div className="register">
                    <RegisterContent 
                        header="Create a username" 
                        btnTitle="Next" 
                        id={'username'}
                        description="Create a username for your account." 
                        inputProp={{value:username, text:'text', maxLength:'20', onBlur:checkName}}
                        refInput={(input)=>nameRef.current = input}
                        isIcon={<IoCloseOutline size={"24px"} color="#FFF" /> } 
                        onPress={onSubmit}
                        btnStyle={message.isValid ? {opacity:'1', cursor:"pointer"} : {cursor:'not-allowed'}}
                        onTextChange={(val)=>{onTextChange(val)}}
                        iconPress={() => {onTextChange('')}}
                        message={{
                            style: {
                                color: message.color
                            },
                            show: nameErr,
                            text: message.message
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default RegisterStepThree;