
import { UrlType } from "../../constant/objects_types";
import { CreateEventSvg, CreateResellSvg } from "../../styles/IconStyle";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { setUpPrevUrl } from "../../action/generalFunc";

const CreateModal = ({ visible=false, modalClose }:{
    visible?:boolean,
    modalClose: () => void,
}) => {

    const navigate = useNavigate();
    const account = useSelector((state:RootState) => state.account);

    return (
        <div className={`modalParentContainer${!visible ? " d-none" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"rgba(0, 0, 0, 0.70)", zIndex:"9999"}}>
            <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                <div className="actionView animate__animated animate__slideInUp" style={{height:"auto"}}>
                    <div style={{margin: "20px 10px 22px 10px", position: "relative", height: "100%"}}>
                        <div className="actionItemCont shareItemCont contact" style={{position:'relative', height:'auto', flexDirection:"column", gap:"12px"}}>
                            <div style={{display:"flex", alignItems:"center", gap:"10px"}} onClick={() => navigate(account && account.isAuthenticated ? setUpPrevUrl(window.location.pathname, UrlType.CREATE_EVENT) : setUpPrevUrl(window.location.pathname, `${UrlType.LOGIN}?redirect=${UrlType.CREATE_EVENT}`))}>
                                <span style={{display:"inline-flex", width:"20px", height:"20px"}}>
                                    <CreateEventSvg />
                                </span>
                                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                                    <span className="postOwnerTextl" style={{margin:0, fontSize:"14px", fontWeight:"500"}}>Create Event</span>
                                </div>
                            </div>
                            <div style={{display:"flex", alignItems:"center", gap:"10px"}} onClick={() => navigate(account && account.isAuthenticated ? setUpPrevUrl(window.location.pathname, UrlType.CREATE_RESELL_EVENT) : setUpPrevUrl(window.location.pathname, `${UrlType.LOGIN}?redirect=${UrlType.CREATE_RESELL_EVENT}`))}>
                                <span style={{display:"inline-flex", width:"28px", height:"28px"}}>
                                    <CreateResellSvg />
                                </span>
                                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                                    <span className="postOwnerTextl" style={{margin:0, fontSize:"14px", fontWeight:"500"}}>Resell Ticket</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateModal;