import { useRef, useState } from "react";
import { useEffect } from "react";
import { generalObj } from "../../constant/objects_types";
import KycList from "./KycList";

const KycListCont = ({ data, isAnim }:{
    data:generalObj[];
    isAnim:boolean;
}) => {

    const [genreTypes, setGenreTypes] = useState<generalObj[]>([]);
    const kycRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!data) return;
        setGenreTypes([...data]);
    }, [data]);

    const onSelect = (id:number) => {
        const updateGenre = genreTypes.filter((genre) => {
            if (genre.id === id) {
                genre.selected = genre.selected ? false : true;
            }
            return genre
        })
        setGenreTypes(updateGenre);
    }
    

    return (
        <div className="kycList" ref={kycRef}>
            {genreTypes && genreTypes.map((item, index) => (
                <KycList key={index} index={index} item={item} contStyle={{marginBottom:genreTypes.length - 1===index?55:0, width:`${kycRef.current ? `${(kycRef.current.clientWidth - 20) / 3}px` : "33.33%"}`}} isAnim={isAnim} onPress={(id) => onSelect(id)} />
            ))}
        </div>
    )
}

export default KycListCont;