import { useState, useEffect } from "react";
import BorderDivider from "../BorderDivider";
import { PROCESSING_FEE, SERVICE_FEE } from "../../constant/constant";

const SellPriceModal = ({ visible, modalClose }:{
    visible:boolean,
    modalClose:() => void
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    
    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`, left:"0px"}}>
                    <div className="modalContainer" style={{overflow:'hidden'}}>

                    <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                    <div className={`actionView moneyInfo${!visible ? " animate__animated animate__slideOut" : " animate__animated animate__zoomIn" }`} style={{height:"auto", width:"calc(100% - 80px)", borderRadius:'14px', padding:'22px 24px'}}>
                        <div className={"detailTitle"} style={{textAlign:'center'}}>Selling Price Summary</div>
                        <div className="detailSection" style={{display:'flex', justifyContent:'space-between'}}>
                            <div className="detailDesc" style={{textAlign:'center'}}>Service Fee</div>
                            <div className="detailDesc detailDescPrice" style={{textAlign:'center'}}>{SERVICE_FEE}%</div>
                        </div>
                        <div className="detailSection" style={{display:'flex', justifyContent:'space-between'}}>
                            <div className="detailDesc" style={{textAlign:'center'}}>Processing Fee</div>
                            <div className="detailDesc detailDescPrice" style={{textAlign:'center'}}>{PROCESSING_FEE}%</div>
                        </div>
                        <BorderDivider borderStyle={{marginTop:"16px", marginBottom:"0px"}} />
                        <div className="detailSection" style={{display:'flex', justifyContent:'space-between'}}>
                            <div className="detailDesc" style={{textAlign:'center'}}>Total</div>
                            <div className="detailDesc detailDescPrice" style={{textAlign:'center'}}>{SERVICE_FEE + PROCESSING_FEE}%</div>
                        </div>
                        <div style={{marginTop:"16px", textAlign:'center'}}>
                            <button onClick={modalClose}>
                                <span className="sectionTopLink" style={{lineHeight:"16px", fontSize:'13px'}}>Close</span>
                            </button>
                        </div>
                    </div>
                    
                    </div>
                </div>
            )}
        </>
    )
}

export default SellPriceModal;