import { useRef, useState, useEffect } from "react";
import InputField from "../InputField";
import ButtonFrame from "../ButtonFrame";

const FirstModal = ({visible, onNext, closePop}:{
    visible:boolean,
    onNext: () => void,
    closePop: () => void,
}) => {
    
    return (
        <div className={`actionView${!visible ? " animate__animated animate__slideInRight d-none" : " animate__animated animate__zoomIn" }`} style={{height:"auto", width:"calc(100% - 80px)", borderRadius:'14px', padding:'22px 20px'}}>
            <div className={"rateHeader"}>Delete Account</div>
            <div  className={"rateDesc"}>Are you sure you want to delete your account?</div>
            <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:"16px", gap:'5px'}}>
                <ButtonFrame 
                    title="Cancel"  contStyle={{marginTop:"0px", backgroundColor:"rgba(255, 255, 255, 0.16)", border:"none"}} onPress={closePop}
                />
                <ButtonFrame 
                    title="Done" contStyle={{marginTop:0, backgroundColor:"#DB012E", border:"none"}} onPress={onNext}
                />
            </div>
        </div>
    )
}

const SecondModal = ({visible, onNext, closePop}:{
    visible:boolean, 
    onNext:() => void, 
    closePop: () => void
}) => {
    const [reason, setReason] = useState('');
    const inputRef = useRef<HTMLInputElement | null>(null);

    return (

        <div className={`actionView${!visible ? " animate__animated animate__slideInRight d-none" : " animate__animated animate__slideInRight" }`} style={{height:"auto", width:"calc(100% - 80px)", borderRadius:'14px', padding:'22px 20px'}}>
            <div className={"rateHeader"}>What Happened?</div>
            <div  className={"rateDesc"} style={{marginBottom:"16px"}}>We’ll like to know why you deleted you account</div>
            <InputField id="reason" refInput={(input) => inputRef.current = input} inputProp={{value:reason, placeholder:"Reason"}} onTextChange={(val)=>setReason(val)}  customInputText={{backgroundColor:'rgba(255, 255, 255, 0.12)'}} />
            <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:"16px", gap:'5px'}}>
                <ButtonFrame 
                    title="Close"  contStyle={{marginTop:"0px", backgroundColor:"rgba(255, 255, 255, 0.16)", border:"none"}} onPress={closePop}
                />
                <ButtonFrame 
                    title="Submit" contStyle={{marginTop:0, backgroundColor:"#DB012E", border:"none"}} onPress={onNext}
                />
            </div>
        </div>
    )
}

const ThirdModal = ({visible, onNext}:{
    visible:boolean,
    onNext: () => void,
}) => {

    return (
        <div className={`actionView${!visible ? " animate__animated animate__slideOutRight d-none" : " animate__animated animate__slideInRight" }`} style={{height:"auto", width:"calc(100% - 80px)", borderRadius:'14px', padding:'22px 20px'}}>
            <div className={"rateHeader"}>We’ll miss you!</div>
            <div  className={"rateDesc"}>We hope you come back soon!</div>
            <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:"16px", gap:'5px'}}>
                <ButtonFrame 
                    title="Thanks" contStyle={{marginTop:0, backgroundColor:"#DB012E", border:"none"}} onPress={onNext}
                />
            </div>
        </div>
    )
}

const DeleteAccountModal = ({ visible, endFlow, modalClose }:{ 
    visible:boolean, 
    endFlow: () => void, 
    modalClose: () => void 
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [openFirst, setOpenFirst] = useState<boolean>(true);
    const [openSecond, setOpenSecond] = useState<boolean>(false);
    const [openThird, setOpenThird] = useState<boolean>(false);


    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`}}>
                    <div className="modalContainer" style={{overflow:'hidden'}}>
                    
                        <FirstModal visible={openFirst} closePop={() => {
                            modalClose();
                        }} onNext={()=> {
                            setOpenFirst(false);
                            setOpenSecond(true);
                        }} />
                        {openSecond && (
                            <SecondModal visible={openSecond} closePop={() => {
                                setOpenSecond(false);
                                setOpenThird(true);
                            }} onNext={()=> {
                                setOpenSecond(false);
                                setOpenThird(true);
                            }} />
                        )}
                        
                        {openThird && (
                            <ThirdModal visible={openThird} onNext = {() => {
                                endFlow();
                                modalClose()
                            }} />
                        )}
                    
                    </div>
                </div>
            )}
        </>
    )
}

export default DeleteAccountModal;