
import { useState, useEffect, useRef } from 'react';
import { generalObj } from '../../constant/objects_types';
import ModalLoader from '../modal/ModalLoader';
import ExploreHeader from '../explore/ExploreHeader';
import { useUserLocation } from '../../ticki_hooks';
import EventItem from './EventItem';
import { fetchEventType } from '../../action/generalFunc';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import LocationModal from '../modal/LocationModal';

const LandingViewAllModal = ({ visible, item, link_name, modalClose }:{
    visible: boolean,
    item: generalObj[],
    link_name: string,
    modalClose: () => void
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [modalLoader, setModalLoader] = useState<boolean>(false);
    const [locationData, locationStateData] = useUserLocation();
    const [locationModal, setLocationModal] = useState<boolean>(false);
    const [newLocation, setNewLocation] = useState<string | null>(null);
    const [eventFilters, setEventFilter] = useState<generalObj[]>([]);
    const [event, setEvent] = useState<generalObj[]>([]);

    const headerRef = useRef<HTMLDivElement | null>(null);
    const account = useSelector((state:RootState) => state.account);
    const [headerHeight, setHeaderHeight] = useState<number>(180);
    const [showingFilter, setShowingFilter] = useState<boolean>(false);
    
    

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!headerRef.current) return;
        setHeaderHeight(headerRef.current.clientHeight);
    }, [showingFilter]);

    useEffect(() => {
        if (!item || !account) return;
        setModalLoader(true);
        setEvent(item.filter((oItem) => {
            oItem.show = true;
            return oItem;
        }));

        const getEventTypes = async () => {
            let new_filter:object[] = [];
            let current_event_filter:object[] = [];

            item.forEach(element => {
                if (!current_event_filter.includes(element.event_type_id)) {
                    current_event_filter.push(element.event_type_id);
                }
            })
            const result:generalObj = await fetchEventType(account.token);
            if (result.status) {
                new_filter.push({
                    id: '00',
                    name: 'All',
                    show:true,
                    active: true
                });
                result.data.data.forEach((element:generalObj) => {
                    new_filter.push({
                        ...element,
                        show:current_event_filter.includes(element.id) ? true : false,
                        active: false
                    })
                });
            }

            setEventFilter(new_filter);

            setModalLoader(false);
        }

        getEventTypes();

    }, [item, account]);

    const updateFilter = (id:number|string) => {
        let event_type_change:number|string|null = null;
        setEventFilter((prevEventFilter) => {
            return (
                prevEventFilter.filter((item) => {
                    if (item.id === id && item.active) {
                        return item;
                    }
                    if (item.id === id && !item.active) {
                        item.active = true;
                        if (event_type_change !== item.id) {
                            event_type_change = item.id;
                        }
                    }else {
                        item.active = false;
                    }
                    return item;
                })
            )
        });

        setEvent((prevEvent) => (
            prevEvent.filter((item) => {
                if (id === '00') {
                    item.show = true;
                }
                else if (id === item.event_type_id) {
                    item.show = true;
                }else {
                    item.show = false;
                }
                return item;
            })
        ))
    }

    return (
        <>
            {firstShow && (
                <>
                <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                    <div className="modalContainer" style={{}}>
                        <div style={{margin:"0px 20px", width:'100%', height:'100%', position:'relative', overflowX:'hidden', paddingBottom:'180px'}}>
                            <div style={{marginTop:"5px"}}>
                                <div ref={headerRef} style={{paddingTop:"20px", position:'fixed', width:'100%', top:"0px", zIndex:'99', backgroundColor:'#0D0404', left:0}}>
                                    <ExploreHeader eventFilters={eventFilters} locationData={locationData} newLocation={newLocation} isFiltered={true} filteredTitle={link_name} offSearch={true} openLocationModal={(val) => setLocationModal(val)} showDistance={(val) => setShowingFilter(val)} onPress={(val) => updateFilter(val.id)} isSubFilter={true} goBack={() => modalClose()} />
                                </div>
                                <div style={{paddingTop:`${headerHeight + 70}px`}}>
                                    {event.map((item:generalObj, index:number) => (
                                        item.show && <EventItem key={index} item={item} listCont={{marginTop: index === 0 ? "20px" : "40px"}} actionPressed={(action, id)=>console.log(action, id)} updateEventItem={(id)=>console.log(id)} openRecommendPop={() => console.log('open recommend')}  />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}
            {locationStateData && (
                <LocationModal visible={locationModal}  locationData={locationStateData} modalClose={() => setLocationModal(false)} newLocation={(val) => {setNewLocation(val); setLocationModal(false);}} />
            )}
            <ModalLoader visible={modalLoader} />
        </>
    )
}

export default LandingViewAllModal;