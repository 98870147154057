import 'animate.css';
import '../styles/style.css';
import { InputField, ButtonFrame, ModalLoader, SignupFooter } from '../components';
import { useRef, useState, useEffect } from 'react';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator, RootState } from '../redux';
import { bindActionCreators } from 'redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkDeviceApple } from '../action/generalFunc';
import { UrlType } from '../constant/objects_types';
// import { useGoogleLogin } from '@react-oauth/google';
// import axios from 'axios';

const Login = () => {
    const [loginData, setLoginData] = useState({
        principal: '',
        password: '',
        secureEntry: true
    });
    // const [connectGoogle, setConnectGoogle] = useState(false);
    // const [connectApple, setConnectApple] = useState(false);

    const [modalLoading, setModalLoading] = useState(false);

    const principalRef = useRef<HTMLInputElement | null>();
    const passwordRef = useRef<HTMLInputElement | null>();
    const [ searchParams ] = useSearchParams();


    const account = useSelector((state:RootState) => state.account)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { login } = bindActionCreators(actionCreator, dispatch);

    useEffect(() => {
        if (!account || !account.isAuthenticated || account.isOnboarding) return;
        setModalLoading(false);
        if (searchParams.get('redirect')) {
            navigate(`${searchParams.get('redirect')}`);
        }else {
            navigate(UrlType.HOME);
        }
    }, [account, searchParams, navigate]);

    
    
    // const callGoogleLogin = useGoogleLogin({
    //     onSuccess: async (tokenResponse) => {
    //     await axios.get(
    //         'https://www.googleapis.com/oauth2/v3/userinfo',
    //         { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
    //     ).then(async (resp) => {
    //         await googleAuth(resp.data.name, resp.data.given_name, tokenResponse.access_token, resp.data.picture, resp.data.email)
    //     });
    //     setModalLoading(false);
    //     },
    //     onError: errorResponse => {
    //         console.log(errorResponse);
    //         setModalLoading(false);
    //     }
    // });

    // const googleLogin = () => {
    //     setModalLoading(true);
    //     callGoogleLogin();
    // }

    const callLogin = async () => {
        if (!principalRef.current || !passwordRef.current) return;

        if (!loginData.principal) {
          principalRef.current.focus();
          return;
        }
        if (!loginData.password) {
          passwordRef.current.focus();
          return;
        }
        
        setModalLoading(true);
        let am = await login(loginData.principal, loginData.password);
        if (!am) {
          setModalLoading(false);
        }
    }

    return (
        <>
            <div className='authContainer'>
                <div className='logoContainer animate__animated animate__flipInY'>
                    <img src={require('../assets/adaptive-icon.png')} alt="logo" />
                </div>

                {/* Email & username field */}
                <InputField id="email" label={'Username or Email Address'} inputProp={{value:loginData.principal, type:"text", required:true}} refInput={(input)=>principalRef.current = input} onTextChange={(val)=> setLoginData({...loginData, principal:val})} />

                
                {/* Password field */}
                <InputField 
                    id="password" 
                    label={'Password'} 
                    customInputText={{paddingRight:'32px'}}
                    inputProp={{value:loginData.password, type:loginData.secureEntry ? "password" : "text", required:true}} 
                    isIcon={loginData.secureEntry ? <IoEyeOffOutline size={"16px"} color="rgba(255, 255, 255, 0.6)" /> :<IoEyeOutline size={"16px"} color="rgba(255, 255, 255, 0.6)" /> } 
                    refInput={(input)=>passwordRef.current = input} 
                    iconPress={() => setLoginData({...loginData, secureEntry:!loginData.secureEntry})}
                    onTextChange={(val)=> setLoginData({...loginData, password:val})} 
                />

                {/* Forgot Password Link */}
                <div className='forgotText'>
                    <a href={`${UrlType.FORGOT_PASSWORD}`}>
                        <span>Forgot Password?</span>
                    </a>
                </div>

                {/* Sign In Buttons */}
                <ButtonFrame title="Log In" contStyle={{marginTop: '32px', backgroundColor:'#DB012E', border:'none'}} onPress={callLogin} />

                {/* <ButtonFrame title="Continue with Google" iconType="image" imgProp={{src:require('../assets/img/google.png')}} onPress={googleLogin} /> */}

                {/* <GoogleConnect connect={connectGoogle} onCancel={() => {setConnectGoogle(false);setModalLoading(false);}} userData={callOAuthLogin} /> */}

                {checkDeviceApple() && (
                <>
                    {/* <ButtonFrame title="Continue with Apple" iconType="image" imgProp={{src:require('../assets/img/apple.png')}} onPress={() => setConnectApple(true)} /> */}
                </>
                )}

            </div>

            {/* Sign Up Link */}
            <SignupFooter addClass={"forgotText"} textStyle={{paddingBottom:'22px'}} />

            <ModalLoader visible={modalLoading} />
        </>
    )
}

export default Login;