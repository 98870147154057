import { useState, useEffect } from 'react';
import { generalObj } from '../../constant/objects_types';
import { checkDeviceApple } from '../../action/generalFunc';
import BorderDivider from '../BorderDivider';

const MapSelectorModal = ({ visible, data, modalClose }:{ 
    visible:boolean, 
    data: generalObj,
    modalClose: () => void,
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const openGoogleMap = () => {
        window.open(`https://maps.google.com?q=${data.formattedAddress}`);
    }

    const openAppleMap = () => {
        //window.open(`https://maps.apple.com?q=${data.coordinates.latitude},${data.coordinates.longitude}`);
        window.open(`https://maps.apple.com?q=${data.addressLabel}`);
    }

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`, left:"0px"}}>
                    <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                        <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                        <div className={`actionView${!visible ? " animate__animated animate__slideOutDown" : " animate__animated animate__slideInUp" }`} style={{height:"auto", padding:"10px 0px", margin:"0px 20px", backgroundColor:'transparent'}}>
                            <div style={{backgroundColor:'#191818', borderRadius:"18px"}}>
                                {checkDeviceApple() && (
                                    <>
                                        <button onClick={()=>openAppleMap()} style={styles.mapTypeSelect}>
                                            <span className="postOwnerTextl" style={{fontSize:"16px"}}>Open in Maps</span>
                                        </button>
                                        <BorderDivider borderStyle={{marginBlock:"5px"}} />
                                    </>
                                )}
                                <button onClick={()=>openGoogleMap()} style={styles.mapTypeSelect}>
                                    <span className='postOwnerTextl' style={{fontSize:"16px"}}>Open in Google Maps</span>
                                </button>
                            </div>
                            <div style={{backgroundColor:'#191818', borderRadius:"18px", marginTop:"8px"}}>
                                <button onClick={modalClose} style={styles.mapTypeSelect}>
                                    <span className='postOwnerTextl' style={{fontSize:"16px"}}>Cancel</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )

}

export default MapSelectorModal;

const styles = {
    mapTypeSelect: {
        padding:"15px 0px", 
        alignItems:'center',
        width:"100%"
    }
}