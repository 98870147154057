export type generalObj = {
    [key:string]: any
};

export enum UrlType {
    HOME = "/",
    LOGIN = "/account/login/",
    REGISTER = "/account/register/",
    REGISTER_STEP1 = "/account/register/step1/",
    REGISTER_STEP2 = "/account/register/step2/",
    REGISTER_STEP3 = "/account/register/step3/",
    REGISTER_STEP31 = "/account/register/step31/",
    REGISTER_STEP4 = "/account/register/step4/",
    KYC = "/account/kyc/",
    EXPLORE_LANDING = "/explore/",
    EVENT_DETAIL = "/event/",
    TICKET = "/ticket/",
    PROFILE_USER = "/profile/",
    PROFILE_USER_ID = "/profile/user/",
    PROFILE_MY_MONEY = "/profile/me/mymoney/",
    PROFILE_ACCOUNTS = "/profile/me/accounts/",
    HELP_CENTER = "/profile/helpcenter/",
    FORGOT_PASSWORD = "/account/forgot_password/",
    RESET_PASSWORD = "/account/reset/",
    BUY_TICKET = "/event/buy_ticket/",
    CREATE_EVENT = "/create_event/",
    UPDATE_EVENT = "/create_event/edit/",
    CREATE_RESELL_EVENT = "/create_resell_event/",
    CREATE_RESELL_EVENT_EDIT = "/create_resell_event/edit/",
    RESELL_TICKET_DETAIL = "/resell_ticket/",
}