import ButtonFrame from "../ButtonFrame";
import { useNavigate } from "react-router-dom";
import { setUpPrevUrl } from "../../action/generalFunc";
import { UrlType } from "../../constant/objects_types";

const AccountSelectModal = ({ visible, ticket_id, modalClose }:{ 
    visible:boolean,
    ticket_id: number, 
    modalClose: () => void 
}) => {
    const navigate = useNavigate();
    return (
        <>
            <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`}}>
                <div className="modalContainer" style={{overflow:'hidden'}}>
                    <div style={{position:'absolute', width:"100%", height:"100%", left:0, top:0}} onClick={modalClose}></div>
                    
                    <div className={`actionView${!visible ? " animate__animated animate__slideInRight d-none" : " animate__animated animate__zoomIn" }`} style={{height:"auto", width:"calc(100% - 80px)", borderRadius:'14px', padding:'22px 20px'}}>
                        <div className={"rateHeader"}>Account</div>
                        <div  className={"rateDesc"}>You're not logged in!, how will you like to proceed?</div>
                        <div style={{display:'flex', flexDirection:'column', gap:"15px", width:'100%', justifyContent:'space-between', marginTop:"20px"}}>
                            <ButtonFrame 
                                title="Create Account / Sign-in" contStyle={{marginTop:0, backgroundColor:"#DB012E", border:"none"}} onPress={() => {modalClose(); navigate(setUpPrevUrl(window.location.pathname, `${UrlType.LOGIN}?redirect=${UrlType.BUY_TICKET}${ticket_id}`))}}
                            />
                            <ButtonFrame 
                                title="Continue as Guest"  contStyle={{marginTop:"0px", backgroundColor:"rgba(255, 255, 255, 0.16)", border:"none"}} onPress={() => {modalClose(); navigate(setUpPrevUrl(window.location.pathname, `${UrlType.BUY_TICKET}${ticket_id}`))}}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AccountSelectModal;