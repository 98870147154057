import { UrlType } from "../../constant/objects_types";

const SignupFooter = ({ addClass, textStyle }:{ addClass?:string, textStyle?: object }) => {
    return (
        <div className={`SignupFooter ${addClass}`} style={textStyle}>
            <span>Don’t have an account?</span>

            <a href={UrlType.REGISTER_STEP1}>
                <span>Sign Up</span>
            </a>
        </div>
    )
}

export default SignupFooter;