import { useEffect, useState } from "react";
import InputField from "../InputField";
import ButtonFrame from "../ButtonFrame";
import { generalObj } from "../../constant/objects_types";
import { getLocation, getPhoneCode, ticketPrePurchase, validateEmail } from "../../action/generalFunc";
import { usePaystackPayment } from "react-paystack";
import { PaystackProps } from "react-paystack/dist/types";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import PickerModal from "./PickerModal";

const PaystackHook = ({ config, user_data, ticket, discount, loading, onSuccess, onClose }:{
    config: generalObj,
    user_data: generalObj,
    ticket: generalObj[],
    discount: generalObj | null,
    loading: (val:boolean) => void,
    onSuccess: () => void,
    onClose: () => void
}) => {
    const payConfigProp:PaystackProps = {
        email: config.email,
        publicKey: config.publicKey,
        amount: config.amount * 100,
        reference: config.reference
    } 
    const initializePayment = usePaystackPayment(payConfigProp);

    const triggerPrePurchase = async () => {
        loading(true);
        const purchased_ticket = ticket.filter((item) => item.active);
        if (!purchased_ticket.length) {
            loading(false);
            alert('No ticket selected');
            return;
        }

        const ticket_data:generalObj = {
            tx_ref: config.reference,
            quantity: purchased_ticket[0].qty,
            ticket_id: purchased_ticket[0].id,
            fullname: user_data.fullname,
            email: user_data.email,
            phone_number: user_data.phone_number
        }

        if (discount) {
            ticket_data.discount_info = discount;
        }

        const result:generalObj = await ticketPrePurchase('', ticket_data);
        loading(false);
        if (result.status) {
            initializePayment(onSuccess, onClose);
        }else {
            alert(result.message);
        }
    }

    return (
        <ButtonFrame title="Submit" contStyle={{marginTop:0, backgroundColor:"#DB012E", border:"none"}} onPress={() => triggerPrePurchase()} /> 
    );
};

const GuestUserInfoModal = ({ visible, config, ticket, discount, isLoading, modalClose, onComplete, onIncomplete }:{ 
    visible:boolean,  
    config: generalObj,
    ticket: generalObj[],
    discount: generalObj | null,
    isLoading: (val:boolean) => void,
    modalClose: () => void,  
    onComplete: (val:generalObj) => void 
    onIncomplete: (val:generalObj) => void 
}) => {
    const [formData, setFormData] = useState<generalObj>({
        fullname: "",
        email: "",
        phone_number: "",
        phone_code: {
            label:"",
            value: '',
        }
    });
    const [formError, setFormError] = useState<generalObj>({});
    const [payConfig, setPayConfig] = useState<generalObj>({});
    const [openPicker, setOpenPicker] = useState<boolean>(false);
    const [phoneCode, setPhoneCode] = useState<generalObj[]>([]);

    useEffect(() => {
        (async () => {
            const location = await getLocation();
            let pCode:generalObj[] = await getPhoneCode(location.countryCode);
            setFormData((m) => ({...m, "phone_code": {
                id:pCode[0].id,
                label:`${pCode[0].flag} ${pCode[0].phone_code}`,
                value:pCode[0].phone_code
            }}))
        })();
        setPayConfig((prevPayConfig) => ({...prevPayConfig, ...config}));
        if (config.email) {
            setFormData((prevFormData) => ({...prevFormData, email:config.email}));
        } 
        (async () => {
            let result = await getPhoneCode();
            let data:generalObj[] = [];
            result.forEach((element:generalObj) => {
                data.push({
                    id:element.id,
                    label:`${element.flag} ${element.phone_code}`,
                    value:element.phone_code
                })
            })
            setPhoneCode(data.sort((a:generalObj, b:generalObj) => {
                return a.value - b.value
            }));
        })();
    }, [config]);


    const onSubmit = () => {
        if (formData.fullname.length < 2) {
            setFormError({...formError, fullname:true, phone:false});
            return;
        };

        if (!validateEmail(formData.email)) {
            setFormError({...formError, fullname:false, email:true, phone:false});
            return;
        };

        if (formData.phone_number.length < 10) {
            setFormError({...formError, fullname:false, email:false, phone:true});
            return;
        };

        setFormError({});
        const payload = {
            fullname: formData.fullname,
            email: formData.email,
            phone_number:`${formData.phone_code.value}-${formData.phone_number}`,
        }
        onComplete(payload);
    }

    const onInSubmit = () => {
        if (formData.fullname.length < 2) {
            setFormError({...formError, fullname:true, phone:false});
            return;
        };

        if (!validateEmail(formData.email)) {
            setFormError({...formError, fullname:false, email:true, phone:false});
            return;
        };

        if (formData.phone_number.length < 10) {
            setFormError({...formError, fullname:false, email:false, phone:true});
            return;
        };

        setFormError({});
        const payload = {
            fullname: formData.fullname,
            email: formData.email,
            phone_number:`${formData.phone_code.value}-${formData.phone_number}`,
        }
        onIncomplete(payload);
    }

    return (
        <>
            <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`}}>
                <div className="modalContainer" style={{overflow:'hidden'}}>
                
                    <div className={`actionView${!visible ? " animate__animated animate__slideInRight d-none" : " animate__animated animate__slideInRight" }`} style={{height:"auto", width:"calc(100% - 80px)", borderRadius:'14px', padding:'22px 20px'}}>
                        <div className={"rateHeader"}>Guest User</div>
                        <div  className={"rateDesc"} style={{marginBottom:"16px"}}>Enter your information to buy a ticket</div>
                        <InputField id="Fullname" inputProp={{value:formData.fullname, placeholder:"Fullname", maxLength:30}} isError={formError.fullname} onTextChange={(val)=>setFormData({...formData, "fullname": val})}  customInputText={{backgroundColor:'rgba(255, 255, 255, 0.12)'}} message={formError.fullname && {text:"Name must have atleast 2 characters", style: {}}} />
                        {config.email ? (
                            <InputField id="Email" inputProp={{value:formData.email, placeholder:"Email address", disabled:true}} isError={formError.email} onTextChange={(val)=>setFormData({...formData, "email": val.replaceAll(' ', '')})}  customInputText={{backgroundColor:'rgba(255, 255, 255, 0.12)'}} message={formError.email && {text:"Invalid email address", style: {}}} /> 
                        ): (
                            <InputField id="Email" inputProp={{value:formData.email, placeholder:"Email address"}} isError={formError.email} onTextChange={(val)=>setFormData({...formData, "email": val.replaceAll(' ', '')})}  customInputText={{backgroundColor:'rgba(255, 255, 255, 0.12)'}} message={formError.email && {text:"Invalid email address", style: {}}} />
                        )}
                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"10px", marginTop:'10px'}}>
                            {/* Phone code */}
                            <div className="inputField" style={{marginTop:0, padding:0, width:"30%"}}>
                                <div className="inputTextCont" style={{flexDirection:'column', marginTop:0}}>
                                    <button className={`inputText`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:'rgba(255, 255, 255, 0.12)', paddingInline:'8px'}} onClick={()=>setOpenPicker(true)}>
                                        <div style={{color: 'rgba(255, 255, 255, 0.8)'}}>{formData.phone_code?formData.phone_code.label:''}</div>
                                        <div style={{display:'flex'}}>
                                            {openPicker ? (
                                                <IoChevronUpOutline size={"16px"} color={'#FFF'} />
                                            ):(
                                                <IoChevronDownOutline size={"16px"} color={'#FFF'} />
                                            )}
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div style={{width:"70%"}}>
                                <InputField id={`number`} inputProp={{value:formData.phone_number, type:'number', placeholder:'Input phone number'}} customInputField={{paddingTop:0, marginTop:0}} message={formError.phone && {text:"Invalid phone number", style: {}}} customInputText={{backgroundColor:'rgba(255, 255, 255, 0.12)'}} labelStyle={{color:'#FFF'}} customInputTextCont={{marginTop:0}} onTextChange={(val)=>setFormData({...formData, "phone_number": val})} />
                            </div>
                        </div> 
                        <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:"16px", gap:'5px'}}>
                            <ButtonFrame 
                                title="Cancel" contStyle={{marginTop:"0px", backgroundColor:"rgba(255, 255, 255, 0.16)", border:"none"}} onPress={modalClose}
                            />
                            {formData.fullname.length >= 2 && validateEmail(formData.email) && formData.phone_number.length >= 10 ? (
                                <>
                                    {payConfig.amount ? (
                                        <PaystackHook config={{...payConfig, email:formData.email}} user_data={formData} ticket={ticket} discount={discount} loading={(val) => isLoading(val)} onSuccess={onSubmit} onClose={() => onInSubmit()} />
                                    ) : (
                                        <ButtonFrame title="Submit" contStyle={{marginTop:0, backgroundColor:"#DB012E", borderWidth:0}} onPress={() => onSubmit()} /> 
                                    )}

                                    {/* {payConfig.amount === 0 && <ButtonFrame title="Checkout" contStyle={{marginTop: 0, backgroundColor:'#DB012E', borderWidth:0}} onPress={() => onSubmit()} /> } */}
                                </>
                            ):(
                                <ButtonFrame title="Submit" contStyle={{marginTop:0, backgroundColor:"#DB012E", border:"none", opacity:"0.4"}} onPress={() => alert('All fields required')} /> 
                            )}
                        </div>
                    </div>
                
                </div>
            </div>
            {openPicker && <PickerModal visible={openPicker} pickerItem={phoneCode} selectedValue={formData.phone_code?formData.phone_code.label:''} onChange={(val)=>setFormData({...formData, "phone_code": val})} modalClose={()=>setOpenPicker(false)} /> }
        </>
    )
}

export default GuestUserInfoModal;