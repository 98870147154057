
import { useState, useEffect } from "react";
import { generalObj } from "../../constant/objects_types";
import { getLocation, googleMapPlaces } from "../../action/generalFunc";
import InputField from "../InputField";
import { IoCloseOutline } from "react-icons/io5";
import { MdSearchOff } from "react-icons/md";
import { LocationSvg } from "../../styles/IconStyle";
import BorderDivider from "../BorderDivider";

const PlacesModal = ({ visible, currentValue, onChange, modalClose }:{
    visible:boolean,
    currentValue:string,
    modalClose: () => void,
    onChange: (val:generalObj) => void
}) => {

    const [country, setCountry] = useState<string>("");
    const [searchAddress, setSearchAddress] = useState<string>('');
    const [placesList, setPlacesList] = useState<generalObj[]>([]);

    useEffect(() => {
        if (country) return;
        const getCountry = async () => {
            const n_country = await getLocation();
            if (n_country !== null) {
                setCountry(n_country.countryCode);
            }else {
                setCountry('');
            }
        }
        getCountry();
    }, [country]);

    useEffect(() => {
        if (!visible) return;
        setSearchAddress(currentValue);
    }, [visible, currentValue]);

    useEffect(() => {
        if (searchAddress.length < 2 || !country.length) {
            setPlacesList([]);
            return;
        }
        const getSearch = async () => {
            const result:generalObj = await googleMapPlaces(searchAddress);
            //await forwardGeocoding(encodeURI(searchAddress), country);
            if (result.status) {
                setPlacesList(result.data);
            }else {
                setPlacesList([]);
            }
        }

        getSearch()
    }, [searchAddress, country]);

    const processChange = (val:generalObj) => {
        onChange({
            addressLabel: val.addressLabel,
            coordinates: ` ${val.coordinates.longitude}, ${val.coordinates.latitude}`,
            formattedAddress: val.formattedAddress
        });
        modalClose();
        setSearchAddress('');
    }

    return (
        <div className={`modalParentContainer${!visible ? " d-none" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:"9999", left:"0px"}}>
            <div className="modalContainer flexEnd" style={{overflow:'hidden', paddingBlock:"30px"}}>
                <div className="actionView animate__animated animate__slideInUp" style={{height:"100%", paddingInline:"20px", backgroundColor:"#050202"}}>
                    <div style={{textAlign:'end', width:'100%', marginBottom:"15px", paddingInline:"0px"}}>
                        <button onClick={() => {
                            setSearchAddress('');
                            modalClose();
                        }
                        }>
                            <span className="sectionTopLink">Cancel</span>
                        </button>
                    </div>
                    <div style={{margin: "20px 0px 0px 0px", position: "relative", height: "100%"}}>
                        <div style={{marginBottom:10}}>
                            <InputField 
                                id="search_Address"
                                inputProp={{value:searchAddress, placeholder:'Search Address'}} 
                                customInputField={{paddingVertical:0}} 
                                customInputTextCont={{marginTop:0}} 
                                customInputText={{backgroundColor:"rgba(255, 255, 255, 0.12)"}}
                                onTextChange={(val) => setSearchAddress(val)} 
                                isIcon={searchAddress && <IoCloseOutline size={"18px"} color="#FFF" />}
                                iconPress={() => {setSearchAddress('')}}
                            />
                        </div>
                        <div className="actionItemCont">
                            <div style={{paddingBottom: "50px", height:"100%"}}>
                            {placesList && searchAddress && (
                                placesList.length > 0 ? (placesList.map((item:generalObj, index:number) => (
                                    <div key={index}>
                                        <button style={{display:'flex', marginTop:"10px", gap:"5px"}} onClick={()=>processChange(item)}>
                                            <div style={{flexBasis:"1", display:"flex", justifyContent:'center'}}>
                                                <LocationSvg />
                                            </div>
                                            <div style={{flexBasis:"11", display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
                                                <div className="itemOwner" style={{color:'rgba(255, 255, 255, 0.8)', fontSize:"16px", textAlign:'start'}}> {item.addressLabel} </div>
                                                <div className="tabbedText" style={{lineHeight:"16px", textAlign:"left"}}> {item.formattedAddress} </div>
                                            </div>
                                        </button>
                                        {index !== placesList.length - 1 && <BorderDivider borderStyle={{marginBlock:"15px"}} />}
                                    </div>
                                ))):(
                                    <div style={{height:"100%", display:'flex', flexDirection:'column', justifyContent:'center', alignItems:"center", gap:"5px"}}>
                                        <div>
                                            <MdSearchOff size={"72px"} color="#7e7b7b" />
                                        </div>
                                        <div style={{fontSize:"14px", color:"#7e7b7b"}}>
                                            No Result Found.
                                        </div>
                                    </div>
                                ) 
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlacesModal;