import LocationSetter from "../LocationSetter";
import SearchBarButton from "../SearchBarButton";
import { useRef, useState } from "react";
import { SearchSvg, SettingIconSmallSvg } from "../../styles/IconStyle";
import { generalObj } from "../../constant/objects_types";
import { IoChevronBackOutline } from "react-icons/io5";

const ExploreHeader = ({ eventFilters, locationData, newLocation, isFiltered=false, isSubFilter=false, offSearch=false, filteredTitle, triggerSearch, openLocationModal, showDistance, onPress, goBack }:{
    eventFilters:generalObj[],
    locationData:generalObj | null,
    newLocation:string | null,
    isFiltered?:boolean,
    isSubFilter?:boolean,
    offSearch?:boolean,
    filteredTitle?:string,
    triggerSearch?: () => void;
    openLocationModal: (val:boolean) => void
    showDistance?: (val:boolean) => void,
    onPress: (item:generalObj) => void,
    goBack?: () => void,
}) => {
    const [distanceFilter, setDistanceFilter] = useState<number>(1.7);
    const [showDistanceFilter, setShowDistanceFilter] = useState<boolean>(false);
    const explore_headerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div ref={explore_headerRef}>
            {!isFiltered ? (
                <div onClick={triggerSearch}>
                    <SearchBarButton searchBarStyle={{margin:"0px 20px"}} />
                </div>
            ): (
                <div onClick={triggerSearch} style={{margin:"0px 20px 0px 20px", display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div onClick={() => goBack && goBack()} style={{display:'flex', alignItems:'center', gap:'3px'}}>
                        <IoChevronBackOutline size={"24px"} color="#FFF" />
                        <span className="sectionTitle">{filteredTitle}</span>
                    </div>
                    {!offSearch && (
                        <button>
                            <SearchSvg />
                        </button>
                    )}
                </div>
            )}
            <div style={{marginTop:"16px"}}>
                <LocationSetter locationData={newLocation ? newLocation : locationData} openModal={(val) => openLocationModal(val)} />
            </div>
            {!isFiltered && (
                <div className="sectionHeader sectionDisplay">
                    <span className="sectionTitle">Trending Events</span>
                </div>
            )}
            

            {/* Event filter */}
            <div style={{margin:"0px 20px", marginBottom:"8px", marginTop:"24px", display:'flex', gap:'13px'}}>
                <button onClick={() => {
                    showDistance && showDistance(!showDistanceFilter);
                    setShowDistanceFilter(!showDistanceFilter);
                }} className="toggleFilterItem" style={{padding:"8px 12px", backgroundColor:'#302929', border:'none', display:'flex', justifyContent:'center', alignItems:'center', marginLeft:"0px", gap:"5px"}}>
                    <div style={{display:'flex'}}>
                        <SettingIconSmallSvg />
                    </div>
                    <span className="toggleFilterItemText" style={{width:'max-content'}}>Within {distanceFilter} km</span>
                </button>
                <div style={{display:'flex', gap:'13px', overflowY:'hidden', width:`calc(${explore_headerRef ? explore_headerRef.current : "100%"} - 80px)`}}>
                    {eventFilters.map((item:generalObj, index:number) => (
                        <button key={index} className={`toggleFilterItem ${item.active && "toggleFilterItemActive"}`} style={{padding:"8px 12px", backgroundColor:isSubFilter && !item.active?'transparent':'#302929', border:isSubFilter && !item.active?"1px solid #FFF":"none", display:item.show ? 'flex' : 'none', marginLeft:"0px"}} onClick={()=>onPress(item)}>
                            <span className="toggleFilterItemText" style={{width:'max-content'}}>{item.name}</span>
                        </button>
                    ))}
                </div>
            </div>

            {/* distance search location */}
            {showDistanceFilter && (
            <div style={{margin:"0px 20px", marginBottom:"5px"}}>
                <div style={{position:'relative'}}>
                    <input className="slider" type="range" min={"0"} max={"25"} value={distanceFilter} step={"0.1"} onChange={(val) => setDistanceFilter(parseFloat(val.target.value))} />
                    <span className="slider_track" style={{width:`${Math.ceil(((distanceFilter) / 25) * 100)}%`}}></span>
                </div>
                <div style={{marginTop:"15px", position:'relative'}}>
                    <div style={{padding:"6px", borderRadius:"8px", backgroundColor:'#1D1716', display:'flex', alignItems:'center', gap:'1px', marginLeft:"auto", width:'fit-content'}}>
                        <input type="number" value={distanceFilter.toLocaleString()} className="locationRegion" style={{fontSize:13, lineHeight:0, backgroundColor:'transparent', width:`${(distanceFilter.toLocaleString().length + 1) * 6}px`, textAlign:'right'}} onChange={(e)=> {
                            const val = e.target.value;
                            if (val === '' || parseFloat(val) <= 0) {
                                setDistanceFilter(0);
                            }else if (parseFloat(val) >= 25) {
                                setDistanceFilter(25);
                            }else {
                                setDistanceFilter(parseFloat(val));
                            }
                        }} />
                        <span className="locationRegion" style={{fontSize:"16px", lineHeight:"0px"}}> km</span>
                    </div>
                </div>
            </div>
            )}
        </div>
    )
}

export default ExploreHeader;