
const FooterAction = ({ children, contStyle }:{
    children:JSX.Element,
    contStyle?:object,
}) => {
    return (
        <div className="bgMain" style={{width:"100%",position:'absolute',bottom:0, padding: "32px 20px", borderTop:"1px solid rgba(255, 255, 255, 0.12)", ...contStyle}}>
            {children}
        </div>
    )
}

export default FooterAction;