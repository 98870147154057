import { IoCloseOutline, IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { generalObj } from "../../constant/objects_types";
import ButtonFrame from "../ButtonFrame";
import InputField from "../InputField";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator, RootState } from "../../redux";
import BackArrow from "../BackArrow";
import { useOutletContext } from "react-router-dom";

const ProfileEdit = ({ visible, item, modalClose, onTextChange }:{
    visible:boolean,
    item:generalObj,
    modalClose: () => void,
    onTextChange: (val:string) => void
}) => {

    const account = useSelector((state:RootState) => state.account);
    const dispatch = useDispatch();
    const { updateProfile } = bindActionCreators(actionCreator, dispatch);

    const [dataInput, setDataInput] = useState<string>('');
    const [isPassword, setIsPassword] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    const { setModalLoading }:{ setModalLoading:Function } = useOutletContext();


    useEffect(() => {
        if (!item) return;
        setDataInput(item.value);
        if (item.secureEntry) {
            setIsPassword(true);
        }
    }, [item]);

    const saveInput = async () => {
        if (!account) return;

        let socialL = ['website', 'instagram', 'twitter', 'youtube', 'snapchat', 'tiktok']
        setModalLoading(true);
        const result:any = await updateProfile(account.token, item.key, dataInput, socialL.includes(item.key) ? true : false);
        if (result) {
            onTextChange(dataInput);
        }
        setModalLoading(false);
    }

    const updateInput = (val:string) => {
        let value = val;
        if (item.valueNoSpace) {
            value = value.toString().replace(" ", "");
        }

        if (item.prefixText && !value.toString().startsWith(item.prefixText)) {
            value = item.prefixText;
        }

        setDataInput(value);
    }
    
    return (
        <>
            <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" : " animate__animated animate__slideOutRight" }`} style={{backgroundColor:"#0D0404"}}>
                <div className="modalContainer" style={{alignItems:'flex-start'}}>
                    <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                        <div style={{display:'flex', gap:"0px", alignItems:'center', width:'100%', marginTop:"20px"}}>
                            <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                            <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>{item.title}</div>
                        </div>
                        <div style={{flexDirection:'row'}}>
                            <div style={{width:'100%'}}>
                                <label style={{color: 'rgba(255, 255, 255, 0.6)', fontSize: "14px", fontFamily: '400', lineHeight: "19px",marginTop: "16px",textAlign: 'left'}}>{item.descriptiveText}</label>
                                <InputField 
                                    id={`input_1`} 
                                    refInput={(input) => inputRef.current = input}
                                    customInputField={{marginTop:"24px"}} 
                                    customClassInputText={"inputTextEdit"}  
                                    multiline={item.multiline}
                                    customInputText={{minHeight:item.multiline?"100px":"40px", maxHeight:item.multiline?"150px":"40px", width:"100%"}} 
                                    inputProp={!item.secureEntry ? {value:dataInput, ...item.props} :{value:dataInput, type: isPassword ? "password" : "text", ...item.props}} 
                                    isIcon={item.secureEntry ? isPassword ? <IoEyeOffOutline size={"24px"} color="rgba(255, 255, 255, 0.6)" /> : <IoEyeOutline size={"24px"} color="rgba(255, 255, 255, 0.6)" /> : <IoCloseOutline size={"24px"} color="rgba(255, 255, 255, 0.6)" />}  
                                    iconPress={()=>{
                                        if (item.secureEntry) {
                                            setIsPassword(!isPassword);
                                        }else {
                                            updateInput('');
                                        }
                                    }}
                                    onTextChange={(val) => updateInput(val)}
                                />
                                <ButtonFrame title={'Save'} contStyle={{backgroundColor:'#DB012E',border:"none",marginTop:"24px"}} onPress={saveInput} />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default ProfileEdit;