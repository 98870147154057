import { useState } from "react"
import { getDateStr, getTimeStr } from "../../action/generalFunc"
import { SYMBOL } from "../../constant/constant"
import { generalObj } from "../../constant/objects_types"
import { DateIconSvg, PriceTagSmallSvg, TimeIconSvg } from "../../styles/IconStyle"

const ResellTicketInfo = ({event}:{
    event: generalObj
}) => {
    const [seeMore, setSeeMore] = useState<boolean>(false)
    return (
        <>
            {/* Date time display */}
            <div className={"postOwner"} style={{gap:"24px"}}>
                <div style={{display:'flex', alignItems:'center', gap:"6px"}}>
                    <div>
                        <span style={{display:"inline-flex", width:"18px", height:"18px", backgroundColor:"#302929", borderRadius:"50%", padding:"4px", justifyContent:"center", alignItems:"center"}}>
                            <DateIconSvg />
                        </span>
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <span className='postOwnerTextl' style={{fontSize:13, color:"#9E9B9B"}}>Date</span>
                        <span className='postOwnerTextl' style={{fontSize:13}}>{getDateStr(`${event.start_date}`)}</span>
                    </div>
                </div>
                <div style={{display:'flex', alignItems:'center', gap:"6px"}}>
                    <div>
                        <span style={{display:"inline-flex", width:"18px", height:"18px", backgroundColor:"#302929", borderRadius:"50%", padding:"4px", justifyContent:"center", alignItems:"center"}}>
                            <TimeIconSvg />
                        </span>
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <span className='postOwnerTextl' style={{fontSize:13, color:"#9E9B9B"}}>Time</span>
                        <span className='postOwnerTextl' style={{fontSize:13}}>{getTimeStr(`${event.start_date}`)}</span>
                    </div>
                </div>
            </div>

            <div style={{marginTop:"16px", display:'flex', alignItems:'center', gap:"6px"}}>
                <div>
                    <span style={{display:"inline-flex", width:"18px", height:"18px", backgroundColor:"#302929", borderRadius:"50%", padding:"4px", justifyContent:"center", alignItems:"center"}}>
                        <PriceTagSmallSvg />
                    </span>
                </div>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <span className='postOwnerTextl' style={{fontSize:13, color:"#9E9B9B"}}>Price</span>
                    <span className='postOwnerTextl' style={{fontSize:13}}>{`${SYMBOL}${parseFloat(`${event.price}`).toLocaleString()}`}</span>
                </div>
            </div>

            {/* Description */}
            <div style={{marginTop:"16px"}}>
                <span className='inputLabel' style={{fontSize:"13px"}}>Description</span>
                {event && event.information.length <= 100? (
                    <p className="itemOwner" style={{marginTop:"5px", fontSize:"14px", lineHeight:"16px", fontWeight:'400'}}>
                        {event.information}
                    </p>
                ): (
                    <>
                        <p className='itemOwner' style={{marginTop:"5px", fontSize:"14px", lineHeight:"16px", fontFamily:"400"}}>
                            {!seeMore ? `${event.information.slice(0, 100)}...` : event.information}
                        </p>
                        <button className="postOwnerTextl" style={{marginTop:"5px", display:'flex', alignSelf:'center', fontSize:"14px", color:'rgba(225, 225, 225, 0.6)'}} onClick={()=>setSeeMore(!seeMore)}>
                            <span>{!seeMore?"Show more":"Show less"}</span>
                        </button>
                    </>
                )}
            </div>
        </>
    )
}

export default ResellTicketInfo;