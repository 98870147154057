import { useState, useEffect } from "react";
import { PopUpCheckSvg } from "../../styles/IconStyle";

const PopAlert = ({ visible, description, modalClose }:{
    visible:boolean,
    description: string,
    modalClose:() => void
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    
    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!visible) return;
        const timeOut = setTimeout(() => {
            modalClose();
        }, 1000);
        return () => clearTimeout(timeOut);
    }, [visible, modalClose]);

    return (
        <>
            {firstShow && (
                <div className={`modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`, left:"0px"}}>
                    <div className="modalContainer" style={{overflow:'hidden'}}>

                    <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                    <div className={`actionView moneyInfo${!visible ? " animate__animated animate__slideOut" : " animate__animated animate__zoomIn" }`} style={{height:"auto", width:"calc(100% - 200px)", borderRadius:'14px', padding:'22px 24px', display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <span style={{}}>
                            <PopUpCheckSvg />
                        </span>
                        <span className="inputLabel" style={{color:"#9E9B9B", marginTop:"16px"}}>{description}</span>
                    </div>
                    
                    </div>
                </div>
            )}
        </>
    )
}

export default PopAlert;