import { QrScanner } from '@yudiel/react-qr-scanner';
import { useState, useEffect } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { QR_URL } from '../../constant/constant';
import { scanTicketApi } from '../../action/generalFunc';
import { generalObj } from '../../constant/objects_types';
import ModalLoader from './ModalLoader';

const BarcodeScannerModal = ({ visible, modalClose }:{
    visible: boolean,
    modalClose: () => void,
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [showReader, setShowReader] = useState<boolean>(true);
    const [modalLoader, setModalLoader] = useState<boolean>(false);
    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
        setShowReader(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!visible) return;
        setShowReader(true);
    }, [visible]);

    const scanTicket = async (result:string) => {
        if (!account) return;
        if (!result.startsWith(QR_URL)) {
            alert('Invalid ticket');
            return;
        }
        setModalLoader(true);
        setShowReader(false);
        const ticketId = result.replaceAll(QR_URL, "");
        const scan_result:generalObj = await scanTicketApi(account.token, ticketId);
        if (scan_result.status) {
            if (scan_result.data.data.ticket_purchase.used) {
                alert("User already checked in");
            }else {
                alert("User checked in");
            }
        }else {
            alert(scan_result.message);
        }
        setModalLoader(false);
        setShowReader(true);
    }

    return (
        <>
            {firstShow && (
                <>
                <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                    <div className="modalContainer" style={{}}>
                        <div style={{margin:"0px 20px", width:'100%', height:'100%', position:'relative'}}>
                            <div style={{height:'100%', marginTop:"20px"}}>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                    <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Scan Event QR Code</div>
                                    <div style={{display:'flex'}} onClick={() => {setShowReader(false); modalClose()}}>
                                        <IoCloseOutline size={24} color={"#FFF"} />
                                    </div>
                                </div>
                                <div style={{display:"flex", alignItems:'center', height:"100%"}}>
                                    {showReader && (
                                        <QrScanner 
                                            onDecode={(result) => scanTicket(result)}
                                            onError={(error) => console.log(error?.message)}
                                        />
                                        
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}
            <ModalLoader visible={modalLoader} />
        </>
    )
}

export default BarcodeScannerModal;