import { generalObj } from "../constant/objects_types"

const LocationSetter = ({ locationData, openModal }:{
    locationData:generalObj | string | null,
    openModal: (val:boolean) => void
}) => {
    return (
        <div className='locationIndicator' style={{marginBottom:"20px", alignItems:'baseline'}}>
            <span className='locationRegion'>Region:</span>
            <span style={{display:'flex', alignItems:'flex-start'}}>
                <button className="locationRegionText" onClick={() => openModal(true)}><span>{locationData? typeof(locationData) === 'string' ? locationData : locationData.city : 'Lagos'}</span></button>
            </span>
        </div>
    )
}

export default LocationSetter;