import { useCallback, useEffect, useRef, useState } from "react";
import { generalObj } from "../../constant/objects_types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import InputField from "../InputField";
import { IoChevronDownOutline, IoChevronUpOutline, IoClose } from "react-icons/io5";
import PickerModal from "../modal/PickerModal";
import MapSelectorModal from "../modal/MapSelectorModal";
import PlacesModal from "../modal/PlacesModal";
import { CalendarLightSvg, RemoveSvg } from "../../styles/IconStyle";
import DateTimePickerModal from "../modal/DateTimePickerModal";
import { fetchEventSubCategory, fetchEventType } from "../../action/generalFunc";

const NewEvent = ({ item, editting, isError, onChange, scrollRef }:{
    item: generalObj,
    editting?: boolean,
    isError:generalObj,
    onChange: (name:string, val:any) => void,
    scrollRef: generalObj | null
}) => {

    const [eventType, setEventType] = useState<generalObj[]>([
        {
            id:'00',
            label:'--Please select--',
            value:''
        }
    ]);
    const [eventSubType, setEventSubType] = useState<generalObj[]>([
        {
            id:'00',
            parent_id: "00",
            label:'--Please select--',
            value:'',
            show:true
        }
    ]);
    const recurringDur:generalObj[] = [
        {
            id:'00',
            label:'--Please Select--',
            value:''
        },
        {
            id:'1',
            label:'Daily',
            value:'Daily'
        },{
            id:'2',
            label:'Weekly',
            value:'Weekly'
        },{
            id:'3',
            label:'Monthly',
            value:'Monthly'
        },{
            id:'4',
            label:'Quarterly',
            value:'Quarterly'
        },{
            id:'5',
            label:'Yearly',
            value:'Yearly'
        }
    ]
    const [openPicker, setOpenPicker] = useState<boolean>(false);
    const [openSubPicker, setOpenSubPicker] = useState<boolean>(false);
    const [openrecPicker, setOpenRecPicker] = useState<boolean>(false);
    const [openPlaces, setOpenPlaces] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<Date>(new Date());
    const startMinDate = new Date();
    const [fstartDate, setFStartDate] = useState<string | null>(null);
    const [fstartTime, setFStartTime] = useState<generalObj>({
        value: null,
        format: ''
    });
    const [openStart, setOpenStart] = useState<boolean>(false);
    const [openStartTime, setOpenStartTime] = useState<boolean>(false);    
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [endMinDate, setEndMinDate] = useState<Date>(new Date());
    const [fendDate, setFEndDate] = useState<string | null>(null);
    const [fendTime, setFEndTime] = useState<generalObj>({
        value: null,
        format: ''
    });
    const [openEnd, setOpenEnd] = useState<boolean>(false);
    const [openEndTime, setOpenEndTime] = useState<boolean>(false);

    const [startODate, setStartODate] = useState<Date>(new Date());
    const startOMinDate = new Date();
    const [fstartODate, setFStartODate] = useState<string | null>(null);
    const [fstartOTime, setFStartOTime] = useState<generalObj>({
        value: null,
        format: ''
    });
    const [openOStart, setOpenOStart] = useState<boolean>(false);
    const [openOStartTime, setOpenOStartTime] = useState<boolean>(false);    
    const [endODate, setEndODate] = useState<Date>(new Date());
    const [endOMinDate, setEndOMinDate] = useState<Date>(new Date());
    const [fendODate, setFEndODate] = useState<string | null>(null);
    const [fendOTime, setFEndOTime] = useState<generalObj>({
        value: null,
        format: ''
    });
    const [openOEnd, setOpenOEnd] = useState<boolean>(false);
    const [openOEndTime, setOpenOEndTime] = useState<boolean>(false);

    const [tagSuggestActive, setTagSuggestActive] = useState<boolean>(false);
    const [tagSuggest, setTagSuggest] = useState<Array<string>>([]);
    const [editLoaded, setEditLoaded] = useState<boolean>(false);
    const [tagText, setTagText] = useState<string>('');
    const [autoIncrement, setAutoIncrement] = useState<number>(0);
    const descRef = useRef<HTMLTextAreaElement | null>(null);

    const [openMaps, setOpenMaps] = useState<boolean>(false);


    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        
        const fetchevtSubType = async () => {
            const result:generalObj = await fetchEventSubCategory(account.token);
            if (result.status) {
                let evtSubType:generalObj[] = [
                    {
                        id:'00',
                        label:'--Please select--',
                        value:'',
                        show: true
                    }
                ];
                result.data.data.forEach((element:generalObj) => {
                    evtSubType.push({
                        id: `${element.id}`,
                        parent_id: `${element.event_type_id}`,
                        label: element.name,
                        value: element.name,
                        show: false
                    });
                });
                setEventSubType([...evtSubType]);
            }else {
                alert(result.message);
            }
        }
        const fetchevtType = async () => {
            const result:generalObj = await fetchEventType(account.token);
            if (result.status) {
                let evtType = [
                    {
                        id:'00',
                        label:'--Please select--',
                        value:''
                    }
                ];
                result.data.data.forEach((element:generalObj) => {
                    evtType.push({
                        id: `${element.id}`,
                        label: element.name,
                        value: element.name
                    });
                });
                setEventType([...evtType]);
            }else {
                alert(result.message);
            }
        }

        fetchevtType();
        fetchevtSubType();
    }, [account]);

    const displayDate = useCallback((selectedDate:Date, fieldName:string, sendBack:boolean=true) => {
        const currentDate = selectedDate;
        let fDate = `${currentDate.getDate()} / ${currentDate.getMonth() + 1} / ${currentDate.getFullYear()}`;
        let hour = '';
        if (currentDate.getHours() > 12) {
            hour = `${currentDate.getHours() - 12}`;
        }else {
            if (currentDate.getHours() < 10) {
                hour = `0${currentDate.getHours()}`;
            }else {
                hour = `${currentDate.getHours()}`
            }
        }

        let fTime = `${hour} : ${currentDate.getMinutes() < 10?`0${currentDate.getMinutes()}`: currentDate.getMinutes()} : ${currentDate.getSeconds()< 10?`0${currentDate.getSeconds()}`: currentDate.getSeconds()}`;
        setFStartDate(fDate);
        setFStartTime({
            value: fTime,
            format: currentDate.getHours() <= 11 ? 'AM' : 'PM'
        });
        setStartDate(new Date(currentDate));
        setEndMinDate(new Date(currentDate));
        if (sendBack) {
            onChange('date', {
                oneTime: fieldName === 'oneTime'? true: false,
                recurring: fieldName === 'recurring'? true: false,
            });
            onChange(fieldName, {
                startDate: currentDate,
                endDate: '' //endDate ? endDate : ''
            });
        }
    }, [onChange])
    
    const displayEndDate = useCallback((selectedDate:Date, fieldName:string, sendBack:boolean=true) => {
        const currentDate = selectedDate;
        
        let fDate = `${currentDate.getDate()} / ${currentDate.getMonth() + 1} / ${currentDate.getFullYear()}`;
        let hour = '';
        if (currentDate.getHours() > 12) {
            hour = `${currentDate.getHours() - 12}`;
        }else {
            if (currentDate.getHours() < 10) {
                hour = `0${currentDate.getHours()}`;
            }else {
                hour = `${currentDate.getHours()}`
            }
        }
        
        let fTime = `${hour} : ${currentDate.getMinutes() < 10?`0${currentDate.getMinutes()}`: currentDate.getMinutes()} : ${currentDate.getSeconds()< 10?`0${currentDate.getSeconds()}`: currentDate.getSeconds()}`;
        setFEndDate(fDate);
        setFEndTime({
            value: fTime,
            format: currentDate.getHours() <= 11 ? 'AM' : 'PM'
        });
        setEndDate(new Date(currentDate));
        if (sendBack) {
            onChange('date', {
                oneTime: fieldName === 'oneTime'? true: false,
                recurring: fieldName === 'recurring'? true: false,
            });
            onChange(fieldName, {
                startDate: startDate,
                endDate: currentDate
            });
        }
    }, [onChange, startDate]);

    const displayODate = useCallback((selectedDate:Date, fieldName:string, sendBack:boolean=true) => {
        const currentDate = selectedDate;
        let fDate = `${currentDate.getDate()} / ${currentDate.getMonth() + 1} / ${currentDate.getFullYear()}`;
        let hour = '';
        if (currentDate.getHours() > 12) {
            hour = `${currentDate.getHours() - 12}`;
        }else {
            if (currentDate.getHours() < 10) {
                hour = `0${currentDate.getHours()}`;
            }else {
                hour = `${currentDate.getHours()}`
            }
        }
        
        let fTime = `${hour} : ${currentDate.getMinutes() < 10?`0${currentDate.getMinutes()}`: currentDate.getMinutes()} : ${currentDate.getSeconds()< 10?`0${currentDate.getSeconds()}`: currentDate.getSeconds()}`;
        setFStartODate(fDate);
        setFStartOTime({
            value: fTime,
            format: currentDate.getHours() <= 11 ? 'AM' : 'PM'
        });
        setStartODate(new Date(currentDate));
        setEndOMinDate(new Date(currentDate));
        if (sendBack) {
            onChange('date', {
                oneTime: fieldName === 'oneTime'? true: false,
                recurring: fieldName === 'recurring'? true: false,
            });
            onChange(fieldName, {
                startDate: currentDate,
                endDate: '',
                occurence: item.fields.recurring.occurence,
                withEndDate: true,
                withEndOccurence: false,
                endOccurence:''
            });
        }
    }, [onChange, item]);

    const displayOEndDate = useCallback((selectedDate:Date, fieldName:string, sendBack:boolean=true) => {
        const currentDate = selectedDate;
        let fDate = `${currentDate.getDate()} / ${currentDate.getMonth() + 1} / ${currentDate.getFullYear()}`;
        let hour = '';
        if (currentDate.getHours() > 12) {
            hour = `${currentDate.getHours() - 12}`;
        }else {
            if (currentDate.getHours() < 10) {
                hour = `0${currentDate.getHours()}`;
            }else {
                hour = `${currentDate.getHours()}`
            }
        }
        
        let fTime = `${hour} : ${currentDate.getMinutes() < 10?`0${currentDate.getMinutes()}`: currentDate.getMinutes()} : ${currentDate.getSeconds()< 10?`0${currentDate.getSeconds()}`: currentDate.getSeconds()}`;
        setFEndODate(fDate);
        setFEndOTime({
            value: fTime,
            format: currentDate.getHours() <= 11 ? 'AM' : 'PM'
        });
        setEndODate(new Date(currentDate));
        if (sendBack) {
            onChange('date', {
                oneTime: fieldName === 'oneTime'? true: false,
                recurring: fieldName === 'recurring'? true: false,
            });
            onChange(fieldName, {
                startDate: startDate,
                endDate: currentDate,
                occurence:item.fields.recurring.occurence,
                withEndDate: true,
                withEndOccurence: false,
                endOccurence:''
            });
        }
    }, [onChange, item, startDate])

    const updateEventType = useCallback((val:generalObj) => {
        setEventSubType((prevEventSubType) => (
            prevEventSubType.filter((item:generalObj) => {
                if (item.parent_id === val.id) {
                    item.show = true;
                }else if (item.id === "00") {
                    item.show = true;
                }else {
                    item.show = false;
                }
                return item;
            })
        ));
        onChange('eventType', val);
    }, [onChange]);

    useEffect(() => {
        if (!item) return;
        setTagSuggest([...item.fields.tags]);
    }, [item]);


    useEffect(() => {
        if (!item || !editting || editLoaded) return;
        if (item.fields.date.oneTime) {
            displayDate(new Date(`${item.fields.oneTime.startDate}`), 'oneTime', false);
            displayEndDate(new Date(item.fields.oneTime.endDate), 'oneTime', false);
        }
        if (item.fields.date.recurring) {
            displayODate(new Date(`${item.fields.recurring.startDate}`), "recurring", false);
            if (item.fields.date.recurring.withEndDate) {
                displayOEndDate(new Date(`${item.fields.recurring.endDate}`), "recurring", false);
            }
        }
        updateEventType(item.fields.eventType);
        setEditLoaded(true);
    }, [item, editting, editLoaded, displayDate, updateEventType, displayEndDate, displayODate, displayOEndDate]);
    
    const submitTag = (evt:generalObj) => {
        if (tagText.length <= 1 || !scrollRef || evt.key !== "Enter") return;
        const get_existing = tagSuggest.filter(item => item.toLowerCase() === tagText.toLowerCase());
        if (get_existing.length >= 1) return;

        setTagSuggest([tagText, ...tagSuggest]);
        tagInputChange('');
        onChange('tags', [tagText, ...tagSuggest]);
        setTagText('');
        // scrollRef.current;
    }

    const tagInputChange = (val:string) => {
        const new_val = val.toString().replace(" ", "")
        setTagText(new_val);
        if (new_val.length >= 2) {
            setTagSuggestActive(false);
        }else {
            setTagSuggestActive(false);
        }
    }

    const removeTag = (val:string) => {
        const newSuggest = tagSuggest.filter(item => item.toLowerCase() !== val.toLowerCase());
        setTagSuggest([...newSuggest])
    }

    return (
        <>
        <div>
            {/* Name of Event */}
            <InputField id="name" label='Name of Event' inputProp={{value:item.fields.name, maxLength:30}} isError={isError.name} customInputField={styles.contSpace} labelStyle={styles.labelStyle} onTextChange={(val) => onChange('name', val)} message={isError.name && {text:"Name must have atleast 5 characters", style: {}}} />
            
            {/* Description */}
            <InputField id="desc" refInput={(input) => descRef.current=input} label='Brief Description' multiline={true} inputProp={{value:item.fields.description, maxLength:250}} isError={isError.description} customInputText={{lineHeight:"20px", height:`${autoIncrement === 0 ? "auto" : `${autoIncrement}px`}`, minHeight:"40px", maxHeight:"100px"}} customInputField={styles.contSpace} labelStyle={styles.labelStyle} onTextChange={(val) => {
                if (descRef.current) {
                    let desc = descRef.current.clientHeight;
                    descRef.current.onkeydown = (ev) =>{
                        if (ev.key === "Backspace") {
                            setAutoIncrement(desc - 36);
                        }
                        if (ev.key === "Enter") {
                            setAutoIncrement(desc);
                        }
                    }
                }
                onChange('description', val)
            }} message={isError.description && {text:"Description must have atleast 20 characters", style: {}}} />
            
            {/* Type of Event */}
            <div className="inputField" style={styles.contSpace}>
                <div className="inputLabel" style={styles.labelStyle}>Event Type</div>
                <div className="inputTextCont" style={{flexDirection:'column'}}>
                    <button className={`inputText ${isError.eventType ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}} onClick={()=>setOpenPicker(true)}>
                        <div style={styles.activeText}>{item.fields.eventType?item.fields.eventType.label:'--Please select--'}</div>
                        <div style={{display:'flex'}}>
                            {openPicker ? (
                                <IoChevronUpOutline size={"16px"} color={'#FFF'} />
                            ):(
                                <IoChevronDownOutline size={"16px"} color={'#FFF'} />
                            )}
                        </div>
                    </button>
                    {isError.eventType && (
                        <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                            Please select an event type.
                        </span>
                    )}
                    
                </div>
            </div>

            {/* Type of Sub Event */}
            <div className="inputField" style={styles.contSpace}>
                <div className="inputLabel" style={styles.labelStyle}>Sub Category</div>
                <div className="inputTextCont" style={{flexDirection:'column'}}>
                    <button className={`inputText ${isError.eventSub ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}} onClick={()=>setOpenSubPicker(true)}>
                        <div style={styles.activeText}>{item.fields.eventSub?item.fields.eventSub.label:'--Please select--'}</div>
                        <div style={{display:'flex'}}>
                            {openPicker ? (
                                <IoChevronUpOutline size={"16px"} color={'#FFF'} />
                            ):(
                                <IoChevronDownOutline size={"16px"} color={'#FFF'} />
                            )}
                        </div>
                    </button>
                    {isError.eventSub && (
                        <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                            Please select an event Sub Category.
                        </span>
                    )}
                    
                </div>
            </div>
            
            
            {/* Location */}
            <div className="inputField" style={styles.contSpace}>
                <div className="inputLabel" style={styles.labelStyle}>Location</div>
                <div style={{display:'flex', marginTop:"10px", gap:"4px"}}>
                    <button className={`autoBtn greyAutoBtn ${item.fields.location.online ? "fillAutoBtn" : "" }`} style={{opacity:item.fields.location.online ? "1":"0.6"}} onClick={() => onChange('location', {
                        online: true,
                        venue: false
                    })}>
                        <span className="autoBtnText">Online</span>
                    </button>
                    <button className={`autoBtn greyAutoBtn ${item.fields.location.venue ? "fillAutoBtn" : "" }`} style={{opacity:item.fields.location.venue ? "1":"0.6"}} onClick={() => onChange('location', {
                        online: false,
                        venue: true
                    })}>
                        <span className="autoBtnText">Venue</span>
                    </button>
                </div>
                
                <div className="inputTextCont" style={{marginTop:"10px", flexDirection:'column'}}>
                    {item.fields.location.online && (
                        <InputField id="inputLink" inputProp={{value:item.fields.online_location_link, placeholder:'Input Link', type:'url'}} isError={isError.location_link} customInputField={{marginTop:0, paddingVertical:0}} customInputTextCont={{marginTop:"0px", marginBottom:"0px"}} labelStyle={styles.labelStyle} onTextChange={(val) => onChange('online_location_link', val)} message={isError.location_link && {text:"Please input a valid link.", style: {}}} />
                    )}
                    {item.fields.location.venue && (
                        <>
                        <button className={`inputText ${isError.location_address ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}} onClick={()=>setOpenPlaces(true)}>
                            <div style={{width:"90%", textAlign:'start'}}><div style={item.fields.venue_location.addressLabel ? {...styles.activeText, ...styles.locationStlye} : {color:"rgba(255, 255, 255, 0.3)", textAlign:'start', ...styles.locationStlye}}>{item.fields.venue_location.addressLabel ? item.fields.venue_location.addressLabel : "Enter Venue"}</div></div>
                            <div style={{display:'flex'}}>
                                {openPlaces ? (
                                    <IoChevronUpOutline size={"16px"} color={'#FFF'} />
                                ):(
                                    <IoChevronDownOutline size={"16px"} color={'#FFF'} />
                                )}
                            </div>
                        </button>
                        {isError.location_address && (
                            <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end', marginTop:"5px"}}>
                                Please select event location.
                            </span>
                        )}
                        {item.fields.venue_location.addressLabel && (
                            <>
                                <div style={{display:'flex', justifyContent:'flex-end'}}>
                                    <button onClick={()=>setOpenMaps(true)} style={{marginTop:"5px"}}>
                                        <span className="locationRegionText bodyText">View map</span>
                                    </button>
                                </div>
                            </>
                        )}
                        </>
                    )}
                </div>
            </div>
            {openPlaces && <PlacesModal visible={openPlaces} modalClose={()=>setOpenPlaces(false)} currentValue={item.fields.venue_location.addressLabel} onChange={(val) => onChange('venue_location', val)} /> }
            {openMaps && <MapSelectorModal visible={openMaps} data={item.fields.venue_location} modalClose={()=>setOpenMaps(false)} /> }
            
            {/* Visibility */}
            <div className="inputField" style={styles.contSpace}>
                <div className="inputLabel" style={styles.labelStyle}>Visibility</div>
                <div style={{display:'flex', marginTop:"10px", gap:"4px", alignItems:'center'}}>
                    <button className={`autoBtn greyAutoBtn ${item.fields.visibility.public ? "fillAutoBtn" : ""}`} style={{opacity:item.fields.visibility.public ? "1":"0.6"}} onClick={() => onChange('visibility', {
                        public: true,
                        private: false
                    })}><span className="autoBtnText">Public</span></button>
                    <button className={`autoBtn greyAutoBtn ${item.fields.visibility.private ? "fillAutoBtn" : ""}`} style={{opacity:item.fields.visibility.private ? "1":"0.6"}} onClick={() => onChange('visibility', {
                        public: false,
                        private: true
                    })}><span className="autoBtnText">Private</span></button>
                </div>
            </div>

            {/* Date Time */}
            <div className="inputField" style={styles.contSpace}>
                <div className="inputLabel" style={styles.labelStyle}>Date</div>
                <div style={{display:'flex', marginTop:"10px", gap:"4px", alignItems:'center'}}>
                    <button className={`autoBtn greyAutoBtn ${item.fields.date.oneTime ? "fillAutoBtn" : ""}`} style={{opacity:item.fields.date.oneTime ? "1":"0.6"}} onClick={() => onChange('date', {
                        oneTime: true,
                        recurring: false
                    })}><span className="autoBtnText">One-time</span></button>
                    <button className={`autoBtn greyAutoBtn ${item.fields.date.recurring ? "fillAutoBtn" : ""}`} style={{opacity:item.fields.date.recurring ? "1":"0.6"}} onClick={() => onChange('date', {
                        oneTime: false,
                        recurring: true
                    })}><span className="autoBtnText">Recurring</span></button>
                </div>
                <div style={{marginTop:"10px"}}>
                    {item.fields.date.oneTime && (
                        <>
                            <div style={{display:'flex', gap:"8px", height:"40px", alignItems:'center'}}>
                                <button className={`inputText ${isError.onceStartDate ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}} onClick={()=>setOpenStart(true)}>
                                    <div style={styles.dateText}>{fstartDate ?fstartDate: 'Start Date'}</div>
                                    <div>
                                        <CalendarLightSvg />
                                    </div>
                                </button>
                                <div style={{marginInline:"8px", display:'flex', justifyContent:'center'}}>
                                    <RemoveSvg />
                                </div>
                                <button className={`inputText ${isError.onceEndDate &&  "errorBorder"}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}} onClick={()=>setOpenEnd(true)}>
                                    <div style={styles.dateText}>{fendDate ? fendDate : 'End Date'}</div>
                                    <div>
                                        <CalendarLightSvg />
                                    </div>
                                </button>
                            </div>
                            {isError.onceStartDate && (
                                <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                                    Please start date & time.
                                </span>
                            )}
                            {isError.onceEndDate && (
                                <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                                    Please end date & time.
                                </span>
                            )}
                        </>
                    )}
                    {item.fields.date.recurring && (
                        <div>
                            <button className={`inputText ${isError.recurStartDate ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:"8px", height:'40px'}} onClick={()=>setOpenOStart(true)}>
                                <div style={styles.dateText}>{fstartODate ?fstartODate: 'Start Date'}</div>
                                <div>
                                    <CalendarLightSvg />
                                </div>
                            </button>
                            
                            <button className={`inputText ${isError.occurLabel ?  "errorBorder" : "" }`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'40px'}} onClick={() => setOpenRecPicker(true)}>
                                <div style={styles.dateText}>{item.fields.recurring.occurence?item.fields.recurring.occurence.label:'--Please select--'}</div>
                                <div>
                                    {openPicker ? (
                                        <IoChevronUpOutline size={"16px"} color={'#FFF'} />
                                    ):(
                                        <IoChevronDownOutline size={"16px"} color={'#FFF'} />
                                    )}
                                </div>
                            </button>

                            {isError.recurStartDate && (
                                <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                                    Please start date & time.
                                </span>
                            )}

                            {isError.occurLabel && (
                                <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                                    Please select occurence.
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {openStart && <DateTimePickerModal visible={openStart} date={startDate} mode={'date'} onChange={(val) => val && displayDate(val, 'oneTime')} minimumDate={startMinDate} modalClose={()=>setOpenStart(false)} /> }
            {openEnd && <DateTimePickerModal visible={openEnd} date={endDate} mode={'date'} onChange={(val) => val && displayEndDate(val, 'oneTime')} minimumDate={endMinDate} modalClose={()=>setOpenEnd(false)} /> }
            
            {openrecPicker && <PickerModal visible={openrecPicker} pickerItem={recurringDur} selectedValue={item.fields.recurring.occurence?item.fields.recurring.occurence.label:'--Please select--'} onChange={(val) => onChange('recurring', {...item.fields.recurring, occurence:val})} modalClose={()=>setOpenRecPicker(false)} /> }
            {openOStart && <DateTimePickerModal visible={openOStart} date={startODate} mode={'date'} onChange={(val) => val && displayODate(val, 'recurring')} minimumDate={startOMinDate} modalClose={()=>setOpenOStart(false)} /> }
            
                    

            {/* Recurring DateTime */}
            {item.fields.date.recurring && (
                <>
                    <div className="inputField" style={styles.contSpace}>
                        <div className="inputLabel" style={styles.labelStyle}>End Event with (Optional)</div>

                        <div style={{display:'flex', marginTop:"10px", gap:"4px"}}>
                            <button className={`autoBtn greyAutoBtn ${item.fields.recurring.withEndDate ? "fillAutoBtn" : ""}`} style={{opacity:item.fields.recurring.withEndDate ? "1":"0.6"}} onClick={() => onChange('recurring', {
                                ...item.fields.recurring,
                                withEndDate: true,
                                withEndOccurence: false
                            })}>
                                <span className="autoBtnText">Date</span>
                            </button>
                            <button className={`autoBtn greyAutoBtn ${item.fields.recurring.withEndOccurence ? "fillAutoBtn" : ""}`} style={{opacity:item.fields.recurring.withEndOccurence ? "1":"0.6"}} onClick={() => onChange('recurring', {
                                ...item.fields.recurring,
                                withEndDate: false,
                                withEndOccurence: true
                            })}><span className="autoBtnText">Occurrence</span></button>
                        </div>
                        <div style={{marginTop:"10px"}}>
                            {item.fields.recurring.withEndDate && (
                                <>
                                    <div style={{display:'flex'}}>
                                        <button className={`inputText ${isError.recurEndDate ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:"40px"}} onClick={()=>setOpenOEnd(true)}>
                                            <div style={styles.dateText}>{fendODate ?fendODate : 'End Date'}</div>
                                            <div>
                                                <CalendarLightSvg />
                                            </div>
                                        </button>
                                    </div>

                                    {isError.recurEndDate && (
                                        <span className="bodyText" style={{...styles.messageText, ...styles.messageTextTwo, fontSize:"13px", alignSelf:'flex-end'}}>
                                        Please end date & time.
                                        </span>
                                    )}
                                    
                                </>
                            )}
                            {item.fields.recurring.withEndOccurence && (
                                <div>
                                    <InputField id="withEndOccurence" label='' inputProp={{value:item.fields.recurring.endOccurence, type:'number', placeholder:'Input Number'}} isError={isError.endOccur} customInputField={{marginTop:0, paddingVertical:0}} customInputTextCont={{marginTop:0, marginBottom:0}} labelStyle={styles.labelStyle} onTextChange={(val) => onChange('recurring', {
                                        ...item.fields.recurring,
                                        endOccurence: val
                                    })} message={isError.endOccur && {text:"Please enter number of occurence", style: {}}} />
                                </div>
                            )}
                        </div>
                        
                    </div>
                    {openOEnd && <DateTimePickerModal visible={openOEnd} date={endODate} mode={'date'} onChange={(val) => val && displayOEndDate(val, 'recurring')} minimumDate={endOMinDate} modalClose={()=>setOpenOEnd(false)} /> }
                </>
            )}

            {/* Time */}
            {item.fields.date.oneTime && (
                <>
                    <div className="inputField" style={styles.contSpace}>
                        <div className="inputLabel" style={styles.labelStyle}>Time</div>
                        <div className="inputTextCont">
                            <div style={{display:'flex', height:'40px', width:"100%", alignItems:'center'}}>
                                <button className={`inputText ${isError.onceStartDate ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}} onClick={()=>setOpenStartTime(true)}>
                                    <div style={styles.dateText}>{fstartTime.value ?fstartTime.value: 'Start Time'}</div>
                                    <div>
                                        <span className="sectionTitle" style={{fontSize:"13px"}}>{fstartTime.format}</span>
                                    </div>
                                </button>
                                <div style={{marginInline:"8px", display:'flex', justifyContent:'center'}}>
                                    <RemoveSvg />
                                </div>
                                <button className={`inputText ${isError.onceEndDate &&  "errorBorder"}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}} onClick={()=>setOpenEndTime(true)}>
                                    <div style={styles.dateText}>{fendTime.value ?fendTime.value : 'End Time'}</div>
                                    <div>
                                        <span className="sectionTitle" style={{fontSize:"13px"}}>{fendTime.format}</span>
                                    </div>
                                </button>
                            </div>
                            
                        </div>
                    </div>
                    {openStartTime && <DateTimePickerModal visible={openStartTime} date={startDate} mode={'time'} onChange={(val) => val && displayDate(val, 'oneTime')} modalClose={()=>setOpenStartTime(false)} pickerStyle={{height:"40%"}} /> }
                    {openEndTime && <DateTimePickerModal visible={openEndTime} date={endDate} mode={'time'} onChange={(val) => val && displayEndDate(val, 'oneTime')} minimumDate={endMinDate} modalClose={()=>setOpenEndTime(false)} pickerStyle={{height:"40%"}} /> }
                </>
            )}

            {item.fields.date.recurring && (
                <>
                    <div className="inputField" style={styles.contSpace}>
                        <div className="inputLabel" style={styles.labelStyle}>Time</div>
                        <div className="inputTextCont">
                            <div style={{display:'flex', height:'40px', width:"100%", alignItems:'center'}}>
                                <button className={`inputText ${isError.recurStartDate || isError.recurStartDate ?  "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}}onClick={()=>setOpenOStartTime(true)}>
                                    <div style={styles.dateText}>{fstartOTime.value ?fstartOTime.value: 'Start Time'}</div>
                                    <div>
                                        <span className="sectionTitle" style={{fontSize:"13px"}}>{fstartOTime.format}</span>
                                    </div>
                                </button>
                                {item.fields.recurring.withEndDate && (
                                    <>
                                        <div style={{marginInline:"8px", display:"flex", justifyContent:'center'}}>
                                            <RemoveSvg />
                                        </div>
                                        <button className={`inputText ${isError.recurEndDate ? "errorBorder" : ""}`} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}} onClick={()=>setOpenOEndTime(true)}>
                                            <div style={styles.dateText}>{fendOTime.value ?fendOTime.value : 'End Time'}</div>
                                            <div>
                                                <span className="sectionTitle" style={{fontSize:"13px"}}>{fendOTime.format}</span>
                                            </div>
                                        </button>
                                        
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {openOEndTime && <DateTimePickerModal visible={openOEndTime} date={endODate} mode={'time'} onChange={(val) => val && displayOEndDate(val, 'recurring')} minimumDate={endOMinDate} modalClose={()=>setOpenOEndTime(false)} pickerStyle={{height:"40%"}} /> }
                   {openOStartTime && <DateTimePickerModal visible={openOStartTime} date={startODate} mode={'time'} onChange={(val) => val && displayODate(val, 'recurring')} modalClose={()=>setOpenOStartTime(false)} pickerStyle={{height:"40%"}} /> }
                </>
            )}

            {/* Tags */}
            <div className="inputField" style={{...styles.contSpace, marginBottom:item.fields.date.recurring ? "20px" : "0px"}}>
                <InputField id="tags" label='Tags (Optional)' inputProp={{value:tagText, placeholder:"Hit ENTER after entering a tag"}} customInputField={{marginTop:"0px", paddingBlock:"0px"}} customInputText={tagSuggestActive ? {borderEndEndRadius:"0px", borderEndStartRadius:"0px"} : {}} labelStyle={styles.labelStyle} onTextChange={(val) => tagInputChange(val)} submitEditing={submitTag} />
                
                <div style={{display:'flex', gap:"12px", marginTop:"12px",width: "100%", overflowY: "hidden"}}>
                    {tagSuggest.map((item, index) => (
                        <button onClick={()=>removeTag(item)} key={index} style={{display:'flex', justifyContent:'center', alignItems:'center', padding:"4px 8px", backgroundColor:"#302929", borderRadius:"24px", gap:"8px"}}>
                            <div className="skipText bodyText"  style={{fontWeight:'700'}}>#{item}</div>
                            <div style={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                                <IoClose size={"14px"} color={"#FFF"} />
                            </div>
                        </button>
                    ))}
                </div>
                
            </div>
            
        </div>
        {openPicker && <PickerModal visible={openPicker} pickerItem={eventType} selectedValue={item.fields.eventType?item.fields.eventType.label:''} onChange={(val) => updateEventType(val)} modalClose={()=>setOpenPicker(false)} /> }
        {openSubPicker && <PickerModal visible={openSubPicker} pickerItem={eventSubType.filter((item) => item.show)} selectedValue={item.fields.eventSub?item.fields.eventSub.label:''} onChange={(val) => onChange('eventSub', val)} modalClose={()=>setOpenSubPicker(false)} /> }
        </>
    )
}

export default NewEvent;

const styles:generalObj = {
    labelStyle: {
        color:'#FFF'
    },
    dateText: {
        fontFamily:'400',
        fontSize:"13px",
        textAlign: 'start',
        color:'rgba(255, 255, 255, 0.6)'
    },
    activeText: {
        color: 'rgba(255, 255, 255, 0.8)',

    },
    locationStlye: {
        textOverflow:'ellipsis', 
        whiteSpace:'nowrap',
        width: "100%",
        overflow: "hidden"
    },
    contSpace: {
        marginTop:"24px",
        padding: "0px"
    },
    messageText: {
        fontWeight: '400',
        lineHeight: "19px",
        color: '#FFF'
    },
    messageTextOne: {
        color: 'rgba(255, 255, 255, 0.3);',
        alignSelf: 'flex-start'
    },
}