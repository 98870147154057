
type payloadData = {
    [key:string]: any
}

/* User Account action type */
export enum ActionType {
    REGISTER_USER = "REGISTER_USER",
    LOGIN_USER = "LOGIN_USER",
    UPDATE_USER = "UPDATE_USER",
    REFRESH_TOKEN = "REFRESH_TOKEN",
    ONBOARDED_USER = "ONBOARDED_USER",
    ONBOARDING_USER = "ONBOARDING_USER",
    LOGOUT_USER = "LOGOUT_USER"
}

interface actionRegisterUser {
    type: ActionType.REGISTER_USER;
}

export interface actionLoginUser {
    type: ActionType.LOGIN_USER;
    payload: payloadData;
}

export interface actionRefreshToken {
    type: ActionType.REFRESH_TOKEN;
    payload: payloadData;
}

interface actionUpdateUser {
    type: ActionType.UPDATE_USER;
    payload: any;
}

interface actionOnboardUser {
    type: ActionType.ONBOARDED_USER;
}

interface actionOnboardingUser {
    type: ActionType.ONBOARDING_USER;
}

interface actionLogoutUser {
    type: ActionType.LOGOUT_USER;
}

export type Action = actionRegisterUser | actionLoginUser | actionUpdateUser | actionRefreshToken | actionOnboardUser | actionOnboardingUser | actionLogoutUser;

/* User Register action type */
export enum RActionType {
    UPDATE_INFO = "UPDATE_INFO",
    CLEAR_INFO = "CLEAR_INFO"
}

interface ractionUpdateInfo {
    type: RActionType.UPDATE_INFO;
    payload: payloadData;
}

export interface ractionClearInfo {
    type: RActionType.CLEAR_INFO;
}

export type RAction = ractionUpdateInfo | ractionClearInfo;