import { useEffect, useState, useRef } from "react";
import { BackArrow, RegisterContent } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState, ractionCreator } from "../../redux";
import { useNavigate } from "react-router-dom";
import { UrlType, generalObj } from "../../constant/objects_types";
import { IoCloseOutline } from "react-icons/io5";
import { getLocation, getPhoneCode } from "../../action/generalFunc";
import { invalidColor } from "../../constant/constant";

const RegisterStepThreeOne = () => {
    type msgRObj = {
        [key:string]:any
    }
    const resgisterState = useSelector((state:RootState) => state.registerInfo);  
    const [formData, setFormData] = useState<generalObj>({
        phone_number: "",
        phone_code: {
            label:"",
            value: '',
        }
    });
    const [phoneCode, setPhoneCode] = useState<generalObj[]>([]);
    const [phoneErr, setPhoneErr] = useState<boolean>(false);
    const [message, setMessage] = useState<msgRObj>({
        color:'#029522',
        message: '',
        isValid: false
    })
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updateInfo } = bindActionCreators(ractionCreator, dispatch);
    const phoneRef = useRef<HTMLInputElement | null>();

    useEffect(() => {
        if (!resgisterState || !resgisterState.email || !resgisterState.fullname || !resgisterState.username) {
            navigate(UrlType.REGISTER_STEP1);
            return;
        }
        if (!resgisterState.phone || !resgisterState.phone_code) return;
        let phone_num:string = resgisterState.phone;
        let code = phone_num.split('-');
        (async () => {
            const location = await getLocation();
            let pCode:generalObj[] = await getPhoneCode(resgisterState.phone_code ? resgisterState.phone_code : location.countryCode);
            setFormData((prevData) => ({...prevData, "phone_number": code[1], "phone_code": {
                id:pCode[0].id,
                label:`${pCode[0].flag} ${pCode[0].phone_code}`,
                value:pCode[0].phone_code,
                code: pCode[0].code
            }}));
            let result = await getPhoneCode();
            let data:generalObj[] = [];
            result.forEach((element:generalObj) => {
                data.push({
                    id:element.id,
                    label:`${element.flag} ${element.phone_code}`,
                    value:element.phone_code,
                    code: element.code
                })
            })
            setPhoneCode(data.sort((a:generalObj, b:generalObj) => {
                return a.value - b.value
            }));
            if (code[1].length === 10) {
                setPhoneErr(false);
                setMessage((msg) => ({...msg, message: '', isValid:true}));
                return true;
            }else {
                setPhoneErr(true);
                setMessage((msg) => ({...msg, color:invalidColor, message:"Phone must be 10 digits", isValid:false}));
                return false;
            }
        })();
        
    }, [resgisterState, navigate]);

    useEffect(() => {
        if (resgisterState.phone && resgisterState.phone_code) return;

        (async () => {
            const location = await getLocation();
            let pCode:generalObj[] = await getPhoneCode(location.countryCode);
            setFormData((prevData) => ({...prevData, "phone_code": {
                id:pCode[0].id,
                label:`${pCode[0].flag} ${pCode[0].phone_code}`,
                value:pCode[0].phone_code,
                code: pCode[0].code
            }}));

            let result = await getPhoneCode();
            let data:generalObj[] = [];
            result.forEach((element:generalObj) => {
                data.push({
                    id:element.id,
                    label:`${element.flag} ${element.phone_code}`,
                    value:element.phone_code,
                    code: element.code
                })
            })
            setPhoneCode(data.sort((a:generalObj, b:generalObj) => {
                return a.value - b.value
            }));
        })();
        
    }, [resgisterState]);

    
    const checkPhone = (val?:string) => {
        let val_data = val ? val :formData.phone_number;
        if (val_data.length === 10) {
            setPhoneErr(false);
            setMessage({...message, message: '', isValid:true});
            return true;
        }else {
            setPhoneErr(true);
            setMessage({...message, color:invalidColor, message:"Phone must be 10 digits", isValid:false});
            return false;
        }
    }
    
    const onSubmit = async () => {
        if (formData.phone_number.length === 0) return;
        if (message.isValid) { 
            await updateInfo("phone", `${formData.phone_code.value}-${formData.phone_number}`);
            await updateInfo("phone_code", `${formData.phone_code.code}`);
            setPhoneErr(false);
            navigate(UrlType.REGISTER_STEP4);
        }else {
            alert("Invalid phone number");
        }
    }
    
    const onTextChange = (val:string) => {
        setFormData((prevData) => ({...prevData, "phone_number": val.toString().replace(" ", "")})); 
        setPhoneErr(false);
        checkPhone(val);
    }

    return (
        <>
            <div className='authContainer' style={{justifyContent:'flex-start'}}>
                <BackArrow onPress={() => {window.history.back()}} />
                <div className="register">
                    <RegisterContent 
                        header="What's your Phone number?" 
                        btnTitle="Next" 
                        id={'phone_number'}
                        description="Include your phone number." 
                        inputProp={{value:formData.phone_number, type:'number', maxLength:'10'}}
                        refInput={(input)=>phoneRef.current = input}
                        isIcon={<IoCloseOutline size={"24px"} color="#FFF" /> } 
                        onPress={onSubmit}
                        btnStyle={message.isValid ? {opacity:'1', cursor:"pointer"} : {cursor:'not-allowed'}}
                        onTextChange={(val)=>{onTextChange(val)}}
                        iconPress={() => {onTextChange('')}}
                        message={{
                            style: {
                                color: message.color
                            },
                            show: phoneErr,
                            text: message.message
                        }}
                        allow_paired={true}
                        selectedObj={formData.phone_code}
                        objList={phoneCode}
                        updateCode={(val) => setFormData((prevData) => ({...prevData, "phone_code": val}))}
                    />
                </div>
            </div>
        </>
    )
}

export default RegisterStepThreeOne;