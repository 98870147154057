import { useState, useEffect } from 'react';
import BackArrow from '../BackArrow';
import { InfoIconSmallSvg, TermsSvg } from '../../styles/IconStyle';
import { IoChevronForwardOutline } from 'react-icons/io5';
import WhatWeDo from './WhatWeDo';

const CompanyInfo = ({ visible, modalClose }:{
    visible:boolean,
    modalClose: () => void,
}) => {
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [openWwd, setOpenWwd] = useState<boolean>(false);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <>
                    <div className={`modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                        <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>About Us</div>
                                    </div>
                                </div>
                                
                                <div style={{width:'100%', marginTop:"24px", height:"100%"}}>
                                    <div style={{height:"calc(100% - 270px)", paddingBottom:'90px', overflowX:'hidden'}}>
                                        <div className={"inputField"} onClick={() => setOpenWwd(true)}>
                                            <div className="inputTextCont" style={{borderBottom: "1px solid rgba(255, 255, 255, 0.1)"}}>
                                                <div className="inputText" style={{paddingLeft:"0px", paddingRight:"0px",backgroundColor: 'transparent', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                    <div style={{display:"flex", flexDirection:'row', alignItems:'center'}}>
                                                        <div style={{display:'flex'}}>
                                                            <InfoIconSmallSvg />
                                                        </div>
                                                        <div style={{fontWeight:'500', fontSize:"14px", lineHeight:"22px", marginLeft:"5px", color:'#FFF'}}>What we do</div>
                                                    </div>
                                                    <div style={{display:'flex', alignItems:'flex-end'}}>
                                                        <IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"inputField"} onClick={() => console.log("wwd")}>
                                            <div className="inputTextCont" style={{borderBottom: "1px solid rgba(255, 255, 255, 0.1)"}}>
                                                <div className="inputText" style={{paddingLeft:"0px", paddingRight:"0px",backgroundColor: 'transparent', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                    <div style={{display:"flex", flexDirection:'row', alignItems:'center'}}>
                                                        <div style={{display:'flex'}}>
                                                            <TermsSvg />
                                                        </div>
                                                        <div style={{fontWeight:'500', fontSize:"14px", lineHeight:"22px", marginLeft:"5px", color:'#FFF'}}>Terms of use</div>
                                                    </div>
                                                    <div style={{display:'flex', alignItems:'flex-end'}}>
                                                        <IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <WhatWeDo visible={openWwd} modalClose={() => setOpenWwd(false)} />
        </>
    )
}

export default CompanyInfo;